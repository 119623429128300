import { UserGuard } from './shared/guards/user.guard';
import { ModalComponent } from './shared/alerts/modal/modal.component';
import { ErrorDecryptInterceptor } from './shared/services/error.decrypt.interceptor';
import { SiteDeTestesModule } from './_new-tecnoscopio-v2/site-de-testes/site-de-testes.module';
import { NewFiltroForumService } from './_new-tecnoscopio-v2/new-filtro/new-filtro-forum/new-filtro-forum.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ModalModule } from 'ngx-bootstrap/modal'; // modal tipo alert
import { AppRoutingModule } from './app-routing.module';
import { LoginService } from './_nt-f4bric/service/login.service';
import { DiagramaService } from './_nt-f4bric/service/diagrama.service';
import { FormsModule } from '@angular/forms';
import { AuthDiagramaGuard } from './_nt-f4bric/guards/auth-diagrama.guard';
import { UsuarioDiagramaGuard } from './_nt-f4bric/guards/usuario-diagrama-guard';
import { AppService } from './app.service';
import { SeoService } from './seo.service';
import { QuestionService } from './shared/services/question.service';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MediaScreenService } from './shared/services/media-screen.service';
import { SharedModule } from './shared/shared.module';
import { CupomService } from './shared/cupom/cupom.service';
import { AssinaturaService } from './shared/assinatura/assinatura.service';
import { SignalService } from './shared/services/signal.service';
import { LoginTokenGuard } from './shared/login/login-token.guard';
import { CryptoService } from './shared/services/crypto.service';
import { NewNavBarComponent } from './_new-tecnoscopio-v2/new-nav-bar/new-nav-bar.component';
import { NavBarGuard } from './shared/guards/nav-bar.guard';
import { SanitizeSrcSignalPipe, SanitizeSrcUserPipe } from './shared/pipe/sanitizer-src-pipe.';
import { NewDemoYoutubeComponent } from './_new-tecnoscopio-v2/new-demo-youtube/new-demo-youtube.component';
import { UserService } from './shared/services/user.service';

@NgModule({
  declarations: [
    AppComponent,
    NewNavBarComponent,
    NewDemoYoutubeComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ModalModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    SharedModule,
    NoopAnimationsModule,
    SiteDeTestesModule
  ],
  providers: [
    DiagramaService,
    UsuarioDiagramaGuard,                    // Guarda de rota para reverificar se usuário é do tipo premium  |     -> PremiumService
    LoginService,                            // Serviço de login                                              |     -> AuthService
    AuthDiagramaGuard,
    AppService,
    SeoService,
    UserGuard,
    QuestionService,
    MediaScreenService,
    CupomService,
    AssinaturaService,
    SignalService,
    LoginTokenGuard,
    CryptoService,
    UserGuard, 
    UserService, 
    SignalService,
    SanitizeSrcSignalPipe,
    SanitizeSrcUserPipe,
    NavBarGuard,
    NewFiltroForumService
  ],
  entryComponents: [ModalComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }
