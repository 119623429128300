import { AppService } from './../../../app.service';
import { SucessCreateAccountComponent } from './../sucess-create-account/sucess-create-account.component';
import { AlertService } from './../alert.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-renovar-plano-cartao',
  templateUrl: './renovar-plano-cartao.component.html',
  styleUrls: ['./renovar-plano-cartao.component.scss']
})
export class RenovarPlanoCartaoComponent implements OnInit {

  user: any;
  produto: any;
  recorrencia: any;
  idPopup: any;
  userPlano: any;
  Installments: number[] = Array.from({ length: 12 }, (_, i) => i + 1);

  formResp = new FormGroup({
    cvv: new FormControl(),
    Installments: new FormControl()
  });

  mobileFlagService: string = 'Desktop';

  constructor(
    private router: Router,
    private _dialog: MatDialog,
    private alertService: AlertService,
    private appService: AppService,
    public dialog: MatDialogRef<RenovarPlanoCartaoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Data_Interface
  ) { }

  ngOnInit() {
    this.user = this.data.user;
    this.recorrencia = this.data.recorrencia;
    this.userPlano = this.data.plano;
    this.idPopup = this.data.idPopup;
    this.produto = this.recorrencia.plano.produto;

    if (!this.recorrencia.creditCard.ultimosDigitos) {
      this.dialog.close(false);
      this._dialog.open(SucessCreateAccountComponent, {
        data: {
          title: 'Falha',
          icon_title: '&#xE888;',
          message: 'Cadastre um cartão de crédito antes de prosseguir!.',
          buttonConfirm: 'Cadastrar',
          buttonDecline: 'Cancelar',
          icon_button: '&#xE5C8;',
          background: '#ff9966',
          timer: 6
        },
        disableClose: true
      }).afterClosed().pipe(take(1)).subscribe(async (callback) => {
        if (callback) {
          this.router.navigate([`${environment.url_version}/perfil-cartao`]);
        }
      })
      return
    }

    // console.log(this.userPlano)
    this.appService.getMobile().subscribe(flag => {
      this.mobileFlagService = flag;
    });
  }

  cancel() {
    this.dialog.close(false);
  }

  returnLength(value) {
    if (value == null || value == '' || value == undefined) return true;
    if (value.length == 3 || value.length == 4) {
      return false
    } else {
      return true
    }
  }

  renovarAssinatura() {
    let Installments: number = parseInt(this.formResp.value.Installments);
    const body = {
      planoId: this.userPlano._id,
      cvv: this.formResp.value.cvv,
      bancoId: environment.bank_id,
      clienteId: environment.client_id,
      Installments
    }
    try {
      this.alertService.renovarPlanoCartao(body).subscribe((res: any) => {
        if (res.code == 200) {
          //Sucesso
          this._dialog.closeAll();
          this.cancel();
          this.popUp_visualizado();
          this._dialog.open(SucessCreateAccountComponent, {
            data: {
              title: 'Sucesso',
              icon_title: '&#xE876;',
              message: 'Obrigado por assinar nosso plano!.',
              buttonConfirm: 'Tudo bem',
              buttonDecline: '',
              icon_button: '&#xE5C8;',
              background: '#47c9a2',
              timer: 6
            },
            disableClose: true
          }).afterClosed().pipe(take(1)).subscribe(async (callback) => {
            location.reload();
          })
          return
        } else {
          //Falha
          this.alertMenssage('Falha!', '', res.msg, '', 'Tudo bem', '#ff9966');
          return
        }
      },
        (error) => {
          //Falha
          this.alertMenssage('Falha!', '', error.error.msg, '', 'Tudo bem', '#ff9966');
          return
        });
    } catch (error) {
      //Falha
      this.alertMenssage('Falha!', '', 'Não foi possível validar o cupom!', '', 'Tudo bem', '#ff9966');
      return
    }
  }

  alertMenssage(title: any, icon: any, message: any, buttonConf: any, buttonDecl: any, background: any) {
    this._dialog.open(SucessCreateAccountComponent, {
      data: {
        title: title,
        icon_title: icon,
        message: message,
        buttonConfirm: buttonConf,
        buttonDecline: buttonDecl,
        icon_button: '&#xE5C8;',
        background: background,
        timer: 0
      },
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
      width: '30em'
    })
  }

  popUp_visualizado() {
    // if (this.idPopup) {
    //   this.alertService.popUppVisualizado(this.idPopup).subscribe(async (res: any) => { });
    // }
  }

  formatNumber(number) {
    try {
      const [integerPart, decimalPart] = number.toString().split(".");
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      const formattedDecimalPart = decimalPart ? `,${decimalPart}` : "";
      const formattedNumber = `${formattedIntegerPart}${formattedDecimalPart}`;
      return formattedNumber;
    } catch (error) {

    }
  }

  calculateValue(installment: number): number {
    let value = this.userPlano.produto.PrecoVenda;
    if (installment === 1) {
      const desconto = this.userPlano.desconto_a_vista / 100;
      value = value - (value * desconto);
    } else {
      value = value / installment;
    }
    return value;
  }

  createInstallments(length: number): number[] {
    return Array.from({ length }, (_, i) => i + 1);
  }

  addCartao() {
    this.cancel();
    localStorage.setItem('change-card', 'true');
    this.router.navigate([`${environment.url_version}/perfil-cartao`]);
  }

}

interface Data_Interface {
  user: string;
  recorrencia: string;
  plano: string;
  idPopup: string;
}