import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment'
// import { NOMEM } from 'dns';
import { tap, delay, take } from 'rxjs/operators';
import { Componente } from '../componente';
import { Diagrama } from '../diagrama';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
// import { Diagrama } from '../diagrama';


@Injectable()
export class DiagramaService {

  public token: BehaviorSubject<string> = new BehaviorSubject(null);
  private id = 0; // esse id vai ser do próprio mongo
  l = false;

  // key = 'AIzaSyAVcOpwjGFFD1JepbDHHxNQdE1LxU8OGak';
  key = environment.api_key_youtube

  emitirKanvas = new EventEmitter<string>();
  permissionAcess = new EventEmitter<boolean>();

  user;

  constructor(private http: HttpClient) {
  }

  private response: any;


  getAllComponents() {
    return this.http.get<Componente[]>(`${environment.api_server}/api/componente/carregar`, { headers: SharedService.header });
  }

  getComponentById(_id) {
    return this.http.post(`${environment.api_server}/api/componente/carregar/id`, { _id: _id }, { headers: SharedService.header });
  }

  saveComponent(id, nome, qtdPinos, jsonCanvas) {
    return this.http.post(`${environment.api_server}/api/componente/salvar`, { id, nome, qtdPinos, jsonCanvas }, { headers: SharedService.header });
  }

  deleteComponent(_id) {
    return this.http.post(`${environment.api_server}/api/componente/delete/id`, { _id: _id }, { headers: SharedService.header });
  }

  getAllDiagrams() {
    return this.http.get<Diagrama[]>(`${environment.api_server}/api/diagrama/carregar`, { headers: SharedService.header });
  }

  getAllDiagramsApagados() {
    return this.http.get<Diagrama[]>(`${environment.api_server}/api/diagrama/carregar/apagados`, { headers: SharedService.header });
  }

  getDiagram(_id) {
    // const _id = '52882143'
    return this.http.post(`${environment.api_server}/api/diagrama/pesquisa/id`, { _id: _id }, { headers: SharedService.header });
  }

  // filterDiagrams(tags) {
  //   return this.http.post(`${environment.api_server}/api/newtecnoscopio/diagrama/filtro/v3`, tags, { headers: SharedService.header });
  // }

  filterDiagrams(tags) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/diagrama/filtro`, tags, { headers: SharedService.header });
  }

  saveDiagram(data) {
    return this.http.post(`${environment.api_server}/api/diagrama/salvar`, data, { headers: SharedService.header });
  }

  getInfoComp(params) {
    return this.http.post(`${environment.api_server}/api/diagrama/componentes/infos`, params, { headers: SharedService.header });
  }

  getFoto(diagramaId, jsonComponenteId) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/get/foto`, { jsonComponenteId: jsonComponenteId, diagramaId: diagramaId }, { headers: SharedService.header });
  }

  removeFoto(id, diagramaId, jsonComponenteId) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/foto/remove/componente`, { fotoId: id, jsonComponenteId: jsonComponenteId, diagramaId: diagramaId }, { headers: SharedService.header });
  }

  getAllFotos() {
    return this.http.get(`${environment.api_server}/api/newtecnoscopio/get/fotos`, { headers: SharedService.header });
  }

  getFotoBy(search: any) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/get/fotos/search`, {search: search}, { headers: SharedService.header });
  }

  linkFotoId(data) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/foto/set`, data, { headers: SharedService.header });
  }

  uploadFoto(data) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/foto/diagrama/upload`, data, { headers: SharedService.header });
  }

  uploadSignal(data) {
    return this.http.post(`${environment.api_server}/api/diagrama/componente/upload/sinal`, data, { headers: SharedService.header });
  }

  getTutorial(diagramaId, jsonComponenteId) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/get/pdf`, { jsonComponenteId: jsonComponenteId, diagramaId: diagramaId }, { headers: SharedService.header });
  }

  removeTutorial(id, diagramaId, jsonComponenteId) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/pfd/remove`, { pdfId: id, jsonComponenteId: jsonComponenteId, diagramaId: diagramaId }, { headers: SharedService.header });
  }

  uploadTutorial(data) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/pdf/diagrama/upload`, data, { headers: SharedService.header });
  }

  videoExist(videoId) {
    const url = 'https://www.googleapis.com/youtube/v3/videos?id=';
    return this.http.get(`${url}${videoId}&key=${this.key}&part=snippet`);
  }

  getAllCompont() {
    return this.http.get(`${environment.api_server}/api/diagrama/all/componentes`, { headers: SharedService.header });
  }

  uploadVideo(object) {
    return this.http.post(`${environment.api_server}/api/module/video`, object, { headers: SharedService.header });
  }

  deleteDiagram(_id) {
    return this.http.post(`${environment.api_server}/api/diagrama/delete/id`, { _id: _id }, { headers: SharedService.header });
  }

  restaurarDiagram(_id) {
    return this.http.post(`${environment.api_server}/api/diagrama/restaurar/id`, { _id: _id }, { headers: SharedService.header });
  }

  editDiagram(data) {
    return this.http.post(`${environment.api_server}/api/diagrama/editar/id`, data, { headers: SharedService.header });
  }

  authentication(email, password) {
    return this.http.post(`${environment.api_server}/api/module/admin/login`, { email, password });
  }

  getAllPlans() {
    return this.http.get(`${environment.api_server}/api/assinantes/plano/get`);
  }

  getVehiclesInfo(mod, brand, model) {
    return this.http.post(`${environment.api_server}/api/module/vehicle`, { mod: mod, brand: brand, model: model }, { headers: SharedService.header });
  }

  getSinalById(id) {
    return this.http.get(`${environment.api_server}/api/module/admin/buffer/${id}`, { headers: SharedService.header });
  }

  downloadPDF(id) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/pfd/download`, { pdfId: id }, { headers: SharedService.header, responseType: 'arraybuffer' });
  }

  downloadFoto(id) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/foto/download`, { fotoId: id }, { headers: SharedService.header, responseType: 'arraybuffer' });
  }

  getPDFId(id) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/get/pdf`, { pdfId: id }, { headers: SharedService.header });
  }

  getFotoId(id) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/get/foto`, { fotoId: id }, { headers: SharedService.header });
  }

  changePassword(password) {
    return this.http.put(`${environment.api_server}/api/module/admin/new-password`, { password: password }, { headers: SharedService.header });
  }

  uploadPDF(file) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/diagrama/pfd/upload`, file, { headers: SharedService.header });
  }

  getPermissoes() {
    return this.http.get(`${environment.api_server}/api/assinantes/plano/permissao`, { headers: SharedService.header });
  }

  emit(user: any, token: any) {
    if (!user || !token) {
      this.user.next(null);
      this.token.next(null);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
    } else {
      if (user) {
        if (user.crudPermission.admin == true) {
          localStorage.setItem('admin', 'true')
        }
      }
      this.user.next(user);
      this.token.next(token);
      if (sessionStorage.getItem('token')) {
        sessionStorage.setItem('token', token)
      } else {
        localStorage.setItem('token', token);

      }
    }
  }

  saveTutorial(tutorial) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/pfd/upload`, tutorial, { headers: SharedService.header });
  }

  editComponent(comp) {
    return this.http.post(`${environment.api_server}/api/componente/editar/id`, comp, { headers: SharedService.header });
  }

  backupDiagrama(diagrama) {
    return this.http.post(`${environment.api_server}/api/module/diagram/backup/file`, diagrama, { headers: SharedService.header });
  }

  getAdmin(id) {
    return this.http.post(`${environment.api_server}/api/module/user/get/admin/id`, { id: id }, { headers: SharedService.header });
  }

  finishDiagram(id, marca) {
    if (id !== null) {
      return this.http.post(`${environment.api_server}/api/diagrama/editar/concluido`, { id: id }, { headers: SharedService.header });
    } else {
      return this.http.post(`${environment.api_server}/api/diagrama/editar/concluido`, { marca: marca }, { headers: SharedService.header });
    }
  }

  getCategoriaComponente() {
    return this.http.get(`${environment.api_server}/api/newtecnoscopio/categorias/categoria`, { headers: SharedService.header });
  }

  getTipoComponente(id) {
    return this.http.get(`${environment.api_server}/api/newtecnoscopio/categorias/tipocomponente/${id}`, { headers: SharedService.header });
  }

  getAllEngine(params: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/category/engine/list`, params, { headers: SharedService.header, observe: 'response' });
  }

  getAllSystem(params: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/category/system/list`, params, { headers: SharedService.header, observe: 'response' });
  }

  getMarca() {
    return this.http.get(`${environment.api_server}/api/newtecnoscopio/categorias/marca`, { headers: SharedService.header });
  }

  getModelo() {
    return this.http.get(`${environment.api_server}/api/newtecnoscopio/categorias/modelo`, { headers: SharedService.header });
  }

  getTipoDiagrama() {
    return this.http.get(`${environment.api_server}/api/newtecnoscopio/categorias/tipodiagrama`, { headers: SharedService.header });
  }

  getSistema() {
    return this.http.get(`${environment.api_server}/api/newtecnoscopio/categorias/sistemainjecao`, { headers: SharedService.header });
  }

  removeMotorFromDiagram(diagrama: string, motor: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/category/engine/delete-diagram`, { idEngine: motor, idDiagram: diagrama }, { headers: SharedService.header, observe: 'response' });
  }

  removeSystemFromDiagram(sistema: string, diagrama: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/category/system/delete-diagram`, { idSystem: sistema, idDiagram: diagrama }, { headers: SharedService.header, observe: 'response' });
  }

  updateCategoriasDiagrama(data) {
    return this.http.post(`${environment.api_server}/api/diagrama/editar/componentes/canvas/id`, data, { headers: SharedService.header });
  }

  addComponentInfo(data) {
    return this.http.post(`${environment.api_server}/api/diagrama/componente/by/componente`, data, { headers: SharedService.header });
  }

  deleteComponentInfo(data) {
    const httpOptions = {
      headers: SharedService.header, body: data
    };
    return this.http.delete(`${environment.api_server}/api/diagrama/componente/by/componente`, httpOptions);
  }

  addComponent(data) {
    return this.http.post(`${environment.api_server}/api/diagrama/componentes/bydiagramaid/addComponentesByDiagramaId`, data, { headers: SharedService.header });
  }

  removeComponent(data) {
    return this.http.post(`${environment.api_server}/api/diagrama/componentes/bydiagramaid/removeComponentesByDiagramaId`, data, { headers: SharedService.header });
  }

  getInfoCompByDiagramaId(diagramaId) {
    return this.http.post(`${environment.api_server}/api/diagrama/componentes/getComponentesByDiagramaId`, {diagramaId: diagramaId}, { headers: SharedService.header });
  }

  getDiagramRequests(params: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/diagram/requests`, params, { headers: SharedService.header, observe: 'response' });
  }

  updateDiagramRequest(pedidoId: string, diagramaId: string, completed: boolean): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/diagram/request/completed`, { pedidoId, diagramaId, completed }, { headers: SharedService.header, observe: 'response' });
  }
}
