import { NewFiltroForumService } from './../new-filtro/new-filtro-forum/new-filtro-forum.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { addListener, launch, stop } from 'devtools-detector';
var flagDevTools;
addListener((isOpen) => {
  flagDevTools = isOpen;
});

@Component({
  selector: 'app-new-nav-bar',
  templateUrl: './new-nav-bar.component.html',
  styleUrls: ['./new-nav-bar.component.scss']
})

export class NewNavBarComponent implements OnInit {

  session: boolean = false;
  countNotification: boolean = false;
  devToolsOpen: Boolean = false;
  openNotificationFlag: boolean = false;
  user;
  fakeUser: boolean = false;
  mobileFlagAppNew: boolean = false;
  mobileFlag: boolean = false;
  fixedNavBar: boolean = false;
  modalDownload: boolean = false;
  downloadProgress: number = 0;
  token: string;
  softwareLogin: string;
  service = "Desktop"
  notifications = [];
  userName: string;
  permForum;
  permAcessForum;

  routerLink_URL = environment.url_version == '' ? `${environment.url_version}` : `/${environment.url_version}`;
  routerLink_URL_sobre = `${this.routerLink_URL}/sobre`;
  routerLink_URL_login = `${this.routerLink_URL}/user/login`;
  routerLink_URL_perfilDownloads = `${this.routerLink_URL}/downloads`;

  showNavbar;

  constructor(
    private _route: Router,
    private appService: AppService,
    private _http: HttpClient,
    private ativatedRoute: ActivatedRoute,
    private newFiltroForumService: NewFiltroForumService,
  ) { }

  ngOnInit() {
    this.appService.getFakeLogin().subscribe(flag => {
      this.fakeUser = flag;
    });

    this.appService.getMobileApp().subscribe(flag => {
      this.mobileFlag = flag;
    });

    this.appService.getDownloadModal().subscribe(flag => {
      this.modalDownload = flag;
    });

    this.appService.getDownloadProgress().subscribe(flag => {
      this.downloadProgress = flag;
    });
    
    this.appService.getFixedNavBar().subscribe(flag => {
      this.fixedNavBar = flag;
    });

    this.appService.getMobileAppNew().subscribe(flag => {
      this.mobileFlagAppNew = flag;
    });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    )
      this.service = "Mobile";
    else this.service = "Desktop";
    this.softwareLogin = sessionStorage.getItem('software-new');
    this.user = this.appService.getUser().value;

    this.appService.user.subscribe((user) => {
      if (user) {
        this.user = user;
        this.appService.emitShowNavBar(this.appService.getNavBarComLogin());
        this.showNavbar = this.appService.getNavBarComLogin();
        this.session = true;
      } else {
        this.appService.emitShowNavBar(this.appService.getNavBarSemLogin());
        this.showNavbar = this.appService.getNavBarSemLogin();
        this.session = false;
      }

    });

    this.ativatedRoute.data.subscribe((info) => {
      if (info.user) this.user = info.user;
    });

    this.appService.showNavBar.subscribe((nav_bar) => {
      this.showNavbar = nav_bar;
      if (!this.user) this.session = false;
    })

    if (this.session == true) {
      // console.log('===>', document.getElementById('clickbox'))
      window.addEventListener('click', (e: any) => {  
        if (document.getElementById('clickbox')) {
          if (document.getElementById('clickbox').contains(e.target)){
            // Clicked in box
            // console.log('click dentro')
          } else{
            // console.log('click fora')
            this.openNotificationFlag = false;
          }
        }
      });
    }

    try {
      if (environment.production && !this.user.crudPermission.admin) {
        // launch();
      }
    } catch (error) {
      
    }
    this.checkDevToolsChange();
  }

  ngAfterViewChecked() {
    if (this.user == null || this.user == undefined || !this.user) {
      return
    }
  }

  getFormatedName(user) {
    try {
      let Fullname = this.user.personalData.name;
      const formataNome = str => {
        return str.toLowerCase().replace(/(?:^|\s)(?!da|de|do)\S/g, l => l.toUpperCase());
      };
      let parts = Fullname.split(' ');
      return `${parts[0]}`;

    } catch (e) {
      this.appService.emit(undefined, undefined, undefined);
      this._route.navigate(['osciloscopio/sinais-filtro']);
    }
  }

  isActive() {
    let retorno;

    if (this._route.url.indexOf('blog-post') !== 0)
      retorno = true;

    if (this._route.url.indexOf('blog') !== 0)
      retorno = true;

    if (this._route.url.indexOf('blog') === -1)
      retorno = false;

    return retorno
  }

  logout() {
    this.appService.setLoginUser(false);
    sessionStorage.clear();
    localStorage.clear();
    this.session = false;
    this.appService.emit(undefined, undefined, undefined);
    this.appService.emitShowNavBar(this.appService.getNavBarSemLogin());
    this._route.navigate([this.routerLink_URL]);
    this.appService.setFakeLogin(false);
    this.appService.setLogoutFlag(true);
  }

  userFromServer(token) {
    this.user = this.appService.getUser().value;
    if (!this.user) {
      return this._http.get(`${environment.api_server}/api/module/user/id`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + token) })
    }
  }

  openNotification() {
    this.openNotificationFlag = !this.openNotificationFlag;
  }

  getData(timeStr) {
    let date = new Date(timeStr);
    let hour: any = date.getHours();
    let minutes: any = date.getMinutes();
    let seconds: any = date.getSeconds();
    let day: any = date.getDate();
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    if (month <= 9) month = '0' + month;
    if (day <= 9) day = '0' + day;
    if (hour <= 9) hour = '0' + hour;
    if (minutes <= 9) minutes = '0' + minutes;
    if (seconds <= 9) seconds = '0' + seconds;
    return day + "/" + month + "/" + year + ' - ' + hour + ':' + minutes + ':' + seconds
  }

  goToNotification(notification) {
    console.log(notification)
    this.openNotificationFlag = false;
    if (notification.tipo == 'forum') {
      let url = `forum/${notification.forumId}/notif`.split(' ').join('-');
      this._route.navigate([`${environment.url_version}/${url}`]);
      this.openNotification();
      localStorage.setItem('replyId', notification.replyId);
      const notificationObject = {
        notificacaoId: notification._id,
        visualizado: true,
        apagado: false
      }
      try {
        this.newFiltroForumService.deletePreviewedNotification(notificationObject).subscribe((sucess: any) => {
          if (sucess.code == 200) {
            this.notifications = sucess.notificacao;
          } else {
          }
        },
          (error) => {
          });
      } catch (error) {
      }
    } else {
      let url = `curso/${notification.cursoId}/aula/${notification.aulaId}`.split(' ').join('-');
      this._route.navigate([`${environment.url_version}/${url}`]);
      const notificationObject = {
        notificacaoId: notification._id,
        visualizado: true,
        apagado: false
      }
      try {
        this.newFiltroForumService.deletePreviewedNotification(notificationObject).subscribe((sucess: any) => {
          if (sucess.code == 200) {
            this.notifications = sucess.notificacao;
          } else {
          }
        },
          (error) => {
          });
      } catch (error) {
      }
      return
    }
  }

  closeMotification(event, id) {
    this.openNotificationFlag = false;
    event.stopPropagation();
    const notification = {
      notificacaoId: id,
      visualizado: true,
      apagado: true
    }
    try {
      this.newFiltroForumService.deletePreviewedNotification(notification).subscribe((sucess: any) => {
        if (sucess.code == 200) {
          this.notifications = sucess.notificacao;
        } else {
        }
      },
        (error) => {
        });
    } catch (error) {
    }
  }

  checkDevToolsChange() {
    setInterval(() => {
      if (flagDevTools) {
        if (this.user) {
          if (this.user.crudPermission.admin) {
            this.devToolsOpen = false;
            return
          }
        }
        this.devToolsOpen = true;
      } else {
        if (this.devToolsOpen) {
          window.location.reload();
        }
        this.devToolsOpen = false;
      }
    }, 1);
  }

  closeModalDownload() {
    this.appService.setDownloadModal(false);
  }

  limitarTexto(texto) {
    try {
      if (texto.length > 220) {
        return texto.substring(0, 220);
      } else {
        return texto;
      }
    } catch (error) {
      
    }
  }

  closeWebView(){
    const url = `${environment.url_version}/close-browser`;
    window.location.href = url;
  }
}