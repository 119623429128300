import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/shared.service';

@Injectable()
export class DiagramaFiltroService {

  private cont = 0;
  page = new BehaviorSubject<number>(1);
  static novaBusca: EventEmitter<any> = new EventEmitter();
  static fimNovaBusca: EventEmitter<any> = new EventEmitter();

  _LOG = false;

  constructor(private _http: HttpClient) { }

  // filtroDiagramaDropbox(tags) {
  //   return this._http.post(`${environment.api_server}/api/newtecnoscopio/diagrama/filtro/v3`, tags, { headers: SharedService.header });
  // }

  filtroDiagramaDropbox(tags) {
    return this._http.post(`${environment.api_server}/api/newtecnoscopio/diagrama/filtro`, tags, { headers: SharedService.header });
  }

  emitirNovaBusca(emit) {;
    this.page.next(emit.page);
    DiagramaFiltroService.novaBusca.emit();
  }

  iniciarNovaBusca() {;
    DiagramaFiltroService.novaBusca.emit();
  }

  terminarNovaBusca() {
    DiagramaFiltroService.fimNovaBusca.emit();
  }

  returnFiltroPage(){
    return this.page
  }

  getPage = this.page.value;


}
