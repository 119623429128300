import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import { CryptoService } from './crypto.service';
import { map } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { TokenService } from '../alerts/token/token.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  ENCRYPT_API = environment.encrypt_api;
  plataformaId = environment.platform_id;
  mobileFlag = false;

  constructor(
    private appService: AppService,
    private loginService: LoginService,
    private tokenService: TokenService,
    private router: Router,
    private cryptoService: CryptoService
  ) {
    // Subscreve para obter o estado do aplicativo móvel
    this.appService.getMobileApp().subscribe(flag => {
      this.mobileFlag = flag;
    });
  }

  /**
   * Método intercepta requisições HTTP para adicionar cabeçalhos e, se necessário, criptografar o corpo.
   * @param request - Requisição HTTP original.
   * @param next - Próximo manipulador no pipeline.
   * @returns Observable com o evento HTTP modificado.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cloneReq;

    if (
      this.isExemptedRequest(request)
    ) {
      this.ENCRYPT_API = false;
      cloneReq = this.cloneRequestForExemptedUrls(request);
    } else {
      this.ENCRYPT_API = environment.encrypt_api;
      cloneReq = this.processRequest(request);
    }

    cloneReq = this.addAccessKeyToRequest(cloneReq);

    // Adicionando a lógica para desabilitar cache
    if (environment.disable_cache) {
      cloneReq = cloneReq.clone({
        setHeaders: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
    }

    if (this.isEncryptionRequired(cloneReq)) {
      cloneReq = this.encryptRequestBody(cloneReq);
    }

    return next.handle(cloneReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let responseBody = event.body;

          if (this.ENCRYPT_API) {
            responseBody = this.cryptoService.decryptResponse(responseBody, cloneReq.headers.get("authorization"));
            event = event.clone({ body: responseBody });
          }

          if (responseBody.code == environment.logout.code && responseBody.error == environment.logout.error) {
            this.handleLogout();
          }

          return event;
        }
      })
    );
  }

  /**
   * Verifica se a requisição deve ser isenta de criptografia.
   * @param request - Requisição HTTP.
   * @returns true se a requisição é isenta, false caso contrário.
   */
  private isExemptedRequest(request: HttpRequest<any>): boolean {
    const exemptedUrls = [
      "geolocation/usuario/lgpd/cookies",
      "module/user/login/diagrama/token",
      "software/user/login/token",
      "software/download",
      "viacep.com.br",
      "software/versions",
      "api/newtecnoscopio/foto/download",
      "api/newtecnoscopio/pfd/download",
      "googleapis.com/youtube/v3"
    ];

    const urlExempted = exemptedUrls.some(exemptedUrl => request.url.includes(exemptedUrl));
    const hrefExempted = window.location.href.includes("diagrama/crf");

    return urlExempted || hrefExempted;
  }

  /**
   * Clona a requisição para URLs isentas.
   * @param request - Requisição HTTP original.
   * @returns Requisição clonada com cabeçalhos apropriados.
   */
  private cloneRequestForExemptedUrls(request: HttpRequest<any>): HttpRequest<any> {
    if (request.url.includes("googleapis.com/youtube/v3")) {
      return request.clone();
    } else {
      return request.clone({
        setHeaders: {
          cryt: 'd17',
          plataformaId: this.plataformaId
        }
      });
    }
  }

  /**
   * Processa a requisição para adicionar cabeçalhos e determinar a criptografia necessária.
   * @param request - Requisição HTTP original.
   * @returns Requisição clonada com cabeçalhos apropriados.
   */
  private processRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (this.ENCRYPT_API) {
      this.appService.user.subscribe((user) => {
        if (user && user.crudPermission.admin) {
          this.ENCRYPT_API = false;
        }
      });
    }

    return request.clone({
      setHeaders: {
        cryt: this.ENCRYPT_API ? 'r10' : 'd17',
        plataformaId: this.plataformaId
      }
    });
  }

  /**
   * Adiciona a chave de acesso do localStorage à requisição.
   * @param request - Requisição HTTP original ou clonada.
   * @returns Requisição clonada com a chave de acesso.
   */
  private addAccessKeyToRequest(request: HttpRequest<any>): HttpRequest<any> {
    const accessKey = localStorage.getItem('acesskey');
    if (accessKey) {
      return request.clone({
        setHeaders: {
          acesskey: accessKey,
          plataformaId: this.plataformaId,
          cryt: this.ENCRYPT_API ? 'r10' : 'd17'
        }
      });
    }
    return request;
  }

  /**
   * Verifica se a criptografia é necessária para a requisição.
   * @param request - Requisição HTTP clonada.
   * @returns true se a criptografia é necessária, false caso contrário.
   */
  private isEncryptionRequired(request: HttpRequest<any>): boolean {
    return request instanceof HttpRequest && (request.method === 'POST' || request.method === 'PUT') && this.ENCRYPT_API;
  }

  /**
   * Criptografa o corpo da requisição.
   * @param request - Requisição HTTP clonada.
   * @returns Requisição clonada com o corpo criptografado.
   */
  private encryptRequestBody(request: HttpRequest<any>): HttpRequest<any> {
    const body = this.cryptoService.cripyBody(request.body, request.headers.get("authorization"));
    return request.clone({ body });
  }

  /**
   * Trata o logout do usuário.
   */
  private handleLogout(): void {
    if (this.mobileFlag) {
      const message = { type: 'logout-angular-website', payload: true };
      window.parent.postMessage(message, '*');
    }
    sessionStorage.clear();
    localStorage.clear();
    this.appService.emit(null, null, null, 'from busca sinal => usuário invalido');
    this.router.navigate([`${environment.url_version}/user/login`]);
  }

  /**
   * Método para realizar login falso, utilizado para desenvolvimento e testes.
   */
  fakeLogin() {
    try {
      this.loginService.authentication({ email: environment.user_fake.email, password: environment.user_fake.password })
        .subscribe((res: any) => {
          if (res.code == 200) {
            localStorage.clear();
            sessionStorage.clear();
            this.tokenService.storageEmail(res.user.auth.email);
            this.appService.emit(res.user, res.token, null, 'from login component');
            this.appService.setFakeLogin(true);
            localStorage.setItem('fake-user', 'true');
          }
        }, (error) => {
          // Falha no login falso
        });
    } catch (error) {
      // Erro no login falso
    }
  }
}
