import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlockAcessGuard implements CanLoad {
  
  constructor(
    private router: Router
    ) {}

    canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
      return new Promise(async (resolve, reject) => {
        if (localStorage.getItem('fake-user') == 'true') {
          resolve(false);
          this.router.navigate([`${environment.url_version}/filtros`]);
        } else {
          resolve(true);
        }
      });

  }

}
