import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SharedService } from '../shared.service';

@Injectable()
export class LoginService {

  constructor(private _http: HttpClient) { }

  authentication(form) {
    return this._http.post(`${environment.api_server}/api/module/user/login`, form);
  }

  recoverEmail(cpf_cnpj) {
    return this._http.post(`${environment.api_server}/api/module/user/get/cpfcnpj`, cpf_cnpj, { headers: SharedService.header });
  }
  
  checkToken(token) {
    return this._http.get(`${environment.api_server}/api/module/user/check/have/token`, { headers: SharedService.headerWithToken(token) });
  }

  checkPassword(form) {
    return this._http.post(`${environment.api_server}/api/module/user/login/`, form);
  }

}
