import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SanitizeSrcIndexPipe, SanitizeSrcSignalPipe, SanitizeSrcUserPipe, SanitizeSrcBlogPipe } from './sanitizer-src-pipe.';
import { PhonePipe } from './phone.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { SanitizeHtmlDatePipe } from './sanitize-html-date.pipe';
import { VideoIdPipe } from './video-id.pipe';
import { HideEmailPipe } from './hide-email.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SanitizeSrcIndexPipe,
    SanitizeSrcSignalPipe,
    SanitizeSrcUserPipe,
    SanitizeSrcBlogPipe,
    PhonePipe,
    SanitizeHtmlPipe,
    SanitizeHtmlDatePipe,
    VideoIdPipe,
    VideoIdPipe,
    HideEmailPipe
  ],
  exports: [
    SanitizeSrcIndexPipe,
    SanitizeSrcSignalPipe,
    SanitizeSrcUserPipe,
    SanitizeSrcBlogPipe,
    PhonePipe,
    SanitizeHtmlPipe,
    SanitizeHtmlDatePipe,
    VideoIdPipe,
    HideEmailPipe
  ],
   providers: [
    DatePipe
   ]
})
export class PipeModule { }
