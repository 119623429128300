import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/shared.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NewFiltroForumService {
    
    private forumPost = new BehaviorSubject<object>(null);

    constructor(private http: HttpClient) {
    }

    storageforum(forum: object) {
        this.forumPost.next(forum);
    }

    getforumPost() {
        return this.forumPost;
    }

    createForum(forum) {
        return this.http.post(`${environment.api_server}/api/module/forum/create`, forum, { headers: SharedService.header });
    }

    createReply(reply) {
        return this.http.post(`${environment.api_server}/api/module/forum/reply/create`, reply, { headers: SharedService.header });
    }

    editReply(reply) {
        return this.http.put(`${environment.api_server}/api/module/forum/reply/update`, reply, { headers: SharedService.header });
    }


    getForums(filtro) {
        // console.log(filtro)
        return this.http.post(`${environment.api_server}/api/module/forum/get/filter`, filtro, { headers: SharedService.header });
    }

    getforum(id) {
        return this.http.post(`${environment.api_server}/api/module/forum/get/forumId`, {forumId: id}, { headers: SharedService.header });
    }

    // getUser(id) {
    //     return this.http.post(`${environment.api_server}/api/module/user/get/id`, {id: id}, { headers: SharedService.header });
    // }

    deleteReplyByID(id) {
        const httpOptions = {
            headers: SharedService.header, body:{ replyId: id }
        };
        return this.http.delete(`${environment.api_server}/api/module/forum/reply/delete`, httpOptions );
    }

    editForum(post) {
        return this.http.post(`${environment.api_server}/api/module/forum/update/${post.forumId}`, {body: post.body, titulo: post.titulo}, { headers: SharedService.header });
    }

    createNotification(notification) {
        return this.http.post(`${environment.api_server}/api/module/forum/notification/create`, notification, { headers: SharedService.header });
    }

    deletePreviewedNotification(notification) {
        return this.http.post(`${environment.api_server}/api/module/forum/notification/update`, notification, { headers: SharedService.header });
    }

    // getNotification() {
    //     return this.http.get(`${environment.api_server}/api/module/forum/notification/get`, { headers: SharedService.header });
    // }

    countNotification() {
        return this.http.get(`${environment.api_server}/api/module/forum/notification/count`, { headers: SharedService.header });
    }

    searchForum(search, page) {
        return this.http.post(`${environment.api_server}/api/module/forum/search`, {search: search, page: page}, { headers: SharedService.header });
    }

}