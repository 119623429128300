import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-new-contato',
  templateUrl: './new-contato.component.html',
  styleUrls: ['./new-contato.component.scss']
})
export class NewContatoComponent implements OnInit {

  constructor(
    private titleService: Title, 
    private metaService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('New tecnoscópio - Sobre');
    this.metaService.addTags([
      {name: 'description', content: 'Uma nova forma de fazer diagnóstico avançado em sistemas automotivos.'},
    ]);
  }

}
