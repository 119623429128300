import { Component, OnInit, OnChanges, Renderer2, ElementRef, OnDestroy, Output, EventEmitter, Input, AfterViewInit, AfterContentChecked, AfterViewChecked, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ActivationEnd } from '@angular/router';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { SignalService } from '../../shared/services/signal.service';
import { PagerService } from '../../shared/services/Pager/pager';
import { environment } from '../../../environments/environment';
import { take, takeUntil, tap } from 'rxjs/operators';
import { DiagramaFiltroService } from './diagrama-filtro/diagrama-filtro.service';
import { SocketService } from '../../shared/services/socket.service';
import { FiltrosComponent } from './filtros/filtros.component';
import { AppService } from '../../app.service';
import { NewNavBarComponent } from '../new-nav-bar/new-nav-bar.component';
import { Meta, Title } from '@angular/platform-browser';

interface TAG {
  type: string;
  value: string;
  module?: string;
  user_data?: any;
}

@Component({
  selector: 'app-new-sinais-filtro',
  templateUrl: './new-sinais-filtro.component.html',
  styleUrls: ['./new-sinais-filtro.component.scss']
})

// export class NewSinaisFiltroComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked, AfterViewChecked, DoCheck {
export class NewSinaisFiltroComponent implements OnInit, OnDestroy, AfterViewInit {
  showCheckBoxPrivate: boolean = false;
  checkBoxPrivate: boolean = false;

  tag_detector = new BehaviorSubject(null);
  $destroyer = new Subject();

  user: any;
  query: any;
  moduleDefault = { module: ["Automotivo-carros"] }
  list = [];
  total = 5;
  waiting = true;
  firstLoad: boolean = true;
  tag_list: Array<TAG> = [];
  search_list: any;
  searchString;
  lastQuery: any;
  by = 'date';
  order = -1;
  page = 1;
  pager: any = {};
  pagedItems: any[];
  params: any;
  pageQtd: any = 0;
  recentes: string = "Mais recentes";
  signalValid = false;
  tab: any;
  listUsuario;
  tipoBusca = "Oscilograma"; // Diagrama, Tutorial, etc...

  emailUser = localStorage.getItem('email') || sessionStorage.getItem('email');

  _PRINT = false;
  _LOG = false;
  diagramas;

  loading = true;
  loadingDiagrama = false;
  start = false;

  sizeDiagrama;
  countNgAfterViewChecked = 0;

  notificacoes = [];

  io; // socket

  subscription: Subscription[] = [];

  assinantes = [];

  @Input() type: any;

  tag: any = {
    user: [],
    module: ["Automotivo-carros"],
    brand: [],
    model: [],
    year: [],
    sett: [],
    signalType: [],
    injSystem: []
  }

  inscricao;

  constructor(
    private _signalService: SignalService,
    private _router: Router,
    private _route: ActivatedRoute,
    private pagerService: PagerService,
    private diagramaService: DiagramaFiltroService,
    private _socketService: SocketService,
    private renderer: Renderer2,
    private element: ElementRef,
    private _appService: AppService,
    private titleService: Title,
    private metaService: Meta,
    private appService: AppService
  ) { }

  async ngOnInit() {
    this.start = true;
    this.setAppFilterUrl();
    this.inscricao = this._route.queryParams.subscribe(
      (queryParams: any) => {
        if (queryParams['searchType']) {
          this.tipoBusca = queryParams['searchType'];
        } else {
        }
      }
    );

    this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'searchType': this.tipoBusca }, queryParamsHandling: 'merge' });
    // console.log(this.tipoBusca)
    this.titleService.setTitle('New tecnoscópio - Sinais');
    this.metaService.addTags([
      { name: 'description', content: 'Mais de 3 mil sinais compartilhado pelos mecânicos.' },
    ]);
    // this.assinantes = this._appService.getAssinantes();
    // if (this.assinantes.length == 0) {
    //   this._appService.getEmailUsers().subscribe((valor: any) => {
    //     this.assinantes = valor.assinaturas /* luizotavioautomacao@gmail.com  */
    //     this._appService.setAssinantes(this.assinantes);
    //   });
    // }

    this.renderer.setStyle(this.element.nativeElement.offsetParent, 'overflow', 'visible');

    this.selectTab();
    this.user = await this.appService.getUser().value;
    this.setTagOrder();
    this.setPage(this.page);
    this.checkIsPrivate();
    this.firstLoad = false;

    // this._signalService.getUserInfoForSignalSimplified('').subscribe((res: any) => {
    //   this.listUsuario = res.list; // lista com todos os usuário que tem pelo menos um sinal
    // });

    this.notificacoes = this.user.pop_up;
    // console.log('this.notificacoes', this.notificacoes)
    this.loading = false;

    DiagramaFiltroService.novaBusca.subscribe((res) => {
      this.loadingDiagrama = true;
      const page = this.diagramaService.returnFiltroPage();
      this.page = page.value;
      // window.scroll(0, 100);
    });
    DiagramaFiltroService.fimNovaBusca.subscribe((res) => {
      this.loadingDiagrama = false;
    });

    SignalService.searchSignal.subscribe((res) => {
      this.getList();
    });
  }

  ngOnDestroy() {
    // this.subscription.forEach(s => s.unsubscribe());
    this.inscricao.unsubscribe(); 
  }

  ngAfterViewChecked(){
    if (this.start) {
      this.countNgAfterViewChecked = 2;
    }
    this.start = false;
    if (this._appService.getSinalService() && this.countNgAfterViewChecked <= 3) {
      this.getList();
      this.countNgAfterViewChecked++
    }
  }

  ngAfterViewInit() {
    this._signalService.emitterSearchSignal();
  }

  setAppFilterUrl() {
    let inscricao = this._route.queryParams.subscribe(
      (queryParams: any) => {
        if (queryParams['searchType'] == 'Oscilograma') {
          if (queryParams['module'] !== '' && queryParams['module'] !== undefined && queryParams['module'] !== null) {
            this.tag.module = [queryParams['module']];
          }
          if (queryParams['brand'] !== '' && queryParams['brand'] !== undefined && queryParams['brand'] !== null) {
            this.tag.brand = [queryParams['brand']];
          }
          if (queryParams['model'] !== '' && queryParams['model'] !== undefined && queryParams['model'] !== null) {
            this.tag.model = [queryParams['model']];
          }
          if (queryParams['year'] !== '' && queryParams['year'] !== undefined && queryParams['year'] !== null) {
            this.tag.year = [queryParams['year']];
          }
          if (queryParams['sett'] !== '' && queryParams['sett'] !== undefined && queryParams['sett'] !== null) {
            this.tag.sett = [queryParams['sett']];
          }
          if (queryParams['type'] !== '' && queryParams['type'] !== undefined && queryParams['type'] !== null) {
            this.tag.signalType = [queryParams['type']];
          }
          if (queryParams['injSystem'] !== '' && queryParams['injSystem'] !== undefined && queryParams['injSystem'] !== null) {
            this.tag.injSystem = [queryParams['injSystem']];
          }
          let setItem = this.buildTag(this.tag);
          sessionStorage.setItem('tags', JSON.stringify(setItem));
        } else {

        }
      }
    );
    inscricao.unsubscribe();
  }

  buildTag(tags, buscaUsuario?) {
    for (const [index, [key, value]] of Object.entries(Object.entries(tags))) {
      if (!tags[key].length || tags[key] === 'none') {
        delete tags[key];
      }
    }

    if (buscaUsuario) {
      // console.log('buidTag : buscaUsuario => ', buscaUsuario);
      Object.assign(tags, { search: buscaUsuario.email, userId: buscaUsuario.id });
      // console.log(tags);
    }

    // console.log('buildTag : tags => ', tags);

    return tags;
  }

  selectTab() {
    this.tab = (sessionStorage.getItem('tags'));
    this.tab = JSON.parse(this.tab);
    if (this.tab)
      this.tab = this.tab.module[0]; // if(this._LOG) console.log("tab =>", this.tab);
    if (this._LOG) console.log('f "selectTab() this.tab =>', this.tab);
  }

  getQuery(regexFrom: string) {
    // this.selectTab(); // descobrir qual tab selecionado;

    // return new Promise((resolve) => {
    //   let query: any = { regexFrom: regexFrom, aggregate: false };
    //   query['aggregate'] = true;
    //   if (this._LOG)
    //     console.log("Talvez essa funcion não esteja fazendo diferença: query => ", query);

    //   let users = this.tag_list.filter((tag) => tag.type == 'user');
    //   users.length > 0 ? query['email'] = users.map((user: any) => user.email) : null;

    //   let modules = this.tag_list.filter((tag) => tag.type == 'module');
    //   modules.length > 0 ? query['module'] = modules.map((mod) => mod.value) : null;

    //   let brands = this.tag_list.filter((tag) => tag.type == 'brand');
    //   brands.length > 0 ? query['brand'] = brands.map((brand) => brand.value) : null;

    //   let setts = this.tag_list.filter((tag) => tag.type == 'sett');
    //   setts.length > 0 ? query['sett'] = setts.map((sett) => sett.value) : null;

    //   let evos = this.tag_list.filter((tag) => tag.type == 'evaluation');
    //   evos.length > 0 ? query['evaluation'] = evos.map((evo) => evo.value) : null;

    //   let dates = this.tag_list.filter((tag) => tag.type == 'date');
    //   dates.length > 0 ? query['date'] = dates.map((date) => date.value) : null;
    //   this.lastQuery = query;
    //   if (this._LOG) console.log('depois de processar toda function: getQuery => resolve => ', query);
    //   if (this._LOG) console.log('tag_list é usando somente dentro dessa function: tag_list =>: ', this.tag_list);
    //   resolve(query)
    // })
  }

  async checkIsPrivate() {
    if (this._LOG) console.log('sinal privado?');
    let resHasPrivate: any = await this._signalService.hasSinalsPrivate().pipe(take(1)).toPromise();

    if (this._LOG) console.log(resHasPrivate);

    if (resHasPrivate.sinal_privado)
      this.showCheckBoxPrivate = true;
    else
      this.showCheckBoxPrivate = false;
  }

  async search_auto_complete(keyup: boolean = false) {
    if (this.searchString === '' && keyup) {
      this.search_list = [];
      // this.getList();
      this._signalService.emitterSearchSignal();
      return
    }

    if (this.searchString) {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'search': this.searchString }, queryParamsHandling: 'merge' });
      }, 100);
      this.query = await this.getTags();

      // let sinalService = this._appService.getSinalService();
      // if (!sinalService) {
      //   this._appService.setSinalService(false);
      // console.log('res res');

      let res = (<any>(await this._signalService.filterSignals({ validado: this.signalValid, tags: this.query, ...{ type: 'aggregate', page: 1, sortType: this.by, sort: this.order, search: this.searchString, limit: 10 }, email: this.emailUser }, this.checkBoxPrivate).pipe(take(1)).toPromise()));
      let autocomplete = res.signals.map((signal) => {
        return { ...signal, value: signal.brand + ' - ' + signal.model + ' - ' + signal.year + ' - Versão: ' + signal.versao }
      }) || [];

      // setTimeout(() => {
      //   this._appService.setSinalService(true);
      // }, 350);

      // if(this._LOG) console.log('search_auto_complete : searchString : autocomplete => ', autocomplete);
      this.list = autocomplete;

      if (autocomplete.length > 0) {
        this.search_list = autocomplete;
        if (keyup) {
          this.list = [];
          this.list = res.signals.filter((s, ind, arr) => ind < 10);
          this.setPage(1);
        }
      } else {
        if (this.search_list)
          this.search_list = this.search_list.filter((signal) => signal.value.toLowerCase().indexOf(this.searchString.toLowerCase()) == 0);
        if (keyup) {
          this.list = [];
          this.list = this.search_list.filter((s, ind, arr) => ind < 10);
          this.setPage(1);
        }
      }

      // }

    }
  }

  async getList() {
    let sinalService = this._appService.getSinalService();
    if (!sinalService)
      return;
    if (this._LOG)
      console.log('getList() ...');
    let res: any;
    this.waiting = true;

    this.query = await this.getTags();

    // if(this._LOG) console.log("getList() : query => ", this.query);

    if (this.checkBoxPrivate) {
      res = await this._signalService.filterSignalsPrivate({ tags: this.query, type: 'aggregate', sort: this.order, sortType: 'evaluation', page: this.page, search: this.searchString, email: this.emailUser }).pipe(take(1)).toPromise();
    }

    if (!this.checkBoxPrivate) {

      sinalService = this._appService.getSinalService();
      if (!sinalService) {
        // console.log('!sinal service: return!');
        return;
      }

      this._appService.setSinalService(false);
      // console.log('res res')
      this.subscription.push(this._signalService.filterSignals(
        {
          tags: this.query,
          type: 'aggregate',
          page: this.page,
          sort: this.order,
          sortType: this.by,
          search: this.searchString,
          email: this.emailUser,
          validado: this.signalValid,
        }, this.checkBoxPrivate).subscribe((res: any) => {

          setTimeout(() => {
            this._appService.setSinalService(true);
          }, 350);

          this.list = [];
          this.list = res.signals.map((signal) => {
            return {
              ...signal,
              value: signal.brand +
                ' - ' +
                signal.model +
                ' - ' +
                signal.year +
                ' - Versão: ' +
                signal.versao
            }
          }) || [];

          this.list = res.signals;
          this.total = res.size;
          this.pageQtd = Math.ceil(this.total / 10);
          this.setPage(this.page);
          // console.log(this.list, this.total, this.pageQtd)
          let user = this.appService.getUser().value;
          user.permissao = res.$i;
          // if (!user) sessionStorage.setItem('user', JSON.stringify(user));
          this.waiting = false;
        }));
    }
  }

  filterPrivateEvent() {
    this.checkBoxPrivate = !this.checkBoxPrivate;

    if (this.checkBoxPrivate)
      sessionStorage.setItem('checkBoxPrivate', 'true');
    if (!this.checkBoxPrivate)
      sessionStorage.setItem('checkBoxPrivate', 'false');

    this.orderBy();
    this.setPage(1); if (this._LOG) console.log('filter private');
  }

  async clearSearch() {
    // if(this._LOG) console.log('clearSearch ...');
    this.searchString = '';
    setTimeout(() => {
      this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'search': this.searchString }, queryParamsHandling: 'merge' });
    }, 100);
    // this.getList();
    this._signalService.emitterSearchSignal();
    this.setPage(1);
  }

  clearSort() {
    // if(this._LOG) console.log('clearSort ...');
    this.by = 'date';
    this.changeUrl();
    this._signalService.emitterSearchSignal();
    // this.getList();
  }

  async changeModule(tipo?) {
    if (this._PRINT) console.log(`Estou no changeModule => switch('tipoBusca') => Diagrama ou Oscilograma`);
    if (this._PRINT) console.log("switch(this.tipoBusca)...", this.tipoBusca);

    this._appService.setTipoBusca(this.tipoBusca);
    switch (this.tipoBusca) {
      case "Diagrama":
        // this.mudarTipoBusca(this.tipoBusca)
        break;
      default:
        if (this._PRINT)
          console.log("Em default vamos setar 'order':'-1', 'by': date' e chamar a function 'setPage(1)'. tipoBusca é: ", this.tipoBusca);
        this.tipoBusca = "Oscilograma";
        this.order = -1;
        this.by = 'date';
        await this.setPage(1);
        break;
    }

  }

  async mudarTipoBusca(tipo) {

    if (this._PRINT) console.log(`Estou mudando o tipo de busca: ${tipo}`);

    switch (tipo) {
      case "Diagrama":
        this.tipoBusca = tipo;
        break;
      default:
        this.tipoBusca = "Oscilograma";
        this.changeModule();
        break;
    }

    if (this._LOG)
      console.log('recebi o tipo enviado pelo .emit: ', tipo);

    // if (tipo == undefined)
    //   return;

    // if (tipo === "Diagrama") {
    //   console.log('mudei o tipoBusca: ', tipo);
    //   // this.changeModule(tipo);
    //   this.tipoBusca = tipo;
    //   // this._router.navigate([`${environment.url_version}/sinais-filtro`,tipo]);
    // }

    // if (tipo === "Oscilograma" || !tipo) {
    //   this.tipoBusca = "Oscilograma";
    //   this.order = -1;
    //   this.by = 'date';
    //   await this.setPage(1);
    // }

  }

  async changeDiagramaModule(event?) {
    if (this._LOG)
      console.log(`Output 'action ' chama a function 'changeDiagramaModule' recebe a tag => {{ ${event} }}`);
    switch (event) {
      case "Busca-usuario":
        this.tipoBusca = "Oscilograma";
        this.tab = "Busca-usuario";
        console.log('tipoBusca é "Oscilograma" e tab é "Busca-usuario" e vamos chamar a function "changeModule()."');
        this.changeModule();
        break;
    }
  }

  async get_diagramas(_diagramas) {
    this.waiting = true;
    if (this._LOG)
      console.log(_diagramas);

    if (this._LOG) console.log('Chegou emissão dos DIAGRAMAS...'), console.log(_diagramas);
    this.diagramas = _diagramas.diagramas;

    if (_diagramas.size) {
      this.total = _diagramas.size;
      this.pageQtd = await Math.ceil(this.total / 10);

    }

    this.setPage(this.page, this.pageQtd);
    this.waiting = false;
  }

  // aparentemente não precisa p funcionar
  changeUrl() {
    if (this._PRINT) console.log('changeUrl... acredito que essa function não esteja mudando nada no código!');
    let params = {
      type: 'aggregate',
      page: this.page,
      sort: this.order,
      sortType: this.by,
      search: this.searchString
    };
    // !this.searchString ? await delete params.search : null;
    // this._router.navigate([`${environment.url_version}/sinais-filtro`, params]);
  }

  orderBy() {
    // if(this._LOG) console.log('orderBy');
    this.order = this.order === -1 ? 1 : -1;
    setTimeout(() => {
      this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'recent': this.order }, queryParamsHandling: 'merge' });
    }, 100);
    if (this.order === -1)
      this.recentes = "Mais recentes";
    else
      this.recentes = "Menos recentes";

    this.changeUrl();
    // this.getList();
    this._signalService.emitterSearchSignal();
  }

  public filterSignalValid(evento) {
    this.signalValid = evento;
    if (this.signalValid) {
      this.page = 1;
    }
    this.setPage(this.page);
    this.changeUrl();
    // this.getList();
    this._signalService.emitterSearchSignal();
  }

  sortType(sort) {
    setTimeout(() => {
      this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'order': sort }, queryParamsHandling: 'merge' });
    }, 100);
    // if(this._LOG) console.log('sort ...');
    this.by = sort;
    this.changeUrl();
    // this.getList();
    this._signalService.emitterSearchSignal();
  }

  setTagOrder() {
    // this.page = this._route.snapshot.paramMap.get('page') ?
    //   parseInt(this._route.snapshot.paramMap.get('page')) : 1;

    // this.by = this._route.snapshot.paramMap.get('sortType') ?
    //   this._route.snapshot.paramMap.get('sortType') : 'date';

    // this.order = this._route.snapshot.paramMap.get('sort') ?
    //   parseInt(this._route.snapshot.paramMap.get('sort')) : 1;
  }

  getTagSort() {
    // this.setTagOrder();
    if (this.by === 'downloaded') { return 'download' }
    if (this.by === 'evaluation') { return 'avaliação' }
    if (this.by === 'votes') { return 'votos' }
  }

  // buscar as tags que compõe o filtro
  async getTags() {
    let tags = {};

    if (sessionStorage.getItem('tags')) {
      tags = Object.assign(
        {},
        await this.getQuery('list'),
        JSON.parse(sessionStorage.getItem('tags'))
      );
      // if(this._LOG) console.log("getTags => ", tags);
    }
    else {
      // tags = await this.getQuery('list');
      tags = { ...tags, ...this.moduleDefault }
    }
    if (this._LOG) console.log('tags: ', tags);
    return tags;
  }

  // onActivate() {
  //   setTimeout( () => {
  //     let top = document.getElementById('content-filter');
  //     if (top !== null && !this.firstLoad) {
  //       top.scrollIntoView();
  //       top = null;
  //     }
  //   } ,10)
  // }

  async setPage(page: number, sizeDiagrama?) {
    window.scroll(0, 0);
    setTimeout(() => {
      this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'page': page }, queryParamsHandling: 'merge' });
    }, 100);
    if (sizeDiagrama) this.sizeDiagrama = sizeDiagrama;
    if (this._LOG) console.log(`estamos em setPage(page) com tipoBusca: ${this.tipoBusca}`);
    this.page = page;
    if (this._LOG) console.log(`SetPage: ${page}`);

    switch (this.tipoBusca) {
      case "Diagrama":
        this.pager = await this.pagerService.getPager(this.pageQtd, this.page, 10);
        break;
      default:
        // await this.getList(); // busca sinais
        this._signalService.emitterSearchSignal();
        if (this._PRINT) console.log(`PAGER SINAIS ===>>>`);
        this.pager = await this.pagerService.getPager(this.pageQtd, page, 10);
        if (this._LOG)
          console.log(this.list);
        this.pagedItems = this.list.slice(this.pager.startIndex, this.pager.endIndex + 1);
        if (this._LOG)
          console.log(this.pagedItems);

        this.page = page;
        this.changeUrl();
        if (this._LOG)
          console.log(this.pager, this.pagedItems, this.list.slice(0, 1));

        // !this.firstLoad && this.onActivate();
        break;
    }
  }

  async setPageDiagrama(page) {
    window.scroll(0, 0);
    if (this._PRINT) console.log(`setPageDiagrama => emitindo nova busca de diagramas no servidor => ${page}`);

    await this.diagramaService.emitirNovaBusca({ page });

    this.page = page;
    // this.diagramaService.page = this.page;
    if (this._LOG) console.log(`SET-PAGE Diagrama => page: ${page}; => emitindo a página para o serviço do diagrama ...`);
    this.pager = await this.pagerService.getPager(this.pageQtd, this.page, 10);

    if (this._LOG)
      console.log('==> SIZE', this.pager, this.pageQtd);

    this.pagedItems = this.diagramas.slice(this.pager.startIndex, this.pager.endIndex + 1);


    // this.pager = await this.pagerService.getPager(sizeDiagrama, this.page, 10);
    // this.pagedItems = this.diagramas.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // this.page = page;
    // if (this._LOG) console.log(`SetPage Diagrama => page: ${page}; pagedItems: ${this.pagedItems}`);

  }

  PAGE_TESTE() {
    if (this._LOG) console.log(`Page: ${this.page}`);
    if (this._LOG) console.log(`${this.pager}`);
    if (this._LOG) console.log(this.pager);
  }

}
