import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  constructor(private dialog: MatDialogRef<NotificationComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private _router: Router) { }

  ngOnInit() {    
    if (this.data.timer) {
      setTimeout(() => {
        this.dialog.close();
      }, this.data.timer * 1000);
    }
  }


  goTo() {
   this.data.qId ? this._router.navigate([`${this.data.url}/forum/question`, this.data.qId]) : null;
   this.dialog.close();
  }

}
