import { LoginToContinueComponent } from './../alerts/login-to-continue/login-to-continue.component';
import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent, ModalData } from './../alerts/modal/modal.component';

export type ModalType = 'success' | 'error' | 'warning';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private dialog: MatDialog,
    private appService: AppService,
  ) {}

  /**
   * Abre um modal com base nos parâmetros fornecidos.
   * 
   * @param title - Título do modal
   * @param message - Mensagem a ser exibida no modal
   * @param type - Tipo de mensagem (success, error, warning)
   * @param buttonConfirm - Texto do botão de confirmação
   * @param buttonDecline - Texto do botão de cancelamento (opcional)
   * @returns Observable que emite o valor após o modal ser fechado
   */
  openModal(
    title: string,
    message: string,
    type: ModalType,
    buttonConfirm: string,
    buttonDecline: string = ''
  ) {
    const data = this.getModalData(title, message, type, buttonConfirm, buttonDecline);

    const dialogRef = this.dialog.open(ModalComponent, {
      data,
      width: '400px',
      disableClose: true,
    });

    return dialogRef.afterClosed();
  }


  /**
   * Gera os dados necessários para o modal com base no tipo de mensagem.
   * 
   * @param title - Título do modal
   * @param message - Mensagem a ser exibida no modal
   * @param type - Tipo de mensagem (success, error, warning)
   * @param buttonConfirm - Texto do botão de confirmação
   * @param buttonDecline - Texto do botão de cancelamento (opcional)
   * @returns Objeto de dados para o modal
   */
  private getModalData(
    title: string,
    message: string,
    type: ModalType,
    buttonConfirm: string,
    buttonDecline: string
  ): ModalData {
    switch (type) {
      case 'success':
        return {
          background: '#47c9a2',
          icon_title: '&#10004;', // Check mark
          title,
          message,
          buttonConfirm,
          buttonDecline,
          buttonConfirmColor: 'green',
          buttonDeclineColor: 'red',
        };
      case 'error':
        return {
          background: '#e74c3c',
          icon_title: '&#10006;', // Cross mark
          title,
          message,
          buttonConfirm,
          buttonDecline,
          buttonConfirmColor: 'red',
          buttonDeclineColor: 'grey',
        };
      case 'warning':
        return {
          background: '#f39c12',
          icon_title: '&#9888;', // Warning sign
          title,
          message,
          buttonConfirm,
          buttonDecline,
          buttonConfirmColor: 'orange',
          buttonDeclineColor: 'grey',
        };
      default:
        return {
          background: '#434e65',
          icon_title: '&#10067;', // Question mark
          title,
          message,
          buttonConfirm,
          buttonDecline,
          buttonConfirmColor: 'blue',
          buttonDeclineColor: 'grey',
        };
    }
  }

  loginToContinue(){
    if (this.appService.getFakeLoginValue().value) this.dialog.open(LoginToContinueComponent, { disableClose: true });
    return this.appService.getFakeLoginValue().value;
  }
}
