import { ModalService } from './shared/services/modal.service';
import { SucessCreateAccountComponent } from './shared/alerts/sucess-create-account/sucess-create-account.component';
import { Component, ViewChild, OnInit, OnDestroy, ViewChildren } from '@angular/core';
import { Router, RouterEvent, NavigationCancel, NavigationError, NavigationStart, NavigationEnd } from '@angular/router';
import { AppService } from './app.service';
import { take, takeUntil, tap } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { MediaScreenService } from './shared/services/media-screen.service';
import { NewNavBarComponent } from './_new-tecnoscopio-v2/new-nav-bar/new-nav-bar.component';
import dateTimeJson from "./shared/_date-time-json/data";
import { environment } from '../environments/environment';
import { addListener, launch, stop } from 'devtools-detector';
import { MatDialog } from '@angular/material';
const dateTimeData = dateTimeJson();

var flagDevTools;
addListener((isOpen) => {
  flagDevTools = isOpen;
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {


  @ViewChildren('indexBtn', /* TODO: add static flag */ {}) indexTec;
  footer = '';
  destroy$ = new Subject();
  loading = true;
  removeTranslate = false;
  passTest = false;
  devToolsOpen = false;
  loginUser = false;
  intervalTranslate;
  user: any;

  constructor(
    private _router: Router,
    private _appService: AppService,
    private _breakpointObserver: BreakpointObserver,
    private _mediaScreen: MediaScreenService,
    private modalService: ModalService,
    private dialog: MatDialog
  ) {

    this._breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).pipe(takeUntil(this.destroy$)).subscribe((res => {
      this._appService.mediaQuery.next(res);
    }))

    _router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  cookieAccepted = false;

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
      // setTimeout(() => { // here
      // }, 2000);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  async ngOnInit() {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
      this._appService.setMobile("Mobile");
    } else {
      this._appService.setMobile("Desktop");
    }

    const hostname = window.location.hostname;
    if (hostname !== 'tecnoscopio.com.br') {
      this._appService.emitPassSiteTeste(true);
    }

    this._appService.getPassSiteTeste().subscribe(flag => {
      this.passTest = flag;
    });

    // launch();

    this.user = await this._appService.getUser().value;
    this.checkDevToolsChange();
    document.addEventListener('contextmenu', (event) => {
      if (this.user) {
        if (this.user.crudPermission.admin) {
          return
        }
      }
      if (!environment.production) {
        return
      }
      event.preventDefault();
    });
    window.addEventListener('message', event => {
      if (event.data === 'backButton') {
        window.history.back();
      }
    });

    this._appService.getLoginUser().subscribe(flag => {
      this.loginUser = flag;
    });

    const cityArr = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/");

    const city = cityArr[cityArr.length - 1];
    console.log(city)

    Object.keys(dateTimeData).forEach((item) => {
      if (item == city) {
        environment.country = dateTimeData[item];
      }
    });

    window.addEventListener('beforeunload', (e) => {
      sessionStorage.removeItem('indexData');
    });
    this._mediaScreen.viewport.pipe().subscribe((res) => {
    })

    this._router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.intervalTranslate = setInterval(() => {
      const elemento: any = document.getElementsByClassName('skiptranslate');
      if (elemento[1]) {
        elemento[1].style.display = 'none';
      }
    }, 100);

    setTimeout(() => {
      clearTimeout(this.intervalTranslate);
    }, 50000)

    this.checkCookie();
  }

  acceptCookies() {
    // this.cookieService.set( 'Test', 'Hello World' );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    clearTimeout(this.intervalTranslate);
  }
  nav(url) {
    this._router.navigate([url]);
  }

  fakeClick(element, $event) {
    element._elementRef.nativeElement.click();
  }

  log(element) {

  }

  createCookies(): void {
    document.cookie = "cookies_accepted=true; max-age=" + 60 * 60 * 24 * 365 + "; path=/";
    this.cookieAccepted = true;
  }

  checkCookie(): void {
    this.cookieAccepted = document.cookie.split(';').some((item) => item.trim().startsWith('cookies_accepted='));
  }

  checkDevToolsChange() {
    setInterval(() => {
      if (flagDevTools) {
        if (this.user) {
          if (this.user.crudPermission.admin) {
            this.devToolsOpen = false;
            stop();
            return
          }
        }
        if (!environment.production) {
          this.devToolsOpen = false;
          stop();
          return
        }
        this.devToolsOpen = true;
      } else {
        if (this.devToolsOpen) {
          window.location.reload();
        }
        this.devToolsOpen = false;
      }
    }, 1);
  }

  exitModeUser() {
    const token = localStorage.getItem('token-support')
    try {
      this._appService.loginByToken(token).subscribe((res: any) => {
        if (res.code == 200) {
          //Sucesso;
          localStorage.clear();
          sessionStorage.clear();
          this._appService.emit(res.user, token, res.user.acesskey);
          this.dialog.open(SucessCreateAccountComponent, {
            data: {
              title: 'Sucesso',
              icon_title: '&#xE876;',
              message: 'Você está logado como: ' + res.user.personalData.name,
              // message: 'Parabéns, sua conta foi criada. Verifique o email recebido e siga as orientações',
              buttonConfirm: 'Tudo bem',
              buttonDecline: '',
              icon_button: '&#xE5C8;',
              background: '#47c9a2',
              timer: 6
            },
            disableClose: true
          }).afterClosed().pipe(take(1)).subscribe(async (callback) => {
            this._appService.setLoginUser(false);
            this._router.navigate([`osciloscopio/filtros`]);
          })
          return
        } else {
          //Falha
          this.alertMenssage('Falha!', '', res.msg, '', 'Tudo bem', '#ff9966');
          return
        }
      },
        (error) => {
          //Falha
          this.alertMenssage('Falha!', '', error.error.msg, '', 'Tudo bem', '#ff9966');
          return
        });
    } catch (error) {
      //Falha
      this.alertMenssage('Falha!', '', 'Não foi possível logar como o usuário!', '', 'Tudo bem', '#ff9966');
      return
    }
  }

  alertMenssage(title: any, icon: any, message: any, buttonConf: any, buttonDecl: any, background: any) {
    this.dialog.open(SucessCreateAccountComponent, {
      data: {
        title: title,
        icon_title: icon,
        message: message,
        buttonConfirm: buttonConf,
        buttonDecline: buttonDecl,
        icon_button: '&#xE5C8;',
        background: background,
        timer: 0
      },
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
      width: '30em'
    })
  }

}


