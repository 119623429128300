import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router, Route, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { TokenService } from '../alerts/token/token.service';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class FakeLoginGuard implements CanLoad {

  url: any;
  mobileFlag = false;

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private router: Router,
    private tokenService: TokenService
    ) {
      this.appService.getMobileApp().subscribe(flag => {
        this.mobileFlag = flag;
      });
      this.appService.getLogoutFlag().subscribe(flag => {
        if (flag) {
          this.route.data.pipe(take(1)).subscribe((res: any) => {
            this.url = res.type;
          });
    
          if (this.mobileFlag) return;
          try {
            this.loginService.authentication({email: environment.user_fake.email, password: environment.user_fake.password}).subscribe((res: any) => {
              if (res.code == 200) {
                //Sucesso
                localStorage.clear();
                sessionStorage.clear();
                this.tokenService.storageEmail(res.user.auth.email);
                this.appService.emit(res.user, res.token, null, 'from login component');
                this.appService.setFakeLogin(true);
                localStorage.setItem('fake-user', 'true');
              } else {
                //Falha
              }
            },
              (error) => {
                //Falha
              });
          } catch (error) {
            //Falha
          };
        }
      });
  }
  
  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {

    return new Promise(async (resolve, reject) => {

      if (this.mobileFlag) resolve(true);
      
      let token = localStorage.getItem('token');
      console.log('Passando fake login guard...', token)
      
      if (token) {
        if (!this.appService.getUser().value) {
          const res: any = await this.loginService.checkToken(token).pipe(take(1)).toPromise();
          if (res.code == 200) {
            this.appService.emit(res.user, token, null, 'from login component');
          }
        }
        resolve(true);
        return;
      }

      this.route.data.pipe(take(1)).subscribe((res: any) => {
        this.url = res.type;
      });

      try {
        this.loginService.authentication({email: environment.user_fake.email, password: environment.user_fake.password}).subscribe((res: any) => {
          if (res.code == 200) {
            //Sucesso
            localStorage.clear();
            sessionStorage.clear();
            this.tokenService.storageEmail(res.user.auth.email);
            this.appService.emit(res.user, res.token, null, 'from login component');
            this.appService.setFakeLogin(true);
            localStorage.setItem('fake-user', 'true');
            this.router.navigate([`${environment.url_version}/filtros`])
            resolve(true);
          } else {
            resolve(true);
            //Falha
          }
        },
          (error) => {
            resolve(true);
            //Falha
          });
      } catch (error) {
        resolve(true);
        //Falha
      }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise(async (resolve, reject) => {

      if (this.mobileFlag) resolve(true);
      
      let token = localStorage.getItem('token');
      
      if (token) {
        if (!this.appService.getUser().value) {
          const res: any = await this.loginService.checkToken(token).pipe(take(1)).toPromise();
          if (res.code == 200) {
            this.appService.emit(res.user, token, null, 'from login component');
          }
        }
        resolve(true);
        return;
      }

      this.route.data.pipe(take(1)).subscribe((res: any) => {
        this.url = res.type;
      });

      try {
        this.loginService.authentication({email: environment.user_fake.email, password: environment.user_fake.password}).subscribe((res: any) => {
          if (res.code == 200) {
            //Sucesso
            localStorage.clear();
            sessionStorage.clear();
            this.tokenService.storageEmail(res.user.auth.email);
            this.appService.emit(res.user, res.token, null, 'from login component');
            this.appService.setFakeLogin(true);
            localStorage.setItem('fake-user', 'true');
            resolve(true);
          } else {
            resolve(true);
            //Falha
          }
        },
          (error) => {
            resolve(true);
            //Falha
          });
      } catch (error) {
        resolve(true);
        //Falha
      }
    });
  }
}
