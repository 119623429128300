import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DirectiveService {
  public limit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public evaluated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 // public move: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

}
