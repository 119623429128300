import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SharedService } from '../shared.service';

@Injectable()
export class UserService {

  constructor(
    private _http: HttpClient,
    private appService: AppService
  ) { }

  hasLogged(token) {
    return this._http.get(`${environment.api_server}/api/module/user/id`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + token) })
  }

  loginUserByTokenAdmin(token: any) {
    return this._http.post(`${environment.api_server}/api/module/user/login-by-admin`, {}, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + token) })
  }

  getUserInfo() {
    return this._http.get(`${environment.api_server}/api/module/user/id` , { headers: SharedService.header });
  }

  getUser(){
    try {
      this.getUserInfo().subscribe( async (res: any) => {
        if (res.code == 200) {
          //Sucesso
          await this.appService.setUser(res.user);
          return
        } else {
          //Falha
          return
        }
      },
        (error) => {
          //Falha
          return
        });
    } catch (error) {
      //Falha
      return
    }
  }
}
