import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AlertService } from '../alert.service';
import { SucessCreateAccountComponent } from '../sucess-create-account/sucess-create-account.component';

@Component({
  selector: 'app-view-tutorial',
  templateUrl: './view-tutorial.component.html',
  styleUrls: ['./view-tutorial.component.scss']
})
export class ViewTutorialComponent implements OnInit {
  
  tutorialId: any = null;
  tutorial: any = null;

  filtroView: boolean = true;
  loading: boolean = true;

  numPagPdf = 1;
  pageIndex = 1;

  constructor(
    private alertService: AlertService,
    private _dialog: MatDialog,
    private dialog: MatDialogRef<ViewTutorialComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Data_Interface
  ) { }

  ngOnInit() {
    this.tutorialId = this.data.tutorialId
    this.filtroView = this.data.filtroView
    if (this.filtroView) {
      this.viewTutorial(this.tutorialId);
    }
  }

  cancel() {
    this.dialog.close(false);
  }

  async viewTutorial(id){
    this.loading = true;
    try {
      this.alertService.getTutorialId(id).subscribe((res01: any) => {
        if (res01.code == 200) {
          try {
            this.alertService.downloadPDF(res01.PDFs[0]._id).subscribe(async (res02) => {
              this.tutorial = { data: new Uint8Array(res02), id: res01.PDFs[0]._id, titulo: res01.PDFs[0].titulo };
              this.loading = false;
              return
            },
              (error) => {
                this.loading = false;
                return
              }
            );
          } catch (error) {
            this.loading = false;
            return
          }
          return;
        } else {
          this.alertMenssage('Falha!', '', 'Não foi possível encontrar o tutorial!', '', 'Tudo bem', '#ff9966');
          this.loading = false;
          return;
        }
      },
        (error) => {
          this.alertMenssage('Falha!', '', error.error.msg, '', 'Tudo bem', '#ff9966');
          this.loading = false;
          return;
        }
      );
    } catch (error) {
      this.alertMenssage('Falha!', '', 'Não foi possível encontrar o tutorial!', '', 'Tudo bem', '#ff9966');
      this.loading = false;
      return;
    }
  }

  pdfController(flag) {
    if (flag) {
      if (this.pageIndex < this.numPagPdf) {
        this.pageIndex++
      }
    }
    if (!flag) {
      if (this.pageIndex > 1) {
        this.pageIndex--
      }
    }
  }

  afterLoadComplete(pdf) {
    if (pdf.pdfInfo) {
      this.numPagPdf = pdf.pdfInfo.numPages;
    }
  }

  alertMenssage(title: any, icon: any, message: any, buttonConf: any, buttonDecl: any, background: any) {
    this._dialog.open(SucessCreateAccountComponent, {
      data: {
        title: title,
        icon_title: icon,
        message: message,
        buttonConfirm: buttonConf,
        buttonDecline: buttonDecl,
        icon_button: '&#xE5C8;',
        background: background,
        timer: 0
      },
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
      width: '30em'
    })
  }

}

interface Data_Interface {
  tutorialId: string,
  filtroView: boolean
}


