import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
// import { UserService } from '../user/user.service';

@Injectable()
export class LoginTokenGuard implements Resolve<any> {

  constructor() { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise(resolve => {
      let token = route.params['token'];
      
      // console.log('guarda de rota [login by token] => ', token);

      setTimeout(() => {
        resolve(token)
      }, 50);
    });



  }

}
