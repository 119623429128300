import { AppService } from './../../../app.service';
import { AlertService } from './../alert.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-view-files-curse',
  templateUrl: './view-files-curse.component.html',
  styleUrls: ['./view-files-curse.component.scss']
})
export class ViewFilesCurseComponent implements OnInit {

  displayType = '';
  titulo = '';
  tutoriais = [];
  fotos = [];
  pager = null;
  user = null;
  slideIndex = 0;
  zoomPdf = 0;
  numPagPdf = 0;
  pageIndex = 1;
  loading = false;
  selectedPhotoIndex: number | null = null;

  constructor(
    private dialog: MatDialogRef<ViewFilesCurseComponent>,
    private alertService: AlertService,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) private data: Data_Interface
  ) { }

  async ngOnInit() {
    this.user = await this.appService.getUser().value;
    this.titulo = this.data.titulo
    switch (this.data.type) {
      case 'tutorial':
        this.getTutorial();
        this.displayType = 'Tutoriais'
        break;
      case 'image':
        this.getImage();
        this.displayType = 'Imagens'
        break;

      default:
        break;
    }
  }

  close() {
    this.dialog.close();
  }

  getImage() {
    this.loading = true;
    this.data.id.forEach(id => {
      try {
        this.alertService.getImg(id).subscribe((res: any) => {
            //Sucesso
            var binary = '';
            var bytes = new Uint8Array(res);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            this.fotos.push({data: 'data:image/jpg;base64,' + window.btoa(binary), id: id});
            this.loading = false;
        },
          (error) => {
            //Falha
            this.loading = false;
          });
      } catch (error) {
        //Falha
        this.loading = false;
      }
    });
  }

  getTutorial() {
    this.data.id.forEach(id => {
      try {
        this.alertService.downloadPDF(id).subscribe(
          (res) => {
            this.tutoriais.push(new Uint8Array(res)); //Converte blob para Uint8Array
          },
          (error) => {
          }
        );
      } catch (error) {
      }
    });
  }

  voltarProximoSlide(array, direção) {
    console.log(array)
    if (direção) {
      //PROXIMO
      if (array.length - 1 < this.slideIndex + 1) {
        return
      }
      this.slideIndex++
    } else {
      //VOLTAR
      if (this.slideIndex - 1 < 0) {
        return
      }
      this.slideIndex--
    }
  }

  pdfController(flag) {
    if (flag) {
      if (this.pageIndex < this.numPagPdf) {
        this.pageIndex++
      }
    }
    if (!flag) {
      if (this.pageIndex > 1) {
        this.pageIndex--
      }
    }
  }

  afterLoadComplete(pdf){
    if (pdf.pdfInfo) {
      this.numPagPdf = pdf.pdfInfo.numPages;
    }
  }

  changeZoomPdf(e){
    this.zoomPdf = 0.02*e.target.value
    console.log(0.02*e.target.value)
  }

  selectSlideIndex(i){
    this.slideIndex = i;
  }

  selectPhoto(index: number): void {
    this.selectedPhotoIndex = index;
  }

  closeFullscreen(): void {
    this.selectedPhotoIndex = null;
  }
}

interface Data_Interface {
  id: any;
  titulo: string;
  type: string;
}

