import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SucessCreateAccountComponent } from '../../shared/alerts/sucess-create-account/sucess-create-account.component';
import { DiagramaService } from '../service/diagrama.service';
import { LoginService } from '../service/login.service';

@Injectable()
export class UsuarioDiagramaGuard {

  l = false;

  constructor(
    private loginService: LoginService,
    private diagramaService: DiagramaService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise(async(resolve, reject) => {
      let split: any = state.url;
      split = split.split("/");
      let token = localStorage.getItem('token');
      let diagramaId = split[4];

      if (!token && !diagramaId)
        resolve(false);
      await this.verificarAcessoByToken(token, diagramaId);
      resolve(true);
    })
  }

  private async verificarAcessoByToken(token, diagramaId) {

    await this.loginService.authenticationByToken(token, diagramaId).subscribe((res: any) => {
      let kanvas: string;
      if (res.code == 401) {
        this.dialog.open(SucessCreateAccountComponent, {
          data: {
            title: 'Aviso!',
            icon_title: '&#xE85A;',
            message: 'Para ter acesso a esse diagrama, faça login em nosso site!',
            buttonConfirm: '',
            buttonDecline: 'Tudo bem',
            icon_button: '&#xE5C8;',
            background: '#ff9966',
            timer: 6
          },
          disableClose: true
        }).afterClosed().pipe(take(1)).subscribe(async (callback) => {
          this.router.navigate([`${environment.url_version}/user/login`])
        });
      }

      if (res.code == 200 && res.diagrama) {
        kanvas = res.diagrama;
        if (this.l) console.log('emitir kanvas');
        this.diagramaService.emitirKanvas.emit(kanvas);
        this.diagramaService.permissionAcess.emit(true);
      }

      if (res.code != 200) {
        this.router.navigate([`/${environment.url_version}`]);
        this.diagramaService.permissionAcess.emit(false);
      }
    },
      (error) => {
        this.router.navigate([`/${environment.url_version}`]);
        this.diagramaService.permissionAcess.emit(false);
      }
    )
  }
}
