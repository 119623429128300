import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-sucess-create-account',
  templateUrl: './sucess-create-account.component.html',
  styleUrls: ['./sucess-create-account.component.scss']
})
export class SucessCreateAccountComponent implements OnInit, AfterViewInit {

  dataToReturn;

  constructor(private dialog: MatDialogRef<SucessCreateAccountComponent>,
    private appService: AppService,
     @Inject(MAT_DIALOG_DATA) private data: Data_Interface) {
      dialog.beforeClose().subscribe(() => dialog.close(this.dataToReturn));
  }

  ngOnInit() {
    if (this.data.timer > 0) {
      setTimeout(() => {
        this.dialog.close(false);
      }, this.data.timer * 1000);
    }
  }

  ngAfterViewInit() {
  }

  beforeClose(){
    return this.dataToReturn;
  }

  cancel() {
    this.dataToReturn = false;
    this.appService.acceptOrRecuseAlert(false);
    this.dialog.close(false);
  }

  confirm() {
    this.dataToReturn = true;
    this.appService.acceptOrRecuseAlert(true);
    this.dialog.close(true);
  }

}

interface Data_Interface {
  title: string;
  icon_title: string;
  message: string;
  buttonConfirm: string;
  buttonDecline: string;
  buttonConfirmColor: string;
  buttonDeclineColor: string;
  icon_button: string;
  background: string;
  timer: number;
}