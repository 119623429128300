import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared/shared.service';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class AssinanteService {

  private selectedPlanKey = 'selectedPlan';

  constructor(private http: HttpClient) { }

  private response: any;

  criaRecorrencia(clienteId, produto, recorrencia, payment, bancoId) {
    this.response = this.http.post(`${environment.api_server}/api/assinantes/recorrencia/create`,
      { clienteId: clienteId, produto: produto, recorrencia: recorrencia, payment: payment, bancoId: bancoId }, { headers: SharedService.header });
    return this.response;
  }

  postRecorrencia(recorrencia) {
    return this.http.post(`${environment.api_server}/api/assinantes/recorrencia/create/newtecnoscopio`,
      recorrencia, { headers: SharedService.header });
  }

  renovarPlanoCupom(cupom) {
    return this.http.post(`${environment.api_server}/api/assinantes/recorrencia/cupom/estender`, { nomeCupom: cupom }, { headers: SharedService.header });
  }

  renovarPlanoCartao(params) {
    return this.http.post(`${environment.api_server}/api/assinantes/recorrencia/renovar/pagamento`, params, { headers: SharedService.header });
  }

  validaCodigoPromocional(codigoPromocional) {
    this.response = this.http.post(`${environment.api_server}/api/cielo/promotional-code`,
      { codigo: codigoPromocional }, { headers: SharedService.header });
    return this.response;
  }

  getRecorrenciaByUserId() {
    return this.http.post(`${environment.api_server}/api/assinantes/recorrencia/get`, { "clienteId": environment.client_id }, { headers: SharedService.header });
  }

  cancelarRecorrencia(infos) {
    return this.http.post(`${environment.api_server}/api/assinantes/recorrencia/cancela`, { "clienteId": environment.client_id, infos }, { headers: SharedService.header });
  }

  changeDataCreditCard(data, installments) {
    return this.http.post(`${environment.api_server}/api/assinantes/recorrencia/update/cartao-credito`, { clienteId: environment.client_id, payment: data, Installments: installments }, { headers: SharedService.header });
  }

  gerarQRCodePix(produto) {
    return this.http.post(`${environment.api_server}/api/pix/payload/qrcode/create`, { produto }, { headers: SharedService.header });
  }

  renovaRecorrencia() {
    return this.http.post(`${environment.api_server}/api/assinantes/recorrencia/update/newtecnoscopio`,
      { clienteId: environment.client_id, bancoId: environment.bank_id }, { headers: SharedService.header });
  }

  updateCard(params: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/user/update/card`, params, { headers: SharedService.header, observe: 'response' });
  }

  blockUpcomingRenewals(idSubscription: any, autoRenew: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/subscription/auto-renew/${idSubscription}/${autoRenew}`, {}, { headers: SharedService.header, observe: 'response' });
  }

  removeCard() {
    return this.http.delete(`${environment.api_server}/api/user/remove/card`, { headers: SharedService.header, observe: 'response' });
  }

  subscriptionHandle(url: string, params: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}${url}`, params, { headers: SharedService.header, observe: 'response' });
  }

  setSelectedPlan(plan: { id: string, type: 'subscription' | 'renewal', oldSubscriptionId?: string } | null): void {
    if (plan) {
      sessionStorage.setItem(this.selectedPlanKey, JSON.stringify(plan));
    } else {
      sessionStorage.removeItem(this.selectedPlanKey);
    }
  }

  getSelectedPlan(): { id: string, type: 'subscription' | 'renewal', oldSubscriptionId?: string } | null {
    const plan = sessionStorage.getItem(this.selectedPlanKey);
    if (plan) {
      sessionStorage.removeItem(this.selectedPlanKey);
      return JSON.parse(plan);
    }
    return null;
  }
}
