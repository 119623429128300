import { Directive, Input, ElementRef, Renderer, SimpleChanges, ChangeDetectorRef, HostListener, ViewRef } from '@angular/core';
// import { DiagramV2Service } from '../../diagram-v2/services/diagram-v2.service';

@Directive({
  selector: '[appRange]'
})
export class RangeDirective {
  @Input() width: string;
  @Input() change: string | number;
  slider1: any;
  slider2: any;
  range_value: HTMLElement;
  value: number;
  s1Bound;
  s2Bound;
  thumb_1;
  thumb_2;
  constructor(private _element: ElementRef, private _renderer: Renderer, private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    // this._renderer.setElementStyle(this._element.nativeElement, 'width', this.width);
    this.slider1 = this._element.nativeElement.querySelector('#range-min');
    this.slider2 = this._element.nativeElement.querySelector('#range-max');
    this.range_value = this._element.nativeElement.querySelector('#range-value');
    this.setPosition(this.slider1, 0, 0);
    this.setPosition(this.slider2, 0, 0);
    this._renderer.setElementStyle(this.range_value, 'min-height', '3px');
    this._renderer.setElementStyle(this.range_value, 'background', '#E74C81');

    this.setPosition(this.range_value, this.slider1.getBoundingClientRect().height / 2, 0);
  }

  ngAfterViewInit() {
    setTimeout(() => {

      this.thumb_1 = this.slider1.querySelector('.mat-slider-thumb');
      this.thumb_2 = this.slider2.querySelector('.mat-slider-thumb');
      this.setRange()

    }, 1000);
  }

  async ngOnChanges(change: SimpleChanges) {
    try {
      if (change.change.firstChange) {
        return;
      }

      await this.setRange();
    } catch (error) {
      return;
    }
  }

  @HostListener('mousedown', ['$event']) down($event) {
    try {

      let target: HTMLElement = $event.target;
      let container: HTMLElement = document.getElementById('range-container');

      if (this.slider1.contains(target)) {
        container.appendChild(this.slider2)
        container.appendChild(this.slider1)
        container.appendChild(this.range_value)

      }

      if (this.slider2.contains(target)) {
        container.appendChild(this.slider1)
        container.appendChild(this.slider2)
        container.appendChild(this.range_value)

      }

    } catch (error) {

    }

  }



  @HostListener('document: mouseup') async up() {
    let interval = setInterval(async () => {
      await this.setRange();
      setTimeout(async () => {
        clearInterval(interval)
      }, 500);
    }, 40)

  }
  async setRange() {
    // let s1Bound = await this._service.getBounds(this.thumb_1.getBoundingClientRect(), this._element.nativeElement.getBoundingClientRect());
    // let s2Bound = await this._service.getBounds(this.thumb_2.getBoundingClientRect(), this._element.nativeElement.getBoundingClientRect());
    // let w = Math.min(Math.abs(s2Bound.centerx - s1Bound.centerx), Math.abs(s1Bound.centerx - s2Bound.centerx));
    // this._renderer.setElementStyle(this.range_value, 'width', w + 'px');
    // this.setPosition(this.range_value, s1Bound.centery - 1, s1Bound.centerx);
    if (!(this._cdr as ViewRef).destroyed ) {
      this._cdr.detectChanges();
    }
  }



  setPosition(element, top = 0, left = 0) {
    this._renderer.setElementStyle(element, 'position', 'absolute');
    this._renderer.setElementStyle(element, 'top', top + 'px');
    this._renderer.setElementStyle(element, 'left', left + 'px');
  }
}
