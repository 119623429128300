import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SharedService } from '../shared.service';

@Injectable()
export class QuestionService {

  constructor(private _http: HttpClient) { }


  getQuestions(index, size, search: string = 'NXD', by: string = 'NXD', order: number = 1) {
    search = search == '' ? 'NXD' : search;
    return this._http.get(`${environment.api_server}/api/module/user/forum/index/${index}/size/${size}/search/${search}/by/${by}/order/${order}`, { headers: SharedService.header });
  }

  getQuestionsBySignal(index, size, search: string = 'NXD', field, by: string = 'NXD', order: number = 1, signal) {
    search = search == '' ? 'NXD' : search;
    signal = signal == '' ? 'NXD' : signal;
    return this._http.get(`${environment.api_server}/api/module/user/forum/signal/${signal}/index/${index}/size/${size}/search/${search}/field/${field}/by/${by}/order/${order}`, { headers: SharedService.header });
  }
  newQuestion(question) {
    return this._http.post(`${environment.api_server}/api/module/user/forum/question`, question, { headers: SharedService.header });
  }

  relevance(id, v) {
    return this._http.put(`${environment.api_server}/api/module/user/forum/question/${id}/relevance/${v}`, {}, { headers: SharedService.header });
  }

  deleteAnswer(id, idAnswer) {
    return this._http.delete(`${environment.api_server}/api/module/user/forum/question/${id}/${idAnswer}`, { headers: SharedService.header });
  }

  updateAnswer(id, idAnswer, update) {
    return this._http.put(`${environment.api_server}/api/module/user/forum/question/${id}/${idAnswer}`, update, { headers: SharedService.header });
  }

  getquestion(id) {
    return this._http.get(`${environment.api_server}/api/module/user/forum/question/${id}`, { headers: SharedService.header });
  }

  evaluation(id, a_id, field, val) {
    return this._http.get(`${environment.api_server}/api/module/user/forum/question/${id}/answer/${a_id}/field/${field}/val/${val}`, { headers: SharedService.header });
  }

  newAnswer(id, form) {
    return this._http.post(`${environment.api_server}/api/module/user/forum/question/${id}`, form, { headers: SharedService.header });
  }

  deleteQuestions(id) {
    return this._http.delete(`${environment.api_server}/api/module/user/forum/question/${id}`, { headers: SharedService.header });
  }

  updateQuestions(id, form) {
    return this._http.put(`${environment.api_server}/api/module/user/forum/question/${id}`, form, { headers: SharedService.header });
  }

  leaveQuestion(id) {
    return this._http.get(`${environment.api_server}/api/module/user/forum/question/leave/${id}`, { headers: SharedService.header });
  }

  joinQuestion(id) {
    return this._http.get(`${environment.api_server}/api/module/user/forum/question/follow/${id}`, { headers: SharedService.header });
  }
 
}
