import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() footer_azul: boolean // receber 1 objeto  do tipo sinal (Buffer no MongoDB)

  whatsApp = "";

  routerLink_URL = environment.url_version == '' ? `${environment.url_version}` : `/${environment.url_version}`;

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  navigateTo(route) {
    this._router.navigate([`${environment.url_version}/${route}`]);
  }

  detectar_mobile() {

    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      // return true;
      this.whatsApp = "https://api.whatsapp.com/send?phone=554499818097";
    }
    else {
      // return false;
      this.whatsApp = "https://web.whatsapp.com/send?phone=554499818097";
    }

  }

} 
