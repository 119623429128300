import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-generated-recurrence',
  templateUrl: './generated-recurrence.component.html',
  styleUrls: ['./generated-recurrence.component.scss']
})
export class GeneratedRecurrenceComponent implements OnInit {
  constructor(private dialog: MatDialogRef<GeneratedRecurrenceComponent>, @Inject(MAT_DIALOG_DATA) private data: Data_Interface) { }

  ngOnInit() {
    if (this.data.timer > 0) {
      setTimeout(() => {
        this.dialog.close(false);
      }, this.data.timer * 1000);
    }
  }

  cancel() {
    this.dialog.close();
  }

  confirm() {
    this.dialog.close(true); // return with callback
  }

}

interface Data_Interface {
  message: string;
  type: string;
  timer: number;
  cancelButton: boolean;
  confirmButton: boolean;
  confirmButtonText: string;
  cancelButtonText: string;
}

