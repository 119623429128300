import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { AppService } from "../../app.service";

@Injectable()
export class NavBarGuard implements Resolve<any> {

    constructor(
        private appService: AppService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {

        return new Promise(resolve => {

            try {
                const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));
                let user = this.appService.getUser().value;

                this.appService.user.subscribe((_user) => {

                    if (user || _user) {
                        this.appService.emitShowNavBar(this.appService.getNavBarComLogin(), 'from nav-bar.guard 1');
                        resolve(user || _user);
                    }

                    if ((!_user && !user) || !token) {
                        this.appService.emitShowNavBar(this.appService.getNavBarSemLogin(), 'from nav-bar.guard 2');
                        resolve(false);
                    }

                });

            } catch (e) {
                resolve(false);
            }
        });
    }
}
