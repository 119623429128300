import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-verifica-token',
  templateUrl: './verifica-token.component.html',
  styleUrls: ['./verifica-token.component.scss']
})
export class VerificaTokenComponent implements OnInit {

  token;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {

    this._route.data.subscribe(data => {
      this.token = data.token;
      // console.log("Token no componente => ", this.token);

      this.loginService.checkToken(this.token).subscribe(async (res: any) => {

        if (res) {

          if (res.code == 200) {
            sessionStorage.clear();
            localStorage.clear();
            localStorage.setItem('token', this.token);
            sessionStorage.setItem('sessao', 'true');
            sessionStorage.setItem('software-new', 'true');
            sessionStorage.setItem('email', res.user.auth.email);
            sessionStorage.setItem("user", JSON.stringify(res.user));

            this._router.navigate([`${environment.url_version}/filtros`]);

          } else this._router.navigate([`${environment.url_version}/user/login`]);

        } else this._router.navigate([`${environment.url_version}/user/login`]);

      });

    })


  }

}
