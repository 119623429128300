import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable()
export class SeoService {

  constructor(private _meta: Meta) { }

  configTags(config) {    
    this._meta.updateTag({...config})
  }

}
