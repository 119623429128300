import { AlertService } from './../alert.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Component({
  selector: 'app-info-atrelamento',
  templateUrl: './info-atrelamento.component.html',
  styleUrls: ['./info-atrelamento.component.scss']
})
export class InfoAtrelamentoComponent implements OnInit {
  displayTitle = '';
  videos: any[] = [];
  tutorials: any[] = [];
  photos: any[] = [];
  slideIndex = 0;
  pageIndex = 1;
  pdfZoom = 1;
  isLoading = false;
  isFullscreen = false;
  fullscreenImage: string = '';

  constructor(
    private dialogRef: MatDialogRef<InfoAtrelamentoComponent>,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  // Inicialização do componente
  ngOnInit(): void {
    console.log(this.data);
    this.loadContent();
  }

  // Fecha o modal
  closeDialog(): void {
    this.dialogRef.close();
  }

  // Carrega o conteúdo baseado no tipo selecionado
  private loadContent(): void {
    this.isLoading = true;
    switch (this.data.type) {
      case 'photo':
        this.loadPhotos();
        this.displayTitle = 'Fotos';
        break;
      case 'video':
        this.loadVideos();
        this.displayTitle = 'Vídeos';
        break;
      case 'tutorial':
        this.loadTutorials();
        this.displayTitle = 'Tutoriais';
        break;
    }
  }

  // Carrega as fotos
  private loadPhotos(): void {
    this.data.id.forEach((id: string) => {
      this.getPhoto(id).subscribe(
        (photo) => this.photos.push(photo),
        () => (this.isLoading = false),
        () => (this.isLoading = false)
      );
    });
  }

  // Carrega os vídeos
  private loadVideos(): void {
    this.videos = this.data.id || [];
    this.isLoading = false;
  }

  // Carrega os tutoriais
  private loadTutorials(): void {
    this.data.id.forEach((id: string) => {
      this.getTutorial(id).subscribe(
        (tutorial) => this.tutorials.push(tutorial),
        () => (this.isLoading = false),
        () => (this.isLoading = false)
      );
    });
  }

  // Altera o slide atual
  navigateSlide(array: any[], forward: boolean): void {
    if (forward && this.slideIndex < array.length - 1) {
      this.slideIndex++;
    } else if (!forward && this.slideIndex > 0) {
      this.slideIndex--;
    }
  }

  // Seleciona o índice do slide
  selectSlideIndex(index: number): void {
    this.slideIndex = index;
  }


  // Função para carregar imagens com correções e utilizando alertService
  getPhoto(photoId: any): Observable<any> {
    return this.alertService.getImg(photoId).pipe(
      map((response: any) => {
        // Converte o Blob em Base64 para exibição
        let binary = '';
        const bytes = new Uint8Array(response);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return {
          id: photoId,
          data: 'data:image/jpg;base64,' + window.btoa(binary),
        };
      }),
      catchError((error) => {
        console.error('Erro ao carregar a foto:', error);
        return throwError(() => new Error('Erro ao carregar a foto'));
      })
    );
  }

  // Função para carregar tutoriais em PDF com correções e utilizando alertService
  getTutorial(tutorialId: any): Observable<any> {
    return this.alertService.downloadPDF(tutorialId).pipe(
      map((response: ArrayBuffer) => {
        const pdfFile = new Blob([response], { type: 'application/pdf' });
        const url = URL.createObjectURL(pdfFile);

        return {
          id: tutorialId,
          file: url,
        };
      }),
      catchError((error) => {
        console.error('Erro ao carregar o tutorial:', error);
        return throwError(() => new Error('Erro ao carregar o tutorial'));
      })
    );
  }

  openFullscreen(imageSrc: string): void {
    this.fullscreenImage = imageSrc;
    this.isFullscreen = true;
  }

  closeFullscreen(): void {
    this.isFullscreen = false;
    this.fullscreenImage = '';
  }

}

interface Data_Interface {
  id: any;
  sinalId: any;
  type: string;
}
