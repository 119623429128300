import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-loading-filtro-v2',
  templateUrl: './loading-filtro-v2.component.html',
  styleUrls: ['./loading-filtro-v2.component.scss']
})
export class LoadingFiltroV2Component implements OnInit, AfterViewInit {

  dataToReturn;

  constructor(private dialog: MatDialogRef<LoadingFiltroV2Component>,
    private appService: AppService,
     @Inject(MAT_DIALOG_DATA) private data: Data_Interface) {
      dialog.beforeClose().subscribe(() => dialog.close(this.dataToReturn));
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

}

interface Data_Interface {
  message: string;
}
