import { Directive, Input, ElementRef, Renderer, OnChanges, HostListener } from '@angular/core';

@Directive({
  selector: '[appEvaluationV2]'
})
export class EvaluationV2Directive {
  @Input('average') average: number;
  @Input('disabled') disabled: boolean;
  @Input('vote') vote: number = -1;
  @Input('fontsize') fontsize: number = 20;
  stars: Array<{ element: HTMLElement, index: number }> = [];

  iniciate = false;

  constructor(private _element: ElementRef, private _renderer: Renderer) { }

  ngOnInit() {}

  ngAfterViewInit() {
    for (let index = 0; index < 5; index++) {
      let element = this._element.nativeElement.querySelector(`#star_${index}`);
      this.stars.push({ element: element, index: index })
    }

    this.stars.forEach((e, i, arr) => {
      this.setStarStyle(arr[i]);
      this._renderer.setElementStyle(arr[i].element, 'font-size', `${this.fontsize - 5}px`)

      if (!this.disabled) {
        arr[i].element.addEventListener('mouseenter', () => {
          this._renderer.setElementStyle(arr[i].element, 'font-size', `${this.fontsize}px`)
        });
        arr[i].element.addEventListener('mouseleave', () => {
          this.vote != i + 1 ? this._renderer.setElementStyle(arr[i].element, 'font-size', `${this.fontsize - 5}px`) : null;
        })

        if (this.vote == i + 1) {
          this._renderer.setElementStyle(arr[i].element, 'font-size', `${this.fontsize}px`);
        }
      }
    });

    this.iniciate = true;
  }

  ngOnChanges(changes: any) {
    if (changes.average) {
      !changes.average.firstChange ? this.stars.forEach((e, i, arr) => {
        this.setStarStyle(arr[i])
      }) : null;
    }

    if (changes.vote) {
      let starCurr = this.stars.filter((s) => s.index == this.vote - 1)[0];
      starCurr ? this._renderer.setElementStyle(starCurr.element, 'font-size', `${this.fontsize}px`) : null;
      let starPrev = this.stars.filter((s) => s.index == changes.vote.previousValue - 1)[0];
      starPrev ? this._renderer.setElementStyle(starPrev.element, 'font-size', `${this.fontsize - 5}px`) : null;

      this.stars.forEach((e, i, arr) => {
        if (!this.disabled) {
          if (this.vote == i + 1) {
            this._renderer.setElementStyle(arr[i].element, 'transform', `translateY(-2.5px)`);
          }
        }

      });
    }
  }

  setStarStyle(star: any) {
    // console.log('AQUI TÁ A ESTRELA', star);
    let permissao = star.element.className.substring(15,16);
    let { element, index } = star;
    // console.log(element)
    if (this.average == 0 || Math.floor(this.average) < index) {
      this._renderer.setElementStyle(element, 'background', `#777777`);
    }
    else if (Math.floor(this.average) == index) {
      let val = this.average - index;
      let percent = val * 100 / 1;
      if(permissao == 0){
        this._renderer.setElementStyle(element, 'background', `linear-gradient(to right, #FFA500 0%, #FFA500 ${percent}%, #777777 ${percent}%, #777777 100%`);
      }else{
        this._renderer.setElementStyle(element, 'background', `linear-gradient(to right, #FFA500 0%, #FFA500 ${percent}%, #777777 ${percent}%, #777777 100%`);
      }
    }
    else {
      if (permissao == 0) {
        this._renderer.setElementStyle(element, 'background', `#FFA500`);
      } else {
        this._renderer.setElementStyle(element, 'background', `#FFA500`);
      }
    }
    this._renderer.setElementStyle(element, '-webkit-text-fill-color', 'transparent')
    this._renderer.setElementStyle(element, '-webkit-background-clip', 'text')

  }

}
