import { Component, OnInit, Output, EventEmitter, Input, Renderer2, ElementRef } from '@angular/core';
import { SignalService } from '../../../shared/services/signal.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil, debounceTime, map, first } from 'rxjs/operators';
import * as _ from 'lodash';
import { ThrowStmt } from '@angular/compiler';
import { NewSinaisFiltroComponent } from '../new-sinais-filtro.component';
import { AppService } from '../../../app.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})

export class FiltrosComponent implements OnInit {

  private unsubscribe$ = new Subject();

  _tipoBusca = ['Oscilograma', 'Diagrama', 'Tutorial']; // Tutorial...

  list: any = {
    brand: [],
    model: [],
    year: [],
    sett: [],
    signalType: [],
    injSystem: []
  }

  filtred: any = {
    brand: [],
    model: [],
    year: [],
    sett: [],
    signalType: [],
    injSystem: []
  }

  filtredClone: any = {
    brand: [],
    model: [],
    year: [],
    sett: [],
    signalType: [],
    injSystem: []
  }

  filtredDefault: any = {
    brand: [],
    model: [],
    year: [],
    sett: [],
    signalType: [],
    injSystem: []
  }

  tag: any = {
    user: [],
    module: ["Automotivo-carros"],
    brand: [],
    model: [],
    year: [],
    sett: [],
    signalType: [],
    injSystem: []
  }

  tagDefault: any = {
    user: [],
    module: ["Automotivo-carros"],
    brand: [],
    model: [],
    year: [],
    sett: [],
    signalType: [],
    injSystem: []
  }

  routerLink_URL = environment.url_version == '' ? `${environment.url_version}` : `/${environment.url_version}`;

  tagFilters: any = {}
  currentFilter: string;
  brandsModule: any;
  firsLoadModule: boolean = true
  enableFields: boolean = false
  loadFilters: boolean = false
  validado = false;

  filterbrand = new FormGroup({ brand: new FormControl('') });
  filtermodel = new FormGroup({ model: new FormControl('') });
  filteryear = new FormGroup({ year: new FormControl('') });
  filtersett = new FormGroup({ sett: new FormControl('') });
  filtersignalType = new FormGroup({ signalType: new FormControl('') });
  filterinjSystem = new FormGroup({ injSystem: new FormControl('') });

  filterusuario = new FormGroup({ nome: new FormControl('') });
  buscaUsuario;
  usuariosClone;
  firstClickBuscaUsuario = false;

  _PRINT = false;
  showlog = false;

  @Output("action") action: EventEmitter<any> = new EventEmitter();
  @Output("tipo_busca") tipo_busca: EventEmitter<any> = new EventEmitter();
  @Input() tab: string;
  @Input() usuarios: any;

  constructor(
    private _signalService: SignalService,
    private _router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private element: ElementRef,
    private _appService: AppService,
    private newSinaisFiltroComponent: NewSinaisFiltroComponent,
  ) { }

  async ngOnInit() {
    // console.log('Filtros')


    await this.getUrlParams();

    this.renderer.setStyle(this.element.nativeElement.offsetParent, 'overflow', 'visible');

    this.usuariosClone = this.usuarios;

    this.setInitialModule();
    this.recoverFilters();
    await this.getFilter();
    setTimeout(() => {
      this.setFiltersUrl();
    }, 450);

    // this.changeModule("Automotivo-carros");

    if (this.tab != 'Busca-usuario') {
      // console.log("filterbrand.get('brand') =>", this.filterbrand.get('brand'));
      this.filterbrand.get('brand').valueChanges.pipe(
        takeUntil(this.unsubscribe$))
        .subscribe(async value => {
          if (this.brandsModule)
            this.filtred.brand = this.brandsModule.filter(item => {
              // console.log(item);
              return item.toLowerCase().includes(value.toLowerCase())
            })
        }
        )
    }

    this.getDropBoxFilter();

    this.filterusuario.get('nome').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        // this.checkFilter('nome')
        // console.log('usuarios => ', this.usuarios);
        this.usuariosClone = this.usuarios.filter(item => {
          // console.log(item.name.toLowerCase());
          // console.log(item);
          // item.filter(_item => {})
          // console.log(item.name.toLowerCase().includes(value.toLowerCase()));
          return item.name.toLowerCase().includes(value.toLowerCase())
        })
      })



  }

  tipoBusca(busca) {
    // console.log(busca)
    if (this._PRINT) console.log(`emitir tipo de busca: .emit(${busca})`);
    this.tipo_busca.emit(busca); // emitir sinal para buscar
    this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'searchType': busca }, queryParamsHandling: 'merge' });
  }

  getDropBoxFilter() {
    // console.log('get drop box Filter');

    this.filtermodel.get('model').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        this.checkFilter('model')
        // console.log('model: ' + this.filtred);
        this.filtred.model = this.list.model.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      )

    this.filteryear.get('year').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        this.checkFilter('year')
        this.filtred.year = this.list.year.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      )

    this.filtersett.get('sett').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        this.checkFilter('sett')
        this.filtred.sett = this.list.sett.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      )

    this.filtersignalType.get('signalType').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        this.checkFilter('signalType')
        this.filtred.signalType = this.list.signalType.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      )

    this.filterinjSystem.get('injSystem').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        this.checkFilter('injSystem');
        this.filtred.injSystem = this.list.injSystem.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      )
  }

  getFilter(module?) {


    if (module === "Busca-usuario" || this.tagFilters.module === "Busca-usuario") {
      return;
    }

    this.loadFilters = true;

    let filtroDropboxService = this._appService.getFiltroDropboxService();
    if (!filtroDropboxService) return;
    this._appService.setFiltroDropboxService(false);

    if (sessionStorage.getItem('tags')) {
      let sessionModule = JSON.parse(sessionStorage.getItem('tags'))
      if (sessionModule.brand !== undefined) {
        this.tagFilters.brand = sessionModule.brand[0];
      }
      if (sessionModule.model !== undefined) {
        this.tagFilters.model = sessionModule.model[0];
      }
      if (sessionModule.year !== undefined) {
        this.tagFilters.year = sessionModule.year[0];
      }
      if (sessionModule.sett !== undefined) {
        this.tagFilters.sett = sessionModule.sett[0];
      }
      if (sessionModule.signalType !== undefined) {
        this.tagFilters.signalType = sessionModule.signalType[0];
      }
      if (sessionModule.injSystem !== undefined) {
        this.tagFilters.injSystem = sessionModule.injSystem[0];
      }
    }

    this._signalService.filtroDropbox(this.tagFilters, this.validado)
      .subscribe((res: any) => {

        if (res.code == 200) {
          this.list = res.dropbox;
          this.list.brand.sort();
          this.filtred = _.cloneDeep(this.list);
          this.loadFilters = false;
          // this.setFiltersUrl();
          if (this.firsLoadModule) {
            this.brandsModule = this.list.brand;
            this.firsLoadModule = false;
          }
        } if (res.code == 401) {
          sessionStorage.clear();
          localStorage.clear();
          this._appService.emit(undefined, undefined, undefined);
          this._router.navigate([this.routerLink_URL + 'user/login']);

        }
      })

    setTimeout(() => {
      this._appService.setFiltroDropboxService(true);
    }, 350);
  }

  setFiltersUrl() {
    let inscricao = this.route.queryParams.subscribe(
      (queryParams: any) => {
        if (queryParams['searchType'] == 'Oscilograma') {
          if (queryParams['brand'] !== '' && queryParams['brand'] !== undefined && queryParams['brand'] !== null) {
            // console.log(queryParams['brand'])
            this.filterbrand.patchValue({ brand: queryParams['brand'] }, { emitEvent: true });
            this.tag.brand = queryParams['brand'];
          }
          if (queryParams['model'] !== '' && queryParams['model'] !== undefined && queryParams['model'] !== null) {
            // console.log(queryParams['brand'])
            this.filtermodel.patchValue({ model: queryParams['model'] }, { emitEvent: true });
            this.tag.model = queryParams['model'];
          }
          if (queryParams['year'] !== '' && queryParams['year'] !== undefined && queryParams['year'] !== null) {
            // console.log(queryParams['brand'])
            this.filteryear.patchValue({ year: queryParams['year'] }, { emitEvent: true });
            this.tag.year = queryParams['year'];
          }
          if (queryParams['sett'] !== '' && queryParams['sett'] !== undefined && queryParams['sett'] !== null) {
            // console.log(queryParams['brand'])
            this.filtersett.patchValue({ sett: queryParams['sett'] }, { emitEvent: true });
            this.tag.sett = queryParams['sett'];
          }
          if (queryParams['type'] !== '' && queryParams['type'] !== undefined && queryParams['type'] !== null) {
            // console.log(queryParams['brand'])
            this.filtersignalType.patchValue({ signalType: queryParams['type'] }, { emitEvent: true });
            this.tag.signalType = queryParams['type'];
          }
          if (queryParams['injSystem'] !== '' && queryParams['injSystem'] !== undefined && queryParams['injSystem'] !== null) {
            // console.log(queryParams['brand'])
            this.filterinjSystem.patchValue({ injSystem: queryParams['injSystem'] }, { emitEvent: true });
            this.tag.injSystem = queryParams['injSystem'];
          }
          let setItem = this.buildTag(this.tag);
          sessionStorage.setItem('tags', JSON.stringify(setItem));
        } else {

        }
      }
    );
    inscricao.unsubscribe();
  }

  checkFilter(idFilter) {
    setTimeout(() => {
      if (this[`filter${idFilter}`].get(idFilter).value === '') {
        delete this.tagFilters[idFilter];
        this.getFilter();
      }
    }, 20)
  }

  backFilter(idFilter) {
    let string = this[`filter${idFilter}`].get(idFilter).value;

    if (typeof string === 'object')
      string = string[0];


    if (string && string !== '' && this.containsInList(this.list[idFilter], string) === false) {
      if (sessionStorage.getItem('tags')) {
        let sessionModule = JSON.parse(sessionStorage.getItem('tags'));
        if (sessionModule[idFilter] && sessionModule[idFilter][0]) {
          this[`filter${idFilter}`].patchValue({ [idFilter]: sessionModule[idFilter][0] })
        }
      }
    }
  }

  recoverFilters() {
    if (sessionStorage.getItem('tags')) {
      let sessionModule = JSON.parse(sessionStorage.getItem('tags'))

      this.filterbrand.patchValue({ brand: sessionModule.brand })
      this.filtermodel.patchValue({ model: sessionModule.model })
      this.filteryear.patchValue({ year: sessionModule.year })
      this.filtersett.patchValue({ sett: sessionModule.sett })
      this.filtersignalType.patchValue({ signalType: sessionModule.signalType })
      this.filterinjSystem.patchValue({ injSystem: sessionModule.injSystem })
    }
  }

  setInitialModule() {
    // Restaura valor default devido a function changeModule
    this.tag = _.cloneDeep(this.tagDefault)

    if (sessionStorage.getItem('tags')) {
      let sessionModule = JSON.parse(sessionStorage.getItem('tags'))
      this.tag.module = [sessionModule.module[0]];
      // console.log(sessionModule.module[0])
      this.tagFilters['module'] = sessionModule.module[0];
      this.checkModule(this.tag.module);

      if (this._PRINT)
        console.log(`Output 'action ' chama a function 'changeDiagramaModule' recebe a tag => {{ ${event} }}`);
      // console.log("tagFilters => ", this.tagFilters);
      // console.log("sessionModule => ", sessionModule);
      // console.log("tag.module => ", this.tag.module);
      // console.log("sessionModule => ",sessionModule);
      // console.log("sessionModule => ",sessionModule);
    } else {
      this.tagFilters['module'] = "Automotivo-carros"
    }
  }

  async changeModule(module) {
    this.tab = module;
    setTimeout(() => {
      this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'module': module }, queryParamsHandling: 'merge' });
    }, 100);
    if (this._PRINT)
      console.log('changeModule => => ', module);
    this.checkModule(module);
    if (module == 'Busca-usuario') {
      if (this._PRINT)
        console.log('Quando sair do filtro do diagrama para o filtro de sinais => "Busca-usuario"');
    }

    // if (module == 'Busca-usuario') {
    //   console.log('Quando sair do filtro do diagrama para o filtro de sinais => "Busca-usuario"');
    //   let idElement = 'usuario-tab';
    //   document.querySelector(`#${idElement}`).classList.add('active'); // ativa o nav-bar
    // }

    this.firsLoadModule = true;
    this.enableFields = false;
    this.tag.module = [module];
    this.filtred = this.filtredDefault;
    this.filtredClone = this.filtredDefault;
    this.list = this.filtredDefault;

    await this.resetFilter();
    sessionStorage.setItem('tags', JSON.stringify(this.buildTag(this.tag)));

    this.getFilter(module);
    this.action.emit();
    // console.log('action.emit() => ', this.action);
  }

  async changeUsuario(evento) {
    // console.log(evento);
    // this.usuarios.map((usuario) => {
    //   if(usuario.nome )
    // })
  }

  changeUrl(key, value) {
    if (this.showlog) console.log(key, value)
    switch (key) {
      case 'brand':
        setTimeout(() => {
          this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'brand': value }, queryParamsHandling: 'merge' });
        }, 100);
        break;
      case 'model':
        setTimeout(() => {
          this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'model': value }, queryParamsHandling: 'merge' });
        }, 100);
        break;
      case 'year':
        setTimeout(() => {
          this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'year': value }, queryParamsHandling: 'merge' });
        }, 100);
        break;
      case 'sett':
        setTimeout(() => {
          this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'sett': value }, queryParamsHandling: 'merge' });
        }, 100);
        break;
      case 'signalType':
        setTimeout(() => {
          this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'type': value }, queryParamsHandling: 'merge' });
        }, 100);
        break;
      case 'injSystem':
        setTimeout(() => {
          this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'injSystem': value }, queryParamsHandling: 'merge' });
        }, 100);
        break;

      default:
        break;
    }
  }

  async changeFilter(value, key) {
    this.changeUrl(key, value);
    if (key === 'busca-usuario') {
      this.buscaUsuario = value;
      this.filterusuario.patchValue({ nome: value.name })
      // console.log('formGroup => ', this.filterusuario.value);
      let setItem = this.buildTag(this.tag, this.buscaUsuario);
      // console.log('setItem => ', setItem, this.filterusuario);
      sessionStorage.setItem('tags', JSON.stringify(setItem));
      this.action.emit();
      return;
    }

    if (key !== this.currentFilter) {
      this.filtredClone = _.cloneDeep(this.list);
      this.currentFilter = key;
    }

    if (key === 'brand') {
      if (this.filterbrand.get(key).value !== '') {
        this.enableFields = true;
      }

      // this.clearForm();
      this.filterbrand.patchValue({ brand: value });
      this.setInitialModule();

      this.tag[key] = [value];
      this.tagFilters[key] = value;

      for (const [index, [key, value]] of Object.entries(Object.entries(this.tagFilters))) {
        if (key !== 'brand' && key !== 'module') {
          delete this.tagFilters[key];
        }
      }
    }

    this.tag[key] = [value];
    this.tagFilters[key] = value;
    console.log(this.tag)
    let setItem = this.buildTag(this.tag);
    // console.log('setItem => ', setItem);
    sessionStorage.setItem('tags', JSON.stringify(setItem));

    for (const [index, [key, value]] of Object.entries(Object.entries(this.tag))) {
      this.tagFilters[key] = await this.tag[key][0];
    }
    this.getFilter();
    this.updateList(this.tag);
  }


  updateList(tags) {
    for (const [index, [key, value]] of Object.entries(Object.entries(tags))) {
      if (!tags[key].length || tags[key] === 'none') {
        delete tags[key];
      }
    }

    // console.log('updateList : action.emit() : tags => ', tags);
    this.action.emit();
  }

  async resetFilter() {
    let module = this.tag.module;

    for (let [key, value] of Object.entries(this.tag)) {
      delete this.tag[key];
      this.list[key] = await [];
    }

    for (let [key, value] of Object.entries(this.list)) {
      this.list[key] = await [];
    }

    this.tag.module = module;
    sessionStorage.removeItem('tags')
    sessionStorage.setItem('tags', JSON.stringify(this.buildTag(this.tag)))
    this.filtred.brand = [];
    this.tagFilters = {};
    this.tagFilters.module = module[0];

    this.updateList(this.tag);
    this.clearForm();
    this.initialUrl();
    this.getFilter();
  }

  resetUrl() {
    let obj = {
      searchType: 'Oscilograma',
      module: this.tag.module,
      validated: this.validado,
      brand: '',
      model: '',
      year: '',
      sett: '',
      type: '',
      injSystem: '',
      order: '',
      recent: -1,
      pag: ''
    }
    this._router.navigate([`${environment.url_version}/sinais-filtro`], { queryParams: obj, queryParamsHandling: 'merge' });
  }

  initialUrl() {
    // let params = { type: 'aggregate', page: 1, sort: -1, sortType: 'date', search: 'undefined' };
    // this._router.navigate([`/osciloscopio/sinais-filtro`, params])
  }

  checkModule(module) {
    // const elems = document.querySelectorAll(".nav-link");
    // let idElement = '';

    // [].forEach.call(elems, function (el) {
    //   el.classList.remove("active");
    // });

    // switch (module[0]) {
    //   case 'Automotivo-carros':
    //     idElement = 'automotivo-tab';
    //     break;

    //   case 'Automotivo-motos':
    //     idElement = 'moto-tab';
    //     break;

    //   case 'Automotivo-caminhões':
    //     idElement = 'caminhoes-tab';
    //     break;

    //   case 'Busca-usuario':
    //     idElement = 'usuario-tab';
    //     break;

    //   default:
    //     idElement = 'automotivo-tab'
    // }
    // document.querySelector(`#${idElement}`).classList.add('active');
  }

  buildTag(tags, buscaUsuario?) {
    for (const [index, [key, value]] of Object.entries(Object.entries(tags))) {
      if (!tags[key].length || tags[key] === 'none') {
        delete tags[key];
      }
    }

    if (buscaUsuario) {
      // console.log('buidTag : buscaUsuario => ', buscaUsuario);
      Object.assign(tags, { search: buscaUsuario.email, userId: buscaUsuario.id });
      // console.log(tags);
    }

    // console.log('buildTag : tags => ', tags);

    return tags;
  }

  clearForm() {
    this.filterbrand.patchValue({ brand: '' })
    this.filtermodel.patchValue({ model: '' }, { emitEvent: false })
    this.filteryear.patchValue({ year: '' }, { emitEvent: false })
    this.filtersett.patchValue({ sett: '' }, { emitEvent: false })
    this.filtersignalType.patchValue({ signalType: '' }, { emitEvent: false })
    this.filterinjSystem.patchValue({ injSystem: '' }, { emitEvent: false })
    this.enableFields = false;
  }

  clearField(evt, field, firstClick?) {
    // evt.stopPropagation()
    // this.changeUrl(field, '');
    // console.log('firtstClick, field, tab => ', firstClick, field, this.tab);

    if (firstClick == undefined && this.tab == 'Busca-usuario') {
      this.changeModule('Busca-usuario');
    }

    if (field == 'usuario' && this.tab == 'Busca-usuario') {

      if (!firstClick) {
        this.firstClickBuscaUsuario = true;
        this[`filter${field}`].patchValue({ nome: '' });
        return;
      }

      // this.changeFilter('usuario', 'busca-usuario');

      else {
        return;
      }
    }

    if (field === 'brand' && this.tab == "Automotivo-carros") {
      for (let [key, value] of Object.entries(this.tag)) {
        delete this.tag[key];
        delete this.tagFilters[key];
      }

      sessionStorage.removeItem('tags');
      this.clearForm();
      this.setInitialModule();
      this.action.emit();
    }

    delete this.tagFilters[field];
    delete this.tag[field];
    // console.log('clearField => ', this.tagFilters, this.tag);
    this[`filter${field}`].patchValue({ [field]: '' });
    this.checkFilter(field);
  }

  containsInList(array, string) {
    let retorno = false;

    array.forEach(element => {
      if (element.toLowerCase() === string.toLowerCase()) {
        retorno = true;
        return
      }
    })

    return retorno;
  }

  filterSignal(e) {
    // console.log(e)console.log(e)
    this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'validated': e }, queryParamsHandling: 'merge' });
    this.validado = e
    this.resetFilter();
    this.newSinaisFiltroComponent.filterSignalValid(e);
    this.getFilter();
  }


  getUrlParams() {
    let inscricao = this.route.queryParams.subscribe(
      (queryParams: any) => {
        if (queryParams['searchType'] == 'Oscilograma' && queryParams['validated'] !== undefined) {
          // console.log(queryParams)
          if (queryParams['module'] !== '' && queryParams['module'] !== undefined && queryParams['module'] !== null) {
            this.changeModule(`${queryParams['module']}`);
          }
          if (queryParams['validated'] !== '' && queryParams['validated'] !== undefined && queryParams['validated'] !== null) {
            if (queryParams['validated'] == 'true') {
              this.filterSignal(true);
            } else {
              this.filterSignal(false);
            }
          }
          // if (queryParams['brand'] !== '' && queryParams['brand'] !== undefined && queryParams['brand'] !== null) {
          //   this.filterbrand.patchValue({ brand: 'aaaaaa'})
          //   console.log(queryParams['brand'])
          // }
          // this.filterbrand.patchValue({ brand: sessionModule.brand })
        } else {
          let obj = {
            searchType: 'Oscilograma',
            module: 'Automotivo-carros',
            validated: this.validado,
            brand: '',
            model: '',
            year: '',
            sett: '',
            type: '',
            injSystem: '',
            search: '',
            order: '',
            recent: -1,
            pag: ''
          }
          this._router.navigate([`${environment.url_version}/sinais-filtro`], { queryParams: obj });
        }
      }
    );
    inscricao.unsubscribe();
  }
}
