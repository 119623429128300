import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class TokenGuard implements Resolve<any> {
    constructor() { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise(resolve => {           
            let token = route.params['token'];
            const recover = state.url.split('recover')[1] ? true : false;
            setTimeout(() => {
                resolve({ token: token, recover: recover })
            }, 50);
        });



    }
}
