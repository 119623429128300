import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared/shared.service';

@Injectable()
export class PlanosService {

  constructor(private _http: HttpClient) { }

  getPlanos() {
    return this._http.get(`${environment.api_server}/api/assinantes/plano/get`);
  }

  getPermissoes() {
    return this._http.get(`${environment.api_server}/api/assinantes/plano/permissao`, { headers: SharedService.header });
  }

  validaCupomByNome(nome, planoCupomId) {
    return this._http.post(`${environment.api_server}/api/assinantes/cupom/valida/nome`, { "nome": nome, planoCupomId: planoCupomId }, { headers: SharedService.header });
  }

  validateCouponByName(nome) {
    return this._http.post(`${environment.api_server}/api/assinantes/cupom/valida/nome`, { nome: nome }, { headers: SharedService.header });
  }

  changePaymentMethod(change) {
    return this._http.post(`${environment.api_server}/api/assinantes/recorrencia/update/tipo`, change , { headers: SharedService.header });
  }

  getRecorrencia(userId) {
    return this._http.post(`${environment.api_server}/api/assinantes/recorrencia/get`, { clienteId: '5dcc055d174ca14c2c44b00f', userId: userId }, { headers: SharedService.header });
  }

  getIP() {
    return this._http.get(`https://api.ipify.org?format=jsonp&callback=?`, { headers: SharedService.header });
  }
}
