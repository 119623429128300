import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { take, tap } from 'rxjs/operators';

@Injectable()
export class LoginService {

  constructor(private _http: HttpClient) { }

  authenticationByToken(token, diagramaId) {
    return this._http.post(environment.api_server + '/api/module/user/login/diagrama/token', { diagramaId: diagramaId }, {
      headers: new HttpHeaders()
        .append('Authorization', 'Bearer ' + token)
    }).pipe(
      take(1),
    );
  }



}
