import { AppService } from './../../app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DiagramaService } from '../service/diagrama.service';

// @Injectable({ providedIn: 'root' })
@Injectable()
export class AuthDiagramaGuard implements CanActivate {

  l = false;

  constructor(
    private router: Router,
    private DiagramaService: DiagramaService,
    private http: HttpClient,
    private appService: AppService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (localStorage.getItem('fake-user')) {
        localStorage.clear();
        this.router.navigate(['diagrama/crf/login']);
      }
      const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));
      if (localStorage.getItem('admin') == 'true') {
        const res: any = await this.appService.loginByToken(token).pipe(take(1)).toPromise();
        if (res.user.crudPermission.admin == true || res.user.crudPermission.support == true) {
          try {
            this.loginAdminByTokenUser(token).subscribe((res: any) => {
              if (res.code == 200) {
                if (res.admin.crudPermission.diagram == true || res.admin.crudPermission.admin) {
                  localStorage.clear();
                  localStorage.setItem('user-admin', 'true');
                  localStorage.setItem('token', res.token);
                  resolve(true)
                } else {
                  alert(`Seu usário não tem permissão para acessar esse ambiente!`)
                  this.router.navigate(['diagrama/crf/login']);
                }
              } else {
                //Falha
                return
              }
            },
              (error) => {
                //Falha
                return
              });
          } catch (error) {
            //Falha
            return
          }
        }
      } else {
        const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));
        if (!token) {
          this.router.navigate(['diagrama/crf/login']);
          resolve(false);
        } else {
          if (!this.DiagramaService.token.value) {
            await this.authenticTokenAdmin(token).pipe(take(1)).subscribe(async (res: any) => {
              if (res.code == 200) {
                if (res.user.crudPermission.diagram == true || res.user.crudPermission.admin) {
                  localStorage.clear();
                  localStorage.setItem('user-admin', 'true');
                  localStorage.setItem('token', res.token);
                  resolve(true)
                } else {
                  alert(`Seu usário não tem permissão para acessar esse ambiente!`)
                  this.router.navigate(['diagrama/crf/login']);
                }
                if (this.l) console.log('Successful authentic admin token')
              } else {
                // this.permissionDenied(res.msg);
                this.router.navigate(['diagrama/crf/login']);
                if (this.l) console.log('Failed authentic admin token')
                resolve(false);
              }
            }, err => {
              if (err) {
                // this.permissionDenied(err);
                this.router.navigate(['diagrama/crf/login']);
                if (this.l) console.log('Failed authentic admin token')
                resolve(false);
              }
            })
          } else {
            resolve(true);
          }
        }
      }
    })
  }

  authenticTokenAdmin(token) {
    return this.http.get(`${environment.api_server}/api/module/admin/id`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + token) })
  }

  loginAdminByTokenUser(token: any) {
    return this.http.post(`${environment.api_server}/api/module/admin/login-by-user`, {}, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + token) })
  }
}