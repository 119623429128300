import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-parcelamento-futuro',
  templateUrl: './parcelamento-futuro.component.html',
  styleUrls: ['./parcelamento-futuro.component.scss']
})
export class ParcelamentoFuturoComponent implements OnInit {

  proximoParcelamento = 1;

  constructor(
    private dialog: MatDialogRef<ParcelamentoFuturoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Data_Interface) {
      dialog.beforeClose().subscribe(() => dialog.close(this.proximoParcelamento));
  }

  ngOnInit() {
    console.log(this.data)
  }

  beforeClose(){
    return this.proximoParcelamento;
  }

  confirm() {
    this.dialog.close();
  }

  changeParc(e){
    this.proximoParcelamento = e.target.value;
  }

}

interface Data_Interface {
  valorPlano: number;
  countParcelas: any;
  parcelaMin: number;
}
