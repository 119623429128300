import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';
import { Route } from '@angular/compiler/src/core';
import { take, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable()
export class UserGuard implements CanActivate, Resolve<any>, CanLoad {
  userGuard;
  constructor(
    private appService: AppService,
    private router: Router,
    private http: HttpClient
  ) { }

  // usuário não carregar módulo sem permissão
  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {

    if (decodeURI(window.location.href).split('/')[4] !== "login") {
      this.appService.sendUrlRedirect({ url: window.location.href, flag: true });
    }

    this.userGuard = this.appService.getUser().value;
    this.appService.emitirNotifcation.emit();
    return new Promise(async (resolve, reject) => {

      const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));

      if (!token) {
        this.permissionDenied();
        resolve(false);
      } else {

        await this.appService.loginFromToken(token).subscribe(async (res: any) => {
          this.userGuard = res.user;
          await this.appService.emit(res.user, token, res.user.acesskey, 'from user.guard.ts => canLoad');

          try {
            if (res.user.personalData.estrangeiro !== 'false' && res.user.personalData.estrangeiro !== false) {
              resolve(true);
            } else {
              let documentCpf = false;
              let documentCnpj = false;
              if (res.code == 200) {
                if (res.user.personalData.cpf == '' ||
                  res.user.personalData.cpf == null ||
                  res.user.personalData.cpf == undefined ||
                  res.user.personalData.cpf.length < 14) {
                  documentCpf = true;
                }
                if (res.user.personalData.cnpj == '' ||
                  res.user.personalData.cnpj == null ||
                  res.user.personalData.cnpj == undefined ||
                  res.user.personalData.cnpj.length < 18) {
                  documentCnpj = true;
                }
                if (documentCpf && documentCnpj) {
                  res.user.changeDocument = true;
                  this.router.navigate([`trocar-documento`]);
                  console.log('===> Usuário tem que registrar DADOS');
                }
                resolve(true);
              }
            }
          } catch (error) {

          }
        }, (err) => {
          // console.log(err)
          this.permissionDenied(err);
          resolve(false);
        });

      }

    });
  }

  // return true se usuário pode acessar a rota // return false se usuário não pode acessar a rota
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (decodeURI(window.location.href).split('/')[4] !== "login") {
      this.appService.sendUrlRedirect({ url: window.location.href, flag: true });
    }

    this.appService.emitirNotifcation.emit();

    const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));

    return new Promise(async (resolve, reject) => {
      if (token) {

        let user = this.appService.getUser().value;
        if (!user) {

          await this.appService.loginFromToken(token).subscribe(async (res: any) => {

            if (res.code == 200) {
              this.userGuard = res.user;
              await this.appService.emit(res.user, token, res.user.acesskey, 'from user.guard.ts => canActivate');
              resolve(true);
            }
            else {
              this.permissionDenied();
              resolve(false);
            }

          }, (err) => {
            if (err) {
              this.permissionDenied(err);
              resolve(false);
            }
            this.permissionDenied(err);
            resolve(false);
          })

        }

        resolve(true);

      }
      else {

        await this.appService.emit(null, null, null);
        this.clearStorage();
        this.permissionDenied();
        resolve(false);
      }

    });
  }

  // carregar dados antes do component ser renderizado
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    console.log('Fazendo login usuário...')
    if (decodeURI(window.location.href).split('/')[4] !== "login") {
      this.appService.sendUrlRedirect({ url: window.location.href, flag: true });
    }

    this.appService.emitirNotifcation.emit();
    const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));
    return new Promise(async (resolve, reject) => {

      try {

        await this.appService.user.pipe(take(1)).subscribe(async (user: any) => {

          if (!user && !this.userGuard) {

            if (!token) {

              this.permissionDenied();
              this.appService.emit(undefined, undefined, undefined);
              this.clearStorage();
              resolve(true);

            }

            await this.loginByToken(token);

          }

          if (!user) {
            if (!this.userGuard) {
              return;
            } else {
              user = this.userGuard;
            }
          }

          let userHeader = {
            type: 'user',
            id: user._id,
            _id: user._id,
            adm: false,
            email: user.auth.email,
            photo: user.personalData.photo,
            nickname: user.personalData.nickname,
            name: user.personalData.name,
            qtdSignal: user.qtdSignal,
            evaluation: user.evaluation,
            qtdVotesSignals: user.qtdVotesSignals,
            signalAverage: user.signalAverage
          }

          this.appService.type.next('user');

          resolve({ userHeader, userDetail: user });

        });
      } catch (error) {
        resolve(false);
      }
    })
  }

  async loginByToken(token) {

    return new Promise(async (resolve, reject) => {

      this.appService.loginFromToken(token).subscribe(async (res: any) => {

        if (res.code == 200) {
          this.userGuard = res.user;
          await this.appService.emit(res.user, token, res.user.acesskey, 'from user.guard.ts => canActivate');
          resolve(true);
        }
        else {
          this.permissionDenied();
          resolve(false);
        }

      }, (err) => {
        if (err) {
          this.permissionDenied(err);
          resolve(false);
        }
        this.permissionDenied(err);
        resolve(false);
      })


    });

  }

  permissionDenied(error?) {
    try { console.log(error.msg) } catch (error) { }
    this.clearStorage();
    this.router.navigate([`${environment.url_version}/user/login`]);
  }

  clearStorage() {
    sessionStorage.clear();
    localStorage.clear();
    this.appService.emitShowNavBar(this.appService.getNavBarSemLogin());
    this.appService.emit(undefined, undefined, undefined);
  }

  setToken(token) {
    if (sessionStorage.getItem('token')) {
      sessionStorage.setItem('token', token)
    } else {
      localStorage.setItem('token', token);
    }
  }

  toUrlDirection() {
    this.appService.emitirNotifcation.subscribe(() => {
    });
  }

}
