// token.component.ts
import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ErrorState } from '../../forms/error-state';
import { Controls } from '../../forms/controls';
import { FormControl, Validators } from '@angular/forms';
import { TokenService } from './token.service';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit, AfterViewInit {
  matcher = new ErrorState();
  controls = new Controls();
  formEmailControl: FormControl;
  debounceTimeout: any = null;
  isValidEmail: boolean = false; // Indicator for valid email
  isSearching: boolean = false; // Indicator for loading state
  searchEmail: string = '';

  constructor(private dialog: MatDialogRef<TokenComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private tokenService: TokenService) {
    // Inicializa o FormControl sem os validadores assíncronos
    this.formEmailControl = new FormControl('', [Validators.required, Validators.email]);
  }

  ngOnInit() {
    // Preenche o valor inicial do e-mail se estiver armazenado
    if (!this.tokenService.emailStore) {
      this.tokenService.emailStore = '';
    }
    this.formEmailControl.patchValue(this.tokenService.emailStore);
    this.controls.setService(this.tokenService);
  }

  ngAfterViewInit() {
    // Placeholder para qualquer lógica pós-visualização
  }

  /**
   * Aciona a validação do e-mail com debounce após mudanças na entrada do e-mail
   * @param email - Valor atualizado do campo de e-mail
   */
  onEmailInputChange(email: string) {
    this.searchEmail = email;
    this.isSearching = email.length >= 1;

    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      this.validateEmail(email);
    }, 1000); // Debounce de 1000ms
  }

  /**
   * Valida o e-mail chamando a API de verificação
   * @param email - E-mail a ser verificado
   */
  validateEmail(email: string) {
    if (!this.formEmailControl.valid) {
      this.isSearching = false;
      return;
    }

    this.isSearching = true;
    this.tokenService.checkEmail(email).pipe(take(1)).subscribe((res: any) => {
      this.isValidEmail = res.code === 200;
      this.isSearching = false;
    }, () => {
      this.isValidEmail = false;
      this.isSearching = false;
    });
  }

  /**
   * Fecha o diálogo sem retornar dados
   */
  cancel() {
    this.dialog.close();
  }

  /**
   * Fecha o diálogo retornando o valor do e-mail
   */
  confirm() {
    this.dialog.close(this.formEmailControl.value); // Return with callback
  }
}
