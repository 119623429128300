import { AppService } from './../../../app.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login-to-continue',
  templateUrl: './login-to-continue.component.html',
  styleUrls: ['./login-to-continue.component.scss']
})
export class LoginToContinueComponent implements OnInit {

  service = "Desktop"

  constructor(
    private dialog: MatDialogRef<LoginToContinueComponent>,
    private router: Router,
    private appService: AppService,
  ) { }

  ngOnInit() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    )
      this.service = "Mobile";
    else this.service = "Desktop";
  }

  cancel() {
    this.dialog.close();
  }

  login(){
    this.router.navigate([`${environment.url_version}/user/login`]);
    this.dialog.close();
  }

  register(){
    this.router.navigate([`${environment.url_version}/user/register`]);
    this.dialog.close();
  }

}
