import { SiteDeTestesViewComponent } from './site-de-testes-view/site-de-testes-view.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', component: SiteDeTestesViewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SiteDeTestesRoutingModule { }
