import { environment } from './../../../environments/environment';
import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginUserByAdminGuard implements CanActivate {

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      return new Promise(async (resolve, reject) => {
        let user = this.appService.getUser().value;
        if (user) {
          if (user.crudPermission.admin == true || user.crudPermission.support == true) {
            resolve(true);
          } else {
            resolve(false);
            this.router.navigate([`${environment.url_version}/filtros`]);
          }
        } else {
          resolve(false);
          this.router.navigate([`${environment.url_version}/filtros`]);
        }
      });

  }
  
}
