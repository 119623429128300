import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TokenService {

  emailStore;
  
  constructor(private _http: HttpClient) { }
  checkEmail(email) {
    return this._http.post(`${environment.api_server}/api/module/user/email/`, { email: email })
  }

  storageEmail(email: any) {
    this.emailStore = email;
  }

}
