import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { take, tap } from 'rxjs/operators';

@Injectable()
export class SharedService {

  constructor(private http: HttpClient) { }

  public static get header() {
    const h = new HttpHeaders()
      .append('Authorization', 'Bearer ' +  (sessionStorage.getItem('token') || localStorage.getItem('token')));
    return h;
  }

  public static headerWithToken(token) {
    const h = new HttpHeaders()
      .append('Authorization', 'Bearer ' +  token);
    return h;

  }

  getUserInfo() {
    return this.http.get(`${environment.api_server}/api/module/user/id` , { headers: SharedService.header });
  }

  public validSerial(serial) {

    return this.http.post(`${environment.api_server}/api/module/user/validate/serial`, {
          "serial": [
              serial
          ]
          } , { headers: SharedService.header });
  }

}
