import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptoService {

    __encryptSecret = environment.encrypt_secret;

    constructor() { }

    /**
     * Criptografa o corpo da requisição.
     * @param body - Corpo da requisição a ser criptografado.
     * @param authorization - Cabeçalho de autorização (opcional).
     * @returns Objeto contendo o corpo criptografado.
     */
    cripyBody(body: any, authorization?: string): any {
        let secret: string;
        if (authorization) {
            secret = `secret: 77 + ${this.__encryptSecret + '#5%521$' + authorization}`;
        } else {
            secret = `secret: 00 + ${this.__encryptSecret + '7!@#44%' + this.__encryptSecret}`;
        }
        const data = CryptoJS.AES.encrypt(JSON.stringify(body), secret.trim()).toString();
        return { data };
    }

    /**
     * Descriptografa a resposta da requisição.
     * @param response - Resposta criptografada da requisição.
     * @param authorization - Cabeçalho de autorização (opcional).
     * @returns Resposta descriptografada.
     */
    decryptResponse(response: any, authorization?: string): any {
        const data = response.data;
        let secret: string;
        if (authorization) {
            secret = `secret: 77 + ${this.__encryptSecret + '#5%521$' + authorization}`;
        } else {
            secret = `secret: 00 + ${this.__encryptSecret + '7!@#44%' + this.__encryptSecret}`;
        }
        const bytes = CryptoJS.AES.decrypt(data, secret.trim());
        const decrypt = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypt);
    }

    /**
     * Descriptografa dados utilizando autorização.
     * @param data - Dados a serem descriptografados.
     * @param authorization - Cabeçalho de autorização.
     * @returns Dados descriptografados.
     */
    decryptData(data: string, authorization: string): any {
        const secret = `secret: 77 + ${this.__encryptSecret + '#5%521$' + authorization}`;
        const bytes = CryptoJS.AES.decrypt(data, secret.trim());
        const decrypt = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypt);
    }
}
