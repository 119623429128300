import environmentConfig from '../../ambiente.json';

// Versão da aplicação
const version = 'v3.5.0';

// Configuração dos ambientes
const environment_config = {
  'local': {
    api_server: "https://localhost",
    encrypt_api: false,
    production: false,
    message: `Bem-vindo ao localhost. Apontando para: https://localhost.`
  },
  'homol': {
    api_server: `https://tecnoscopio.com.br`,
    encrypt_api: true,
    production: false,
    message: `Ambiente: Homologação. Apontando para: https://tecnoscopio.com.br.`
  },
  'prod': {
    api_server: `https://newtecnoscopio.com.br`,
    encrypt_api: true,
    production: true,
    message: `Bem-vindo à plataforma oficial: www.newtecnoscopio.com.br.`
  },
};

const currentEnv = environmentConfig.env;
const config = environment_config[currentEnv] || environment_config['prod'];

console.log('env.ts');
console.log(version);
console.log(config.message);

export const environment = {
  filtroV3: true,
  platform_id: '64a5aa18e55b85f0a8ef739c',
  max_size_bytes_photo_user: 100000,
  disable_cache: true,
  encrypt_api: config.encrypt_api,
  country: undefined,
  duplicated_diagram: true,
  encrypt_secret: "d7lr!@m2ifurmb&s-_ep76@(2lbdb&*@nc7$tlqrv!8tua1xwa",
  production: config.production,
  api_server: config.api_server,
  url_version: "osciloscopio",
  bank_id: "5dcc055c174ca14c2c44b00e", // cielo
  client_id: "5dcc055d174ca14c2c44b00f", // dma electronics
  api_key_youtube: 'AIzaSyAVcOpwjGFFD1JepbDHHxNQdE1LxU8OGak', // gerado por dma.ind.br@gmail.com
  diagram_version: '1.0.0',
  logout: {
    code: 401,
    msg: 'Usuário não identificável!',
    error: 'UNAUTHORIZED'
  },
  user_fake: {
    email: 'visitante',
    password: "visitante"
  },
  first_screen: [
    {
      flag: false,
      value: 'filtros?tipoBusca=Oscilograma'
    },
    {
      flag: true,
      value: 'filtros?tipoBusca=Diagrama'
    },
    {
      flag: false,
      value: 'filtros?tipoBusca=Forum'
    },
    {
      flag: false,
      value: 'filtros?tipoBusca=Cursos'
    },
    {
      flag: false,
      value: 'planos'
    },
    {
      flag: false,
      value: 'noticias'
    }
  ]
}