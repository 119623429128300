import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SignalService } from '../../services/signal.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorState } from '../../forms/error-state';
import { takeUntil, startWith, map, take } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-vehicle-info-basic',
  templateUrl: './vehicle-info-basic.component.html',
  styleUrls: ['./vehicle-info-basic.component.scss']
})
export class VehicleInfoBasicComponent implements OnInit, AfterViewInit, OnDestroy {

  matcher = new ErrorState();
  basicFormGroup: FormGroup;

  // Basic info
  module: string;
  iconModules = 'account_circle';
  brands_list: any = [];
  filtered_brands: Array<any[]>;
  models_list: any = [];
  filtered_models: Array<any[]>;
  years_list: any = [];

  $destroyer = new Subject();

  iniciate = false;


  constructor(public dialogRef: MatDialogRef<VehicleInfoBasicComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    brands_list: Array<string>,
    models_list: Array<string>,
    years_list: Array<string>,
    module: string,
    brand: string,
    model: string,
    year: string
  }, private _service: SignalService, private _fb: FormBuilder) { }

  ngOnInit() {
    Object.keys(this.data).forEach((key: string) => {
      this[key] = this.data[key];
    })
    this.iniciateBasicGroup()
  }

  ngAfterViewInit() {
    this.iniciate = true;
  }

  ngOnDestroy() {
    this.$destroyer.next(true);
    this.$destroyer.complete()
  }

  cancel() {
    this.dialogRef.close()
  }
  save() {
    this.dialogRef.close(this.basicFormGroup.value)
  }

  async iniciateBasicGroup(s = this.data) {
    this.basicFormGroup = this._fb.group({
      brand: new FormControl({ value: s.brand, disabled: false }, Validators.required, [this.validateBrand.bind(this)]),
      model: new FormControl({ value: s.model, disabled: false }, Validators.required, [this.validateModel.bind(this)]),
      year: new FormControl({ value: s.year, disabled: false }, Validators.required, [this.validateYear.bind(this)])
    })

    this.basicFormGroup.get('brand').valueChanges.pipe(takeUntil(this.$destroyer), startWith(''), map((input) => input ? this.filterBrand(input) : this.brands_list.slice())).subscribe(async (res: any) => {
      this.filtered_brands = await res;
      if (this.basicFormGroup.get('brand').valid) {
        let mod = this.module;
        let brand = this.basicFormGroup.get('brand').value;
        this.models_list = await this._service.getVehiclesInfo(`${mod}/${brand}`).pipe(take(1), map((v: any) => v.list)).toPromise();
        this.basicFormGroup.get('model').setValue(this.models_list[0]);
      }
    })

    this.basicFormGroup.get('model').valueChanges.pipe(takeUntil(this.$destroyer), startWith(''), map((input) => input ? this.filterBrand(input) : this.models_list.slice())).subscribe(async (res: any) => {
      this.filtered_models = await res;
      if (this.basicFormGroup.get('model').valid) {
        let mod = this.module;
        let brand = this.basicFormGroup.get('brand').value;
        let model = this.basicFormGroup.get('model').value;
        await this._service.getVehiclesInfo(`${mod}/${brand}/${model}`).pipe(take(1), map((v: any) => v.list)).subscribe(async (res: any) => {
          await (this.years_list = res);
          if (this.years_list) {
            this.basicFormGroup.get('year').setValue(this.years_list[0])

          }
        });

      }
    })
  }



  async filterBrand(input: string) {
    let arr = this.brands_list.filter((v: string) => v.toLowerCase().indexOf(input.toLowerCase()) == 0);
    return arr
  }

  async filterModel(input: string) {
    let arr = this.models_list.filter((v: string) => v.toLowerCase().indexOf(input.toLowerCase()) == 0);
    return arr
  }

  validateBrand(c: FormControl) {
    return new Promise((resolve) => {
      if (!c.value || this.brands_list.filter((v: string) => v == c.value)[0]) {
        return resolve()
      }
      resolve({ brand: true });
    })
  }


  validateModel(c: FormControl) {
    return new Promise((resolve) => {
      if (!c.value || this.models_list.filter((v: string) => v == c.value)[0]) {
        return resolve()
      }
      resolve({ model: true });
    })
  }

  validateYear(c: FormControl) {
    return new Promise((resolve) => {
      if (!c.value || this.years_list.filter((v: string) => v == c.value)[0]) {
        return resolve()
      }
      resolve({ year: true })
    })
  }

  

}
