import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideEmail'
})
export class HideEmailPipe implements PipeTransform {

  transform(email: string): any {
    let parts = email.split('@');
    let value = parts[0].substr(0, 3) + '*****' + '@' + parts[1];    
    return value;
  }

}
