import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AlertService } from '../alert.service';
import { SucessCreateAccountComponent } from '../sucess-create-account/sucess-create-account.component';

@Component({
  selector: 'app-view-foto',
  templateUrl: './view-foto.component.html',
  styleUrls: ['./view-foto.component.scss']
})
export class ViewFotoComponent implements OnInit {
  
  fotoId: any = null;
  foto: any = null;

  filtroView: boolean = true;
  loading: boolean = true;

  constructor(
    private alertService: AlertService,
    private _dialog: MatDialog,
    private dialog: MatDialogRef<ViewFotoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Data_Interface
  ) { }

  ngOnInit() {
    this.fotoId = this.data.fotoId
    this.filtroView = this.data.filtroView
    if (this.filtroView) {
      this.viewFoto(this.fotoId);
    }
  }

  cancel() {
    this.dialog.close(false);
  }

  async viewFoto(id){
    this.loading = true;
    
    try {
      return await this.alertService.downloadFoto(id).subscribe(async (res: any) => {
          //Sucesso
          console.log('REQUEST')
          let binary = '';
          let bytes = new Uint8Array(res);
          let len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          this.foto = 'data:image/jpg;base64,' + window.btoa(binary);
          console.log(this.foto)
          this.loading = false;
          return
      },
        (error) => {
          //Falha
          this.alertMenssage('Falha!', '', error.error.msg, '', 'Tudo bem', '#ff9966');
          this.loading = false;
          return
        });
    } catch (error) {
      //Falha
      this.alertMenssage('Falha!', '', 'Não foi possível encontrar a!', '', 'Tudo bem', '#ff9966');
      this.loading = false;
      return
    }
  }

  alertMenssage(title: any, icon: any, message: any, buttonConf: any, buttonDecl: any, background: any) {
    this._dialog.open(SucessCreateAccountComponent, {
      data: {
        title: title,
        icon_title: icon,
        message: message,
        buttonConfirm: buttonConf,
        buttonDecline: buttonDecl,
        icon_button: '&#xE5C8;',
        background: background,
        timer: 0
      },
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
      width: '30em'
    })
  }

}

interface Data_Interface {
  fotoId: string,
  filtroView: boolean
}


