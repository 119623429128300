import { Directive, ElementRef, Renderer, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appResizeUntilParent]'
})
export class ResizeUntilParentDirective {
  @Input() change: string;

  constructor(private _element: ElementRef, private _renderer: Renderer) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.change.firstChange) {
      return;
    }

    let parent: HTMLElement = this._element.nativeElement.parentElement;
    let parentBound = parent.getBoundingClientRect();
    this._renderer.setElementStyle(this._element.nativeElement, 'width', `${parentBound.width}px`);
    let proporcion = parentBound.width / 16;
    this._renderer.setElementStyle(this._element.nativeElement, 'height', `${proporcion * 9}px`);
  }

  ngAfterViewInit() {
    let parent: HTMLElement = this._element.nativeElement.parentElement;
    let parentBound = parent.getBoundingClientRect();
    this._renderer.setElementStyle(this._element.nativeElement, 'width', `${parentBound.width}px`);
    let proporcion = parentBound.width / 16;
    this._renderer.setElementStyle(this._element.nativeElement, 'height', `${proporcion * 9}px`);
  }

}
