import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-new-cropper',
  templateUrl: './new-cropper.component.html',
  styleUrls: ['./new-cropper.component.scss']
})
export class NewCropperComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  newData: any;

  constructor(
    private dialog: MatDialogRef<NewCropperComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Data_Interface
  ) { }

  ngOnInit() {
    this.newData = this.data;
    this.fileChangeEvent(this.newData);
  }

  ngAfterViewInit() {

  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cancel() {
    this.dialog.close();
  }

  cortar(){
    this.dialog.close(this.croppedImage);
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

}

interface Data_Interface {
  img: string;
}
