import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NoticeComponent } from '../notice/notice.component';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(private dialog: MatDialogRef<NoticeComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {     
    if (this.data.timer > 0) {
      setTimeout(() => {
        this.dialog.close(false);
      }, this.data.timer * 1000);
    }
  }

}
