import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../../app.service';
import { DiagramaFiltroService } from './diagrama-filtro.service';

@Component({
  selector: 'app-diagrama-filtro',
  templateUrl: './diagrama-filtro.component.html',
  styleUrls: ['./diagrama-filtro.component.scss']
})

export class DiagramaFiltroComponent implements OnInit, OnDestroy {

  @Output("get_diagramas") get_diagramas: EventEmitter<any> = new EventEmitter();
  @Output("tipo_busca") tipo_busca: EventEmitter<any> = new EventEmitter();
  @Output("size") size: EventEmitter<number> = new EventEmitter();

  loading = false;
  modulo = 'Automotivo-carros';
  dropbox = null;
  diagramas = [];
  admin = false;
  assinantes = [];
  subsNovaBusca;

  formFiltroDiagrama = new FormGroup({
    marca: new FormControl(null),
    modelo: new FormControl(null),
    ano: new FormControl(null),
    motor: new FormControl(null),
    tipo_diagrama: new FormControl(null),
    sistema_injecao: new FormControl(null),
    categoria: new FormControl(null),
    tipo: new FormControl(null),
  });

  constructor(
    private diagramaService: DiagramaFiltroService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private element: ElementRef,
    private _appService: AppService,
    private _router: Router,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    this.resetFilter();
    this.getUrlParams();
    // this._router.navigate([environment.url_version+'/sinais-filtro'], {queryParams: {'searchType': 'Oscilograma'}});
    //#region serviço criado para somente alguns ter acesso admin

    if (this.assinantes.length == 0) {

      this._appService.getEmailUsers().subscribe((valor: any) => {
        this.assinantes = valor.assinaturas; /* luizotavioautomacao@gmail.com  */
        this._appService.setAssinantes(this.assinantes); /* luizotavioautomacao@gmail.com */

        this.assinantes.forEach((_email) => {
          let user: any = this._appService.getUser().value;
          if (user.auth.email == _email) {
            // sessionStorage.setItem('user', JSON.stringify(user));
            this.admin = true;
          }
        });
      });
    }

    //#endregion
    this.changeFilter();
    // this.assinantes = this._appService.getAssinantes(); /* luizotavioautomacao@gmail.com  */
    this.subsNovaBusca = DiagramaFiltroService.novaBusca.subscribe((res) => {
      this.changeFilter();
    });
  }

  ngOnDestroy() {
    this.subsNovaBusca.unsubscribe();
  }

  changeModule(module) {
    setTimeout(() => {
      this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'modulo': module }, queryParamsHandling: 'merge' });
    }, 100);
    this.modulo = module;
    this.resetFilter();
  }

  changeFilter() {
    this.changeUrl();
    this.loading = true;
    this.diagramaService.filtroDiagramaDropbox(this.objectFilter()).subscribe((sucess: any) => {
      if (sucess.code == 200) {
        this.loading = false;
        this.dropbox = sucess.dropbox;
        this.diagramas = sucess.diagramas;
        this.get_diagramas.emit({ diagramas: this.diagramas, size: sucess.size });
        this.diagramaService.terminarNovaBusca();
      } else {
        this.loading = false;
      }
    },
      (error) => {
        this.loading = false;
      });
  }

  changeUrl() {
    const tags = this.objectFilter();
    if (tags.marca !== null) {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'marca': tags.marca }, queryParamsHandling: 'merge' });
      }, 100);
    } else {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'marca': '' }, queryParamsHandling: 'merge' });
      }, 100);
    }
    if (tags.modelo !== null) {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'modelo': tags.modelo }, queryParamsHandling: 'merge' });
      }, 100);
    } else {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'modelo': '' }, queryParamsHandling: 'merge' });
      }, 100);
    }
    if (tags.ano !== null) {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'ano': tags.ano }, queryParamsHandling: 'merge' });
      }, 100);
    } else {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'ano': '' }, queryParamsHandling: 'merge' });
      }, 100);
    }
    if (tags.motor !== null) {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'motor': tags.motor }, queryParamsHandling: 'merge' });
      }, 100);
    } else {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'motor': '' }, queryParamsHandling: 'merge' });
      }, 100);
    }
    if (tags.sistema_injecao !== null) {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'sistema_injecao': tags.sistema_injecao }, queryParamsHandling: 'merge' });
      }, 100);
    } else {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'sistema_injecao': '' }, queryParamsHandling: 'merge' });
      }, 100);
    }
    if (tags.tipo_diagrama !== null) {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'tipo_diagrama': tags.tipo_diagrama }, queryParamsHandling: 'merge' });
      }, 100);
    } else {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'tipo_diagrama': '' }, queryParamsHandling: 'merge' });
      }, 100);
    }
    if (tags.categoria !== null) {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'categoria': tags.categoria }, queryParamsHandling: 'merge' });
      }, 100);
    } else {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'categoria': '' }, queryParamsHandling: 'merge' });
      }, 100);
    }
    if (tags.tipo !== null) {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'tipo': tags.tipo }, queryParamsHandling: 'merge' });
      }, 100);
    } else {
      setTimeout(() => {
        this._router.navigate([environment.url_version + '/sinais-filtro'], { queryParams: { 'tipo': '' }, queryParamsHandling: 'merge' });
      }, 100);
    }
  }

  tipoBusca(busca) {
    this.tipo_busca.emit(busca);
  }

  objectFilter() {
    const tags = {
      modulo: this.modulo,
      marca: this.formFiltroDiagrama.get('marca').value,
      modelo: this.formFiltroDiagrama.get('modelo').value,
      ano: this.formFiltroDiagrama.get('ano').value,
      motor: this.formFiltroDiagrama.get('motor').value,
      sistema_injecao: this.formFiltroDiagrama.get('sistema_injecao').value,
      tipo_diagrama: this.formFiltroDiagrama.get('tipo_diagrama').value,
      categoria: this.formFiltroDiagrama.get('categoria').value,
      tipo: this.formFiltroDiagrama.get('tipo').value
    }
    return tags
  }

  clearField(control) {
    // this.clearUrlField(control);
    this.diagramaService.iniciarNovaBusca();
    this.formFiltroDiagrama.patchValue({ [`${control}`]: null });
    this.changeFilter();
  }

  emitNovaBusca() {
    this.diagramaService.iniciarNovaBusca();
  }

  changeInput() {
    const emit = {
      page: 1,
    }
    this.diagramaService.emitirNovaBusca(emit);
  }

  resetFilter() {
    this.formFiltroDiagrama.patchValue({
      marca: null,
      modelo: null,
      ano: null,
      motor: null,
      tipo_diagrama: null,
      sistema_injecao: null,
      categoria: null,
      tipo: null,
    });
    const emit = {
      page: 1,
    }
    this.diagramaService.emitirNovaBusca(emit);
    // this.changeFilter();
  }

  diagramaRouter() {
    this._router.navigate([`diagrama/crf`]);
  }

  copyEmail() {
    let textString = new XMLSerializer().serializeToString(document.getElementById('email_id'));
    let copyText = textString.split('>')[1].split('<')[0]
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getUrlParams() {
    let inscricao = this.route.queryParams.subscribe(
      (queryParams: any) => {
        if (queryParams['searchType'] == 'Diagrama' && queryParams['validated'] == undefined) {
          console.log('Formato diagrama')
          this.patchValueUrl(queryParams);
        } else {
          let obj = {
            searchType: 'Diagrama',
            modulo: "Automotivo-carros",
            marca: '',
            modelo: '',
            ano: '',
            motor: '',
            tipo_diagrama: '',
            sistema_injecao: '',
            categoria: '',
            tipo: '',
            page: 1
          }
          this._router.navigate([`${environment.url_version}/sinais-filtro`], { queryParams: obj });
        }
      }
    );
    inscricao.unsubscribe();
  }

  patchValueUrl(queryParams) {
    console.log(queryParams)
    if (queryParams['modulo'] !== '' && queryParams['modulo'] !== undefined && queryParams['modulo'] !== null) {
      this.modulo = queryParams['modulo'];
    }
    if (queryParams['marca'] !== '' && queryParams['marca'] !== undefined && queryParams['marca'] !== null) {
      this.formFiltroDiagrama.patchValue({ marca: queryParams['marca'] });
    }
    if (queryParams['modelo'] !== '' && queryParams['modelo'] !== undefined && queryParams['modelo'] !== null) {
      this.formFiltroDiagrama.patchValue({ modelo: queryParams['modelo'] });
    }
    if (queryParams['ano'] !== '' && queryParams['ano'] !== undefined && queryParams['ano'] !== null) {
      this.formFiltroDiagrama.patchValue({ ano: queryParams['ano'] });
    }
    if (queryParams['motor'] !== '' && queryParams['motor'] !== undefined && queryParams['motor'] !== null) {
      this.formFiltroDiagrama.patchValue({ motor: queryParams['motor'] });
    }
    if (queryParams['sistema_injecao'] !== '' && queryParams['sistema_injecao'] !== undefined && queryParams['sistema_injecao'] !== null) {
      this.formFiltroDiagrama.patchValue({ sistema_injecao: queryParams['sistema_injecao'] });
    }
    if (queryParams['tipo_diagrama'] !== '' && queryParams['tipo_diagrama'] !== undefined && queryParams['tipo_diagrama'] !== null) {
      this.formFiltroDiagrama.patchValue({ tipo_diagrama: queryParams['tipo_diagrama'] });
    }
    if (queryParams['categoria'] !== '' && queryParams['categoria'] !== undefined && queryParams['categoria'] !== null) {
      this.formFiltroDiagrama.patchValue({ categoria: queryParams['categoria'] });
    }
    if (queryParams['tipo'] !== '' && queryParams['tipo'] !== undefined && queryParams['tipo'] !== null) {
      this.formFiltroDiagrama.patchValue({ tipo: queryParams['tipo'] });
    }
    this.diagramaService.iniciarNovaBusca();
  }

  // clearUrlField(control){
  //   switch (control) {
  //     case 'value':

  //       break;

  //     default:
  //       break;
  //   }
  // }

}