import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject ,  Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class MediaScreenService {

  public viewport: BehaviorSubject<{}> = new BehaviorSubject(null);
  private $unsub = new Subject();



  constructor(private _breakpointObserver: BreakpointObserver) {
    this.detectScreen()

  }

  detectScreen() {
    let keys = Object.keys(Breakpoints);
    this._breakpointObserver.observe(Array.from(keys, b => Breakpoints[b])).pipe(takeUntil(this.$unsub)).subscribe(result => {
      let screen = window.screen['orientation'];
      let orientation = screen.angle == 0 ? 'portrait' : 'landscape';
      let isDesktop = screen.type == 'landscape-primary' && orientation == 'portrait';
      let bounds = this.containerBounds;
      let mobileBounds = {
        width: screen.angle > 0 ? Math.max(bounds.width, bounds.height) : Math.min(bounds.width, bounds.height),
        height: screen.angle > 0 ? Math.min(bounds.width, bounds.height) : Math.max(bounds.width, bounds.height)
      }

      if (result.matches) {

        let isMobile = this._breakpointObserver.isMatched(Breakpoints.XSmall) || this._breakpointObserver.isMatched(Breakpoints.Handset);

        isDesktop ? this.viewport.next({
          device: 'desktop',
          orientation: 'none',
          angle: screen.angle,
          ios: this.ios,
          bounds: bounds

        }) :
          this.viewport.next({
            device: isMobile ? 'mobile' : 'tablet',
            orientation: orientation,
            angle: screen.angle,
            ios: this.ios,
            bounds: mobileBounds

          })

      } else {
        screen.angle > 0 || this.ios ? this.viewport.next({
          device: 'tablet',
          orientation: orientation,
          angle: screen.angle,
          ios: this.ios,
          bounds: mobileBounds

        }) :
          this.viewport.next({
            device: 'desktop',
            orientation: 'none',
            angle: screen.angle,
            ios: this.ios,
            bounds: bounds
          })

      }

    })
  }

  get containerBounds():{width: number, height: number} {
  //  let body: HTMLElement = document.getElementById('mainBody');
  //  let bound = body.getBoundingClientRect();
    return {
      width: screen.width,
      height: screen.height
    }
  }

  get ios() {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      ios = /iphone|ipod|ipad/.test(userAgent);
    return ios
  }



  destroy() {
    this.$unsub.next(true);
    this.$unsub.complete();
  }
}
