import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AlertService } from '../alert.service';
import { SucessCreateAccountComponent } from '../sucess-create-account/sucess-create-account.component';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.scss']
})
export class ViewVideoComponent implements OnInit {
  
  videoId: any = null;
  video: any = null;

  filtroView: boolean = true;
  loading: boolean = true;

  constructor(
    private alertService: AlertService,
    private _dialog: MatDialog,
    private dialog: MatDialogRef<ViewVideoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Data_Interface
  ) { }

  ngOnInit() {
    this.videoId = this.data.videoId
    this.filtroView = this.data.filtroView
  }

  cancel() {
    this.dialog.close(false);
  }

  alertMenssage(title: any, icon: any, message: any, buttonConf: any, buttonDecl: any, background: any) {
    this._dialog.open(SucessCreateAccountComponent, {
      data: {
        title: title,
        icon_title: icon,
        message: message,
        buttonConfirm: buttonConf,
        buttonDecline: buttonDecl,
        icon_button: '&#xE5C8;',
        background: background,
        timer: 0
      },
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
      width: '30em'
    })
  }

}

interface Data_Interface {
  videoId: string,
  filtroView: boolean
}

