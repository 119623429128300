import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../../app.service';
import { DiagramaFiltroService } from '../diagrama-filtro/diagrama-filtro.service';
import { NewPostComponent } from '../../new-forum/new-post/new-post.component';

@Component({
  selector: 'app-thumb-diagrama',
  templateUrl: './thumb-diagrama.component.html',
  styleUrls: ['./thumb-diagrama.component.scss']
})
export class ThumbDiagramaComponent implements OnInit {

  _LOG = false;
  _PRINT = false;
  loading = false;

  params_URL;
  full_URL;
  URL;

  cont = 0;

  @Input() diagrama: any;
  @Input() type: any;
  @Input() user: any;
  // @Input() page: any;

  constructor(
    private diagramaService: DiagramaFiltroService,
    private route: ActivatedRoute,
    private _router: Router,
    private renderer: Renderer2,
    private element: ElementRef,
    private _appService: AppService,
    private _route: Router,
    private dialog: MatDialog,
    private appService: AppService
  ) { }

 async ngOnInit() {
    this.renderer.setStyle(this.element.nativeElement.offsetParent, 'overflow', 'visible');
    if (this._LOG) console.log('Iniciando thumb diagrama... ')//, console.log(this.diagrama), console.log(this.type), console.log(this.user);
    this.params_URL = this._router.url;
    if (this._LOG) console.log('params URL => ', this.params_URL);

    this.full_URL = window.location.href;
    if (this._LOG) console.log('currentURL => ', this.full_URL);

    this.URL = this.full_URL.split(this.params_URL);
    this.URL = this.URL[0];

    if (this._LOG) console.log('URL =>', this.URL);

    this.user = await this.appService.getUser().value;
  }
  
  visualizarDiagrama(diagrama) {
    sessionStorage.setItem('url', window.location.href)
    if (this.user.permissao >= this.diagrama.permissao) {
      // localStorage.setItem('diagramaId', diagrama._id);
      const name = diagrama.nome.split(' ').join('-');
      console.log(name)
      this._router.navigate([`diagrama/user/desktop/` + diagrama._id +"/"+ name.split('/').join('')]);

      if (this._PRINT) console.log(`${this.URL}/diagrama/user`);
    }
    else {

      // localStorage.setItem('planoPretendido', this.diagrama.permissao);
      this._route.navigate([`${environment.url_version}/planos`]);

    }
  }

  newPost(diagrama) {
    this.dialog.open(NewPostComponent, { disableClose: true, data: diagrama });
  }


}
