import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'videoId'
})
export class VideoIdPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {
  }
  transform(id: any, provider?: any): SafeUrl {
    let url = '';
    if (provider == 'youtube') {
      url = 'https://www.youtube.com/embed/'+id;
    }
   // console.log(url);
    
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
