import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-demo-youtube',
  templateUrl: './new-demo-youtube.component.html',
  styleUrls: ['./new-demo-youtube.component.scss']
})
export class NewDemoYoutubeComponent implements OnInit {

  constructor(
    private el: ElementRef,
  ) { }

  public YT: any;
  public video: any;
  public player: any;
  public reframed: Boolean = false;
  loading: Boolean = false;
  flagSettings: Boolean = true;
  flagSettingsSpeed: Boolean = false;
  flagSettingsQualidy: Boolean = false;
  flagPause: Boolean = true;
  flagMute: Boolean = true;
  progressBar: any;
  timeTotal: any;
  timeProgress: any;
  volume: number = 100;
  speeds = [];
  qualitys = [];


  ngOnInit() {
    document.addEventListener('contextmenu', event => event.preventDefault());
    this.video = '6QvdRXOmNaM';
    this.initVideo();
    setInterval(() => {
      if (this.loading) {
        this.progressBar = 100 * this.player.getCurrentTime() / this.player.getDuration();
        this.timeProgress = this.convertTime(this.player.getCurrentTime());
        this.timeTotal = this.convertTime(this.player.getDuration());
        this.speeds = this.player.getAvailablePlaybackRates();
        this.qualitys = this.player.getAvailableQualityLevels();
        this.volume = this.player.getVolume();
        // console.log(this.player.getCurrentTime());
      }
    }, 1);
  }

  initVideo() {
    if (window['YT']) {
      this.startVideo();
      return;
    }
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }

  startVideo() {
    this.reframed = false;
    this.player = new window['YT'].Player('player', {
      videoId: this.video,
      playerVars: {
        autoplay: 0,
        autohide: 1,
        modestbranding: 0,
        controls: 0,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playsinline: 0
      },
      events: {
        'onReady': this.toolAction.bind(this),
        'onStateChange': function (event) {
          switch (event.data) {
            case 0:
              console.log('Vídeo Acabou')
              break;
            case -1:    // unstarted
            case 1:     // playing
              console.log('Vídeo Começou')
              break;
            case 2:
              console.log('Vídeo Pausado')
              break;
            case 3:     // buffering
            case 5:     // video cued
            default:
              break;
          }
        }
      }
    });
  }

  toolAction(action, value?) {
    console.log(this.player)
    if (typeof action == 'object') {
      this.player.playVideo();
      this.loading = true;
    }
    switch (action) {
      case 'play':
        this.flagPause = true;
        this.player.playVideo();
        break;

      case 'pause':
        this.flagPause = false;
        this.player.pauseVideo();
        break;

      case 'mute':
        this.flagMute = false;
        this.player.mute();
        break;

      case 'unmute':
        this.flagMute = true;
        this.player.unMute();
        break;

      case 'speed':
        this.flagSettingsSpeed = false;
        this.player.setPlaybackRate(value);
        break;

      case 'quality':
        this.flagSettingsQualidy = false;
        this.player.setPlaybackQualityRange(value);
        // console.log(this.player.getPlaybackQuality());
        break;

      case 'volume':
        this.player.setVolume(value);
        break;

      case 'seekTo':
        this.player.seekTo(value);
        break;

      default:
        break;
    }
  }

  convertTime(time) {
    let hours: any = time / 3600;
    let minutes: any = (hours - Math.floor(hours)) * 60;
    let seconds: any = time % 60;

    hours = Math.floor(hours);
    minutes = Math.floor(minutes);
    seconds = Math.floor(seconds);

    if ((minutes + "").length == 1) {
      minutes = "0" + minutes;
    }

    if ((seconds + "").length == 1) {
      seconds = "0" + seconds;
    }

    return minutes + ":" + seconds;
  }

  blockEvent(e) {
    e.preventDefault();
  }

  progressClick(e) {
    let details = Array.from(document.querySelectorAll('.progress-bar-parent'))
    details.forEach((div: any) => {
      var currentTime = this.player.getDuration() * ((((e.clientX - div.getBoundingClientRect().left) * (div.offsetWidth + 12.5)) / this.player.getDuration()) / 100);
      this.toolAction('seekTo', currentTime);
    });
  }

}
