import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Controls } from '../../forms/controls';
import { ErrorState } from '../../forms/error-state';

@Component({
  selector: 'app-recover-email',
  templateUrl: './recover-email.component.html',
  styleUrls: ['./recover-email.component.scss']
})
export class RecoverEmailComponent implements OnInit {

  matcher = new ErrorState();
  controls = new Controls();
  formEmail = this.controls.emailForToken;
  
  constructor(
    private dialog: MatDialogRef<RecoverEmailComponent>, 
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.dialog.close();
  }

  confirm() {
    this.dialog.close(this.formEmail.value);
  }

  cpfcnpjmask(event: any) {
    let numero = event.match(/\d/g);
    let numeroLength = 0;
    if (numero) {
      numeroLength = numero.join('').length;
    }
    if (numeroLength <= 11) {
      return [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    } else {
      return [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    }
  }

}
