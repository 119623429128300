import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { ErrorState } from '../../forms/error-state';
import { VideoService } from './video.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponentAlert implements OnInit {
  matcher = new ErrorState();
  formUrl = new FormControl('', [Validators.required], this.validVideo.bind(this));
  video_data: any;
  temp: any;
  constructor(private dialog: MatDialogRef<VideoComponentAlert>, @Inject(MAT_DIALOG_DATA) private data: any, private _service: VideoService) { }

  ngOnInit() {
    if (this.data.id) {
      this.formUrl.patchValue(this.data.video.id, { onlySelf: true });
      this.video_data = this.data.video;
    }

  }

  salvar() {
    this.dialog.close(this.temp)

  }


  validVideo(c: FormControl) {
    return new Promise((resolve, reject) => {
      this._service.videoExist(this.url).subscribe((res: any) => {
        if (res.pageInfo.totalResults < 1) {
          return resolve({ noexist: true })
        } else {
          if (this.video_data) {
            this.temp = { _id: this.video_data._id, title: res.items[0].snippet.title, id: this.video_data.id };

          } else {
            this.temp = { title: res.items[0].snippet.title, id: this.url }
          }
        }
        return resolve()
      })
    })
  }

  get url() {
    let parts = this.formUrl.value.split('watch?v=');
    let temp = parts[1];
    if (temp) {
      return temp.split("&")[0];
    }
    return parts[0]

  }

}
