import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe } from "@angular/common";

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlDatePipe implements PipeTransform {
  private createdAt: string = '';
  constructor(private _sanitizer: DomSanitizer, private datePipe: DatePipe) {
  }

  transform(v: string, created: string = '', side = 'right', marginLeft = ''): SafeHtml {
    let d = '';
    if (created != '') {
      const date = new Date(created);  
      const today = new Date();
      let text = this.transformDate(date) == this.transformDate(today) ? `hoje às: ${date.getHours()}:${date.getMinutes()} h` : `${this.transformDate(created)}`;
      this.transformDate(date) == this.transformDate(today) && date.getHours() == today.getHours() ? text = `${Math.abs(date.getMinutes() - today.getMinutes())} minutos atrás` : null;
      d = `<div style='text-align: ${side}; font-size: 10px'><i style='color: blue'; font-size: 8px>${text}</i></div>`;
    
    }
    
    return this._sanitizer.bypassSecurityTrustHtml(v + d);
  }
  transformDate(date) {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
  getDate(date: Date): string {
    return date.getDay() + '/' + date.getMonth() + '/' + date.getFullYear();
  }
}