import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EmailSuggestionService {

    private commonDomains = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com'];

    /**
     * Calcula a distância de Levenshtein entre duas strings.
     * 
     * @param a - Primeira string
     * @param b - Segunda string
     * @returns number - Distância de Levenshtein
     */
    private levenshtein(a: string, b: string): number {
        const matrix = [];

        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }

        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1] + 1,
                        Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
                    );
                }
            }
        }

        return matrix[b.length][a.length];
    }

    /**
     * Verifica se o domínio do e-mail é incorreto e sugere uma correção genérica.
     * 
     * @param email - O e-mail a ser verificado
     * @returns string | null - Retorna a sugestão de correção ou null se o domínio estiver correto
     */
    suggestDomainCorrection(email: string): string | null {
        const emailParts = email.split('@');
        if (emailParts.length !== 2) {
            return null;
        }

        const [localPart, domain] = emailParts;
        if (this.commonDomains.includes(domain) || domain.endsWith('.')) {
            return null;
        }

        let bestMatch = domain;
        let minDistance = Infinity;

        this.commonDomains.forEach(commonDomain => {
            const distance = this.levenshtein(domain, commonDomain);
            if (distance < minDistance) {
                minDistance = distance;
                bestMatch = commonDomain;
            }
        });

        return minDistance <= 3 ? `${localPart}@${bestMatch}` : null;
    }

}
