import { SharedService } from "./../../shared/shared.service";
import { take, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable()
export class CupomService {
  constructor(private _http: HttpClient) {}
  private response: any;

  criaCupom(cupom) {
    this.response = this._http.post(
      `${environment.api_server}/api/assinantes/cupom/create`,
      { cupom },
      { headers: SharedService.header }
    );

    return this.response.pipe(take(1));
  }

  getCupomAll() {
    this.response = this._http.get(
      `${environment.api_server}/api/assinantes/cupom/getAll`,
      { headers: SharedService.header }
    );

    return this.response;
  }
  getCupomById() {
    let cupom = {
      _id: "5ee7d8b9fe0aa7455a566a28",
    };

    this.response = this._http.post(
      `${environment.api_server}/api/assinantes/cupom/getById`,
      { cupom: cupom },
      { headers: SharedService.header }
    );
    return this.response;
  }
}
