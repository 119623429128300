import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy {
  @Input() banners: { image: string }[] = [];
  currentBannerIndex = 0;
  autoSlideInterval: any;

  ngOnInit() {
    this.startAutoSlide();
  }

  ngOnDestroy() {
    this.clearAutoSlide();
  }

  // Avança para o próximo banner
  nextBanner(): void {
    this.currentBannerIndex = (this.currentBannerIndex + 1) % this.banners.length;
  }

  // Volta para o banner anterior
  prevBanner(): void {
    this.currentBannerIndex =
      (this.currentBannerIndex - 1 + this.banners.length) % this.banners.length;
  }

  // Seleciona um banner pelo índice
  selectBanner(index: number): void {
    this.currentBannerIndex = index;
    this.resetAutoSlide();
  }

  // Inicia o slide automático
  startAutoSlide(): void {
    this.autoSlideInterval = setInterval(() => {
      this.nextBanner();
    }, 120000); // 2 minutos
  }

  // Limpa o slide automático
  clearAutoSlide(): void {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
  }

  // Reinicia o slide automático
  resetAutoSlide(): void {
    this.clearAutoSlide();
    this.startAutoSlide();
  }
}
