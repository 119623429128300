import { AlertService } from './../alert.service';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from '../../../app.service';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, AfterViewInit {

  desenvolvimento = false;
  acceptTerms = false;
  confirmed = false;
  user;
  ipUser;

  constructor(private dialog: MatDialogRef<TermsComponent>,
    private _appService: AppService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) private data: any) {

  }

  async ngOnInit() {
    this.desenvolvimento = !environment.production
    this.user = await this._appService.getUser().value;
    this.getInfoUser();
  }

  ngAfterViewInit() {
  }

  cancel() {
    this._appService.acceptOrRecuseTerms(false);
    this.dialog.close();
  }

  confirm() {
    this.confirmed = true;
    this.acceptTerms = false;
    this.dialog.close();
    this.sendInfoAccept();
    this._appService.acceptOrRecuseTerms(true);
    this.manipulatePDF();
  }

  async manipulatePDF() {
    // Verifica se autoRenew é true e escolhe o PDF correto
    const pdfUrl = this.data.autoRenew
      ? '../../../../assets/pdfs/termos_diagrama.pdf'
      : '../../../../assets/pdfs/termos_curso.pdf';

    const existingPdfBytes = await fetch(pdfUrl).then(response => response.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const newPdfBytes = await pdfDoc.save();
    const newPdfBlob = new Blob([newPdfBytes], { type: 'application/pdf' });
    const newPdfUrl = URL.createObjectURL(newPdfBlob);
    const a = document.createElement('a');
    a.href = newPdfUrl;
    a.download = 'termos_modificado.pdf';
    a.click();

    URL.revokeObjectURL(newPdfUrl);
  }

  sendInfoAccept() {
    const accept = {
      contratoId: '6509fbd9e4f1dadb648a7307',
      IP: this.ipUser,
      localizacao_IP: this.user.adress.city
    }
    try {
      this.alertService.sendInfoAccept(accept).subscribe((res: any) => {
        if (res.code == 200) {
          return;
        } else {
          return;
        }
      },
        (error) => {
          return;
        }
      );
    } catch (error) {
      return;
    }
  }

  getInfoUser() {
    try {
      this.alertService.getInfoUser().subscribe((res: any) => {
        if (res.code == 200) {
          this.ipUser = res.ip.substring(7);
          return;
        } else {
          return;
        }
      },
        (error) => {
          return;
        }
      );
    } catch (error) {
      return;
    }
  }
}

