import { SharedService } from "./../../shared/shared.service";
import { take, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable()
export class AssinaturaService {
  constructor(private _http: HttpClient) { }
  private response: any;

  criaPlanoByAdmin(
    nome,
    permissao,
    produtoSigeCloudId,
    titulo,
    descricao,
    vantagem1,
    vantagem2,
    vantagem3
  ) {
    this.response = this._http.post(
      `${environment.api_server}/api/assinantes/plano/create`,
      {
        nome,
        permissao,
        produtoSigeCloudId,
        titulo,
        descricao,
        vantagem1,
        vantagem2,
        vantagem3,
      },
      { headers: SharedService.header }
    );
    return this.response;
  }
  getPlanos() {
    this.response = this._http.get(`${environment.api_server}/api/assinantes/plano/get`);
    return this.response;
  }
}
