import { UserService } from './../services/user.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { TokenService } from '../alerts/token/token.service';

@Injectable()
export class LoginGuard implements Resolve<any> {
  constructor(
    private appService: AppService,
    private router: Router,
    private tokenService: TokenService,
    private userService: UserService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    this.appService.emitirNotifcation.emit();

    return new Promise(async (resolve, reject) => {

      if (localStorage.getItem('user-admin') == 'true') {
        const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));
        try {
          this.userService.loginUserByTokenAdmin(token).subscribe((res: any) => {
            if (res.code == 200) {
              localStorage.clear();
              localStorage.setItem('token', res.token);
              this.appService.emit(res.user, res.token, res.user.acesskey, 'login from login.guard.ts => canActivate');
              resolve(true);
            } else {
              //Falha
              localStorage.clear();
              resolve(true);
              return
            }
          },
            (error) => {
              //Falha
              localStorage.clear();
              resolve(true);
              return
            });
        } catch (error) {
          //Falha
          localStorage.clear();
          resolve(true);
          return
        }
      } else {
        let token = localStorage.getItem('token');
        let user = this.appService.getUser().value;

        if (!token) {
          resolve(true);

        }

        if (!user) {

          if (!token) {
            resolve(true);
            return;
          }

          await this.userService.hasLogged(token).subscribe((res: any) => {

            if (res['code'] === 200) {
              // console.log(res.user)
              localStorage.clear();
              sessionStorage.clear();
              this.tokenService.storageEmail(res.user.auth.email);
              this.appService.emit(res.user, res.token, null, 'from login component');
              this.appService.setFakeLogin(false);
              this.router.navigate([`${environment.url_version}/filtros`])
              resolve(false);
            } else {
              sessionStorage.clear();
              localStorage.clear();
              resolve(true);
            }
          }, err => {
            if (err) {
              console.log(err);
              resolve(false);
            }
          });
        }
        resolve(true);
      }
    });

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.appService.emitirNotifcation.emit();

    return new Promise((resolve) => {

      const token = localStorage.getItem('token');
      let user;
      this.appService.user.subscribe((_user) => {
        user = _user;
      })

      if (token) {
        if (localStorage.getItem('fake-user') == 'true') {
          resolve('/api/module/user/login');
          return
        }
        this.router.navigate([`${environment.url_version}`]);
      }

      let routerLink_URL = environment.url_version == '' ? `${environment.url_version}` : `/${environment.url_version}`;
      if (state.url.split('/user').length > 1) {
        resolve('/api/module/user/login');
      }
      // if (state.url.split('/v2').length > 1) {
      if (state.url.split(routerLink_URL).length > 1) {
        resolve('/api/module/user/login');
      } else {
        resolve('/api/module/admin/login')
      }

    });
  }

}
