import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluationDirective } from './evaluation.directive';
import { DirectiveService } from './directive.service';
import { QuestionRelevanceDirective } from './question-relevance.directive';
import { ResizeUntilParentDirective } from './resize-until-parent.directive';
import { EvaluationV2Directive } from './evaluation-v2.directive.';
import { RangeDirective } from './range.directive';
// import { DiagramV2Service } from '../../diagram-v2/services/diagram-v2.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    EvaluationDirective,
    QuestionRelevanceDirective,
    ResizeUntilParentDirective,
    EvaluationV2Directive,
    RangeDirective
  ],
  exports: [
    EvaluationDirective,
    QuestionRelevanceDirective,
    ResizeUntilParentDirective,
    EvaluationV2Directive,
    RangeDirective
  ],
  providers: [
    DirectiveService,
    // DiagramV2Service
  ]

})
export class DirectiveModule { }
