
import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
// import * as XLSXStyle from 'xlsx-style-correct';
import { Injectable } from '@angular/core';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable()

export class ExcelService {

  constructor() { }

  async exportAsExcelFile(json: any[], excelFileName: string) {
    // const worksheet: XLSX.WorkSheet = await XLSX.utils.json_to_sheet(json);
    // await this.myStyle(worksheet);


    // const workbook: XLSX.WorkBook = await { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    // // Use XLSXStyle instead of XLSX write function which property writes cell styles.
    // const excelBuffer: any = await XLSXStyle.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    // await this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  // private async myStyle(sheet: XLSX.WorkSheet) {

  //   const styleHeader = await {
  //     alignment: { wrapText: true, readingOrder: 2, vertical: 'center', horizontal: 'center' },
  //     font: { bold: true, sz: 11, name: "Arial" },
  //     fill: { patternType: "none" },

  //   };
  //   const styleBody = await {
  //     alignment: { wrapText: true, readingOrder: 2, vertical: 'center', horizontal: 'center' },
  //     font: { bold: false, sz: 10, name: "Arial" },
  //     fill: { patternType: "none" },
  //   };

  //   var keys = await Object.keys(sheet);

  //   for (var i = 0; i < keys.length - 1; i++) {
  //     let s = new String(keys[i]);
  //     if (s.indexOf("1") < 0) {
  //       sheet[keys[i]].s = styleBody;
  //     } else {
  //       const parts = await new String(keys[i]).split(/[^0-9]+/g)
  //       const [letter, number] = parts;
  //       if (number.length > 1) {

  //         sheet[keys[i]].s = await styleBody;
  //       } else {
  //         sheet[keys[i]].s = styleHeader;

  //       }
  //     }
  //   }
  // }

  // private setCellStyle(cell: XLSX.CellObject, style: {}) {
  //   // let range: XLSX.Range;
  //   // cell.s = style;
  // }

  private async saveAsExcelFile(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    await FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



}
