import { FormControl, Validators, AbstractControl, ValidatorFn } from "@angular/forms";
import { take } from "rxjs/operators";

export class Controls {

    private timeoutNickName = null;
    private timeoutDocument = null;
    timeout: any = null;
    private _service: any;
    public _id = "";
    public maskCel = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public maskTel = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public maskCep = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    public maskCPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    public maskCNPJ = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    public maskCelForeigner = ['+', /\d/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/];

    public setService(service) {
        this._service = service;
    }

    public setId(id) {
        this._id = id;
    }

    get email(): FormControl {
        return new FormControl('', [Validators.required, Validators.email], this.validEmail.bind(this))
    }

    get emailControl() {
        return new FormControl('', [Validators.required, Validators.email], this.validEmail.bind(this))
    }

    get emailForToken() {
        return new FormControl('', [Validators.required, Validators.email], this.invalidEmail.bind(this))
    }

    get pwdNoLength(): FormControl {
        return new FormControl('', [Validators.required]);
    }

    get pwd(): FormControl {
        return new FormControl('', [Validators.required, Validators.minLength(8)]);
    }

    get pwdTemp(): FormControl {
        return new FormControl('', [Validators.required, Validators.minLength(8), this.passwordConfirming.bind(this)]);
    }

    get born(): FormControl {
        return new FormControl(['', this.validBorn.bind(this)]);
    }
    get name(): FormControl {
        return new FormControl('', [Validators.required, Validators.minLength(8)]);
    }

    get nickname() {
        return new FormControl('', [Validators.required, Validators.minLength(8)], this.validNick.bind(this))
    }

    get cel() {
        return new FormControl('', [Validators.required, Validators.minLength(11), this.validCel.bind(this)])
    }

    get tel() {
        return new FormControl('', [Validators.required, Validators.minLength(10), this.validTel.bind(this)])
    }

    get person() {
        return new FormControl('', [Validators.required, this.minLength], this.validPerson.bind(this))
    }

    validBorn(c: FormControl) {
        if (!c.value) return;
        if (c.value.replace(/\D/g, '').length != 8) return { invalid: true }
    }

    validCel(c: FormControl) {
        if (!c.value) return;
        if (c.value.replace(/\D/g, '').length != 11) return { invalid: true }
    }

    validTel(c: FormControl) {
        // console.log('c.value :>> ', c.value);
        if (!c.value) return;
        if (c.value.replace(/\D/g, '').length != 10) return { invalid: true }
    }

    minLength(c: AbstractControl): any {
        try {
            if (!c.parent || !c) return;
            let person = c.parent.get('person').value;
            person = person.replace(/\D/g, '');
            if (!person) return;
            if (person.length < (c.parent.get('isCPF').value ? 11 : 14)) {
                return { minlength: true };
            }
            return;
        } catch (error) {

        }
    }

    passwordConfirming(c: AbstractControl): any {
        if (this._id) {
            return;
        }

        if (!c.parent || !c) return;
        const pwd = c.parent.get('passwordTemp');
        const cpwd = c.parent.get('password');

        if (pwd.value !== cpwd.value) {
            return { notEqual: true };
        }

        if (cpwd.hasError('minlength')) {
            return;
        }

        if (!pwd || !cpwd) return;

    }

    validEmail(c: FormControl) {
        return new Promise((resolve, reject) => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this._service.checkEmail(c.value, this._id).subscribe((res: any) => {
                    if (res.code == 200) {
                        return resolve({ exist: true })
                    }
                    return resolve()
                })
            }, 400);
        })
    }

    invalidEmail(c: FormControl) {
        return new Promise((resolve, reject) => {
            this._service.checkEmail(c.value, this._id).subscribe((res: any) => {
                if (res.code != 200) {
                    return resolve({ nonexistent: true })
                }
                return resolve()
            })
        })
    }

    validNick(c: FormControl) {

        clearTimeout(this.timeoutNickName);
        return new Promise((resolve, reject) => {
            this.timeoutNickName = setTimeout(() => {
                this._service.checkNickname(c.value, this._id)/*.pipe(take(1))*/.subscribe((res: any) => {
                    if (res.code == 200) {
                        resolve({ invalidNick: true })
                        return;
                    }
                    resolve()
                })
            }, 1000);
        });
        // return Observable.of({ invalidNick: false });
    }

    validPerson(c: FormControl) {
        clearTimeout(this.timeoutDocument);
        return new Promise((resolve, reject) => {

            this.timeoutDocument = setTimeout(() => {
                if (c.parent.value.isCPF != 'cnpj' && c.parent.value.isCPF != false && c.parent.value.isCPF != 'cpf' && c.parent.value.isCPF != true) {
                    console.log('implementar se já existe o documento extrangeiro digitado');
                } else {

                    const person = c.value.replace(/[^\d]/g, '')
                    switch (person.length) {
                        case 11:
                            console.log('cpf')
                            this._service.validCPF(person).then((res: any) => {
                                if (!res) {
                                    console.log('cpf1')
                                    return resolve({ invalid: true })
                                } else {
                                    this._service.checkCPFCNPJ(person.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'), this._id).pipe(take(1)).subscribe((res: any) => {
                                        if (res.code == 200) {
                                            console.log('cpf2')
                                            return resolve({ exist: true })
                                        } else {
                                            console.log('cpf3')
                                            return resolve()
                                        }
                                    })
                                }
                            })
                            break;

                        case 14:
                            console.log('cnpj', this._service)
                            this._service.validCNPJ(person).then((res: any) => {
                                console.log(res)
                                if (!res) {
                                    console.log('cnpj1')
                                    return resolve({ invalid: true })
                                } else {
                                    this._service.checkCPFCNPJ(person.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'), this._id).pipe(take(1)).subscribe((res: any) => {
                                        if (res.code == 200) {
                                            console.log('cnpj2')
                                            return resolve({ exist: true })
                                        } else {
                                            console.log('cnpj3')
                                            return resolve()
                                        }
                                    })
                                }

                            })
                            break;

                        default:
                            break;
                    }
                }

            }, 1000);

        })
    }

}
