import { Component, OnInit, Inject } from '@angular/core';
import { AppService } from '../../../app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-list-serial',
  templateUrl: './list-serial.component.html',
  styleUrls: ['./list-serial.component.scss']
})
export class ListSerialComponent implements OnInit {
  private serial: string;
  listSerial = [];
  constructor(private _appService: AppService, public dialogRef: MatDialogRef<ListSerialComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this._appService.user.pipe(take(1)).subscribe((user: any) => {
      if (user) {
        this.listSerial = user.myDevices[0].serial;
        this.serial = this.listSerial[0]    
      } 
    })
  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): any {
    this.dialogRef.close(this.serial.toUpperCase());
  }

}