import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CryptoService } from './crypto.service';

@Injectable()
export class ErrorDecryptInterceptor implements HttpInterceptor {

    constructor(private cryptoService: CryptoService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // Verifica se a resposta de erro é do tipo esperado e contém dados criptografados
                if (error.error instanceof ErrorEvent) {
                    // Cliente ou erro de rede
                    return throwError(error);
                } else if (error.error && error.error.data) {
                    // Presume-se que os dados estejam criptografados e necessitem de descriptografia
                    const authorization = 'Bearer ' + (sessionStorage.getItem('token') || localStorage.getItem('token'));
                    try {
                        const decryptedError = this.cryptoService.decryptData(error.error.data, authorization);
                        // Se houver uma mensagem de erro específica no erro descriptografado, re-emitir o erro com essa mensagem
                        return throwError({ ...error, error: { msg: decryptedError.msg, error: decryptedError.error } });
                    } catch (decryptionError) {
                        // Falha na descriptografia, re-emitir o erro original
                        return throwError(error);
                    }
                } else {
                    // Se o erro não conter 'data', verificar se já existe uma mensagem de erro para reemitir
                    if (error.error && error.error.msg) {
                        return throwError(error);
                    } else {
                        // Caso o erro não siga a estrutura esperada, re-emitir como está
                        return throwError({ ...error, error: { msg: error.error.msg, error: error.error.error } });
                    }
                }
            })
        );
    }
}
