import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/shared.service';

@Injectable()
export class NewFiltroDiagramaService {

  constructor(private http: HttpClient) { }

  static novaBusca: EventEmitter<any> = new EventEmitter();

  // getFilterDiagramsByFilter(tags) {
  //   return this.http.post(`${environment.api_server}/api/newtecnoscopio/diagrama/filtro/v3`, tags, { headers: SharedService.header });
  // }

  getFilterDiagramsByFilter(tags) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/diagrama/filtro`, tags, { headers: SharedService.header });
  }
  

}
