import { LoginService } from './../login.service';
import { TokenService } from './../../alerts/token/token.service';
import { AppService } from './../../../app.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-verifica-token-app',
  templateUrl: './verifica-token-app.component.html',
  styleUrls: ['./verifica-token-app.component.scss']
})
export class VerificaTokenAppComponent implements OnInit {

  token;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private loginService: LoginService,
    private appService: AppService,
    private tokenService: TokenService,
  ) { }

  ngOnInit() {
    this._route.data.subscribe(data => {
      this.token = data.token;
      try {
        this.loginService.checkToken(this.token).subscribe(async (res: any) => {
          if (res.code == 200) {
            //Sucesso
            this.logout();
            this.tokenService.storageEmail(res.user.auth.email);
            await this.appService.emit(res.user, this.token, res.user.acesskey, 'from login component');
            localStorage.setItem('software-new-mobile', 'true');
            this.appService.setMobileApp(true);
            this.appService.setFakeLogin(false);
            this.appService.setLoginUser(false);
            this._router.navigate([`${environment.url_version}/filtros`], {
              queryParams: {
                tipoBusca: 'Cursos',
              }
            });
          } else {
            //Falha
            this.returnErrorApp();
          }
        },
          (error) => {
            //Falha
            this.returnErrorApp();
          });
      } catch (error) {
        //Falha
        this.returnErrorApp();
      }
    })
  }


  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.appService.emit(undefined, undefined, undefined);
    this.appService.setFakeLogin(false);
  }

  returnErrorApp(){
    const message = {
      type: 'logout-angular-website',
      payload: true
    };
    window.parent.postMessage(message, '*');
  }

}
