import { AppService } from './../../../app.service';
import { environment } from './../../../../environments/environment';
import { AlertService } from './../alert.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { SucessCreateAccountComponent } from '../sucess-create-account/sucess-create-account.component';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-input-get-cupom',
  templateUrl: './input-get-cupom.component.html',
  styleUrls: ['./input-get-cupom.component.scss']
})
export class InputGetCupomComponent implements OnInit {

  user: any;
  produto: any;
  idPopup: any;
  userPlano: any;

  formResp = new FormGroup({
    cupom: new FormControl()
  });

  mobileFlagService: string = 'Desktop';

  constructor(
    private alertService: AlertService,
    private appService: AppService,
    private _dialog: MatDialog,
    private router: Router,
    public dialog: MatDialogRef<InputGetCupomComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Data_Interface
  ) { }

  ngOnInit() {
    this.user = this.data.user;
    this.idPopup = this.data.idPopup;
    this.produto = this.data.recorrencia.plano.produto;
    this.userPlano = this.data.plano;

    console.log(this.userPlano)

    this.appService.getMobile().subscribe(flag => {
      this.mobileFlagService = flag;
    });
  }

  cancel() {
    this.dialog.close(false);
  }

  confirm() {
    this.dialog.close(true);
  }

  verificarTipoCupom(cupom) {
    cupom = cupom.replace(/[^\w\s]/gi, '');

    if (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(cupom)) {
      return "CPF";
    } else if (/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(cupom)) {
      return "CNPJ";
    } else {
      return "Nome";
    }
  }

  renovarAssinatura() {
    try {
      this.alertService.renovarPlanoCupom(this.formResp.value.cupom).subscribe((res: any) => {
        if (res.code == 200) {
          //Sucesso
          this._dialog.closeAll();
          this.cancel();
          this.popUp_visualizado();
          this._dialog.open(SucessCreateAccountComponent, {
            data: {
              title: 'Sucesso',
              icon_title: '&#xE876;',
              message: 'Obrigado por assinar nosso plano!.',
              buttonConfirm: 'Tudo bem',
              buttonDecline: '',
              icon_button: '&#xE5C8;',
              background: '#47c9a2',
              timer: 6
            },
            disableClose: true
          }).afterClosed().pipe(take(1)).subscribe(async (callback) => {
            location.reload();
          })
          return
        } else {
          //Falha
          this.alertMenssage('Falha!', '', res.msg, '', 'Tudo bem', '#ff9966');
          return
        }
      },
        (error) => {
          //Falha
          this.alertMenssage('Falha!', '', error.error.msg, '', 'Tudo bem', '#ff9966');
          return
        });
    } catch (error) {
      //Falha
      this.alertMenssage('Falha!', '', 'Não foi possível validar o cupom!', '', 'Tudo bem', '#ff9966');
      return
    }
  }

  checkCupom() {
    try {
      this.alertService.validaCupomByNome(this.formResp.value.cupom/*, this.user.planoId*/).subscribe((res: any) => {
        if (res.code == 200) {
          //Sucesso
          this.renovarAssinatura();
          return
        } else {
          //Falha
          this.alertMenssage('Falha!', '', res.msg, '', 'Tudo bem', '#ff9966');
          return
        }
      }, (error) => {
        //Falha
        this.alertMenssage('Falha!', '', error.error.msg, '', 'Tudo bem', '#ff9966');
        return
      });
    } catch (error) {
      //Falha
      this.alertMenssage('Falha!', '', 'Não foi possível validar o cupom!', '', 'Tudo bem', '#ff9966');
      return
    }
  }

  alertMenssage(title: any, icon: any, message: any, buttonConf: any, buttonDecl: any, background: any) {
    this._dialog.open(SucessCreateAccountComponent, {
      data: {
        title: title,
        icon_title: icon,
        message: message,
        buttonConfirm: buttonConf,
        buttonDecline: buttonDecl,
        icon_button: '&#xE5C8;',
        background: background,
        timer: 0
      },
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
      width: '30em'
    })
  }

  popUp_visualizado() {
    // this.alertService.popUppVisualizado(this.idPopup).subscribe(async (res: any) => { });
  }

  returnLength(value) {
    if (value == null || value == '' || value == undefined) return true;
    if (value.length >= 2) {
      return false
    } else {
      return true
    }
  }

  formatNumber(number) {
    try {
      const [integerPart, decimalPart] = number.toString().split(".");
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      const formattedDecimalPart = decimalPart ? `,${decimalPart}` : "";
      const formattedNumber = `${formattedIntegerPart}${formattedDecimalPart}`;
      return formattedNumber;
    } catch (error) {

    }
  }

}

interface Data_Interface {
  user: string;
  idPopup: string;
  recorrencia: any;
  plano: any;
  // message: string;
  // buttonConfirm: string;
  // buttonDecline: string;
  // buttonConfirmColor: string;
  // buttonDeclineColor: string;
  // icon_button: string;
  // background: string;
  // timer: number;
}
