import { Directive, ElementRef, Renderer, OnInit, Input, HostListener } from '@angular/core';
import { DirectiveService } from './directive.service';

@Directive({
  selector: '[appQuestionRelevance]'
})
export class QuestionRelevanceDirective implements OnInit {
  @Input() evaluated: boolean;
  @Input() like: boolean;
  @Input() defaultSize: number = 10;
  @Input() newSize: number = 12;

  constructor(private _element: ElementRef, private _rederer: Renderer, private _service: DirectiveService) { }

  ngOnInit() {
  }
  @HostListener('change') ngOnChanges() {
    this.evaluated ?
      this.like ? this.liked() : this.disliked()
      : this.nothing();
  }

  liked() {
    this._rederer.setElementStyle(this._element.nativeElement, 'color', 'blue')
    this._rederer.setElementStyle(this._element.nativeElement, 'font-size', `${this.newSize}px`)
    this._rederer.setElementStyle(this._element.nativeElement, 'transition', 'all .3s ease-out')
  }
  disliked() {
    this._rederer.setElementStyle(this._element.nativeElement, 'color', 'red')
    this._rederer.setElementStyle(this._element.nativeElement, 'font-size', `${this.newSize}px`)
    this._rederer.setElementStyle(this._element.nativeElement, 'transition', 'all .3s ease-out')
  }
  nothing() {
    this._rederer.setElementStyle(this._element.nativeElement, 'color', 'initial')
    this._rederer.setElementStyle(this._element.nativeElement, 'font-size', `${this.defaultSize}px`)
    this._rederer.setElementStyle(this._element.nativeElement, 'transition', 'all .3s ease-out')

  }

}
