import { SharedModule } from './../../shared/shared.module';
import { NewContatoComponent } from './new-contato-home/new-contato.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewContatoRoutingModule } from './new-contato-routing.module';

@NgModule({
  imports: [
    CommonModule,
    NewContatoRoutingModule,
    SharedModule,
  ],
  declarations: [
    NewContatoComponent
  ]
})
export class NewContatoModule { }
