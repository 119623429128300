import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanLoad, Route, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements Resolve<any> {

  constructor(
    private sharedService: SharedService,
    private appService: AppService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return new Promise(async (resolve, reject) => {
        try {
          this.sharedService.getUserInfo().subscribe( async (res: any) => {
            if (res.code == 200) {
              //Sucesso
              await this.appService.setUser(res.user);
              resolve(true);
              return
            } else {
              //Falha
              resolve(true);
              return
            }
          },
            (error) => {
              //Falha
              resolve(true);
              return
            });
        } catch (error) {
          //Falha
          resolve(true);
          return
        }
      });
  }
  
}
