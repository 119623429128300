import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { take, tap, delay } from 'rxjs/operators';
import { SignalService } from '../../services/signal.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  image: String | ArrayBuffer = '';
  error = '';
  constructor(public dialogRef: MatDialogRef<PreviewComponent>, private _service: SignalService, @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {
    if (this.data.timer) {
      setTimeout(() => {
        this.dialogRef.close();
      }, this.data.timer * 1000);
    }

    try {
      console.log('try');
      this._service.getPreview(this.data.id).pipe(take(1), delay(500)).subscribe((res: any) => {
        if (res.code == 200) {
          console.log('...');
          var blob = new Blob([new Uint8Array(res.img.data)], { type: 'png' });
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          const that = this; reader.onloadend = () => {
            const temp: any = reader.result;
            if (temp) {
              that.image = temp.split('base64,')[1];
            } else {
              that.error = 'Arquivo corrompido...'
              setTimeout(() => {
                that.dialogRef.close()
              }, 4000);
            }
          }
        } else if (res.code == 202) {
          this.error = res.msg;
          setTimeout(() => {
            this.dialogRef.close()
          }, 4000);
        } else {
          this.error = 'Um erro inesperado ocorreu duarante a conversão do arquivo, tente novamente mais tarde....'
          setTimeout(() => {
            this.dialogRef.close()
          }, 4000);
        }
      })
    } catch (err) {
      this.error = 'Um erro inesperado ocorreu duarante a conversão do arquivo, tente novamente mais tarde....'
          setTimeout(() => {
            this.dialogRef.close()
          }, 4000);
     }

    
  }

  close() {
    this.dialogRef.close();
  }

}
