import { NgModule } from '@angular/core';
import { DiagramaModule } from './_nt-f4bric/diagrama.module';
import { SafePipe } from './safe.pipe';
// import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [
    SafePipe
  ],
  imports: [
  ],
  exports: [
    SafePipe
  ],
  providers: [ 
  ]
})
export class SafeModule { }
