import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from '../shared.service';
import * as io from 'socket.io-client';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment';
@Injectable()
export class SocketService {
  private io: BehaviorSubject<any> = new BehaviorSubject(null);
  public notification: BehaviorSubject<any> = new BehaviorSubject([]);
  public newAnswer: BehaviorSubject<any> = new BehaviorSubject(null);
  public scroolUntil: BehaviorSubject<any> = new BehaviorSubject(null);
  response: any;

  constructor(private _router: Router, private _http: HttpClient) {
  }

  async start() {
    return new Promise((resolve, reject) => {
      const socket = io(environment.api_server)//, { 'force new connection': false });
      if (socket) {
        this.io.next(socket);
        resolve(true);
      }
      resolve(false);
    })
  }

  async close(io) {
    io.disconnect();
  }
  get getIO() {
    return this.io;
  }
  get getNotification() {
    return this.notification;
  }

  emit(io, listener, data: any = {}) {
    // console.log('emitindo socket ...');

    data.token = this.token;
    // console.log(listener, data)
    io.emit(listener, data);
  }
  get token() {
    return (sessionStorage.getItem('token') || localStorage.getItem('token'));
  }
  getHistory(skip, limit) {
    skip < 0 ? skip = 0 : null;
    limit < 0 ? skip = 0 : null;
    return this._http.get(`${environment.api_server}/api/module/notification/history/skip/${skip}/limit/${limit}`, { headers: SharedService.header });

  }

  subscribe(socket, m = 'user') {
    this._http.get(`${environment.api_server}/api/module/${m}/notification/subscribe/${socket}`, { headers: SharedService.header }).subscribe((res) => res = res
    )
  }

  pushAnswer(answer) {
    this.newAnswer.next(answer);
  }

  getSignalNotification() {
    this.response = this._http.get(`${environment.api_server}/api/module/admin/buffer/notification/delete`, { headers: SharedService.header });

    return this.response;

  }

}

