import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AngularEditorConfig } from 'nt-forum1';
import { environment } from '../../../../environments/environment';
import { DiagramaService } from '../../../_nt-f4bric/service/diagrama.service';
import { SucessCreateAccountComponent } from '../../../shared/alerts/sucess-create-account/sucess-create-account.component';
import { SignalService } from '../../../shared/services/signal.service';
import { Forum } from '../../../shared/_fakebackend/forum-storage';
import { ForumService } from '../forum.service';
import { AppService } from '../../../app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NewFiltroService } from '../../new-filtro/new-filtro.service';
import { Subject, Subscription } from 'rxjs';
import { NewFiltroDiagramaService } from '../../new-filtro/new-filtro-diagrama/new-filtro-diagrama.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.scss']
})

export class NewPostComponent implements OnInit, OnDestroy {

  public sanitizer: DomSanitizer
  private unsubscribe$ = new Subject();


  marcaDiagrama;
  modeloDiagrama;
  anoDiagrama;
  motorDiagrama;
  tipo_diagramaDiagrama;
  sistema_injecaoDiagrama;
  categoriaDiagrama;
  tipoDiagrama;

  brandSinal;
  injSystemSinal;
  modelSinal;
  settSinal;
  signalTypeSinal;
  yearSinal;
  _categoria = [];


  signal_diagrama;
  newForum = false;
  addSignalFlag = false;
  addDiagramaFlag = false;
  loading = false;
  addVideo = false;
  addSinal = false;
  addDiagrama = false;
  sinalId = [];
  diagramaId = [];
  sinalIdInfo = [];
  diagramaIdInfo = [];
  sub: Subscription[] = [];
  tipo: string;
  idYoutube: any;
  youtubeValue: string;
  timeout: any = null;
  diagramaFormGroupValue: any = null;
  oscilogramaFormGroupValue: any = null;
  newForumFormGroup = new FormGroup({
    title: new FormControl("", [Validators.required]),
    body: new FormControl("", [Validators.required]),
    tags: new FormControl("", [Validators.required]),
  });
  user;
  module;
  category = 'Outros';
  backUpCategory = 'Outros';
  outrosCat = 'Oscilograma';
  nextStep = false;

  tags = [];

  editPost = false;
  digiteSuaDuvida = 'Digite sua dúvida:';
  newForumSignalDiagrama = false;

  modulosExb = ['Automotivo', 'Caminhões', 'Moto'];

  oscilogramaFormGroup = new FormGroup({
    brand: new FormControl(null),
    injSystem: new FormControl(null),
    model: new FormControl(null),
    sett: new FormControl(null),
    signalType: new FormControl(null),
    validado: new FormControl(false),
    year: new FormControl(null)
  });

  diagramaFormGroup = new FormGroup({
    ano: new FormControl(null),
    categoria: new FormControl(null),
    marca: new FormControl(null),
    modelo: new FormControl(null),
    motor: new FormControl(null),
    sistema_injecao: new FormControl(null),
    tipo: new FormControl(null),
    tipo_diagrama: new FormControl(null),
  });

  outrosFormGroup = new FormGroup({
    categoria: new FormControl(null)
  });

  addCategoriaFormGroup = new FormGroup({
    categoria: new FormControl(null)
  });

  categorias = [
    { cat: 'diagrama', nome: 'Diagrama' },
    { cat: 'oscilograma', nome: 'Oscilograma' },
    { cat: 'FAQ', nome: 'FAQ' },
    { cat: 'suporte', nome: 'Suporte New Tecnoscopio' },
    { cat: 'reclamacoes', nome: 'Reclamações' },
    { cat: 'sugestoes', nome: 'Sugestões' },
    { cat: 'tutorial', nome: 'Tutorial' },
    { cat: 'video', nome: 'Vídeo' },
    { cat: 'outros', nome: 'Outros' }
  ]

  backupCategorias = [
    { cat: 'diagrama', nome: 'Diagrama' },
    { cat: 'oscilograma', nome: 'Oscilograma' },
    { cat: 'FAQ', nome: 'FAQ' },
    { cat: 'suporte', nome: 'Suporte New Tecnoscopio' },
    { cat: 'reclamacoes', nome: 'Reclamações' },
    { cat: 'sugestoes', nome: 'Sugestões' },
    { cat: 'tutorial', nome: 'Tutorial' },
    { cat: 'video', nome: 'Vídeo' },
    { cat: 'outros', nome: 'Outros' }
  ]

  backStep = true;

  oscilogramaList = {
    brand: '',
    injSystem: '',
    model: '',
    sett: '',
    signalType: '',
    validado: false,
    year: ''
  }

  diagramaList = {
    ano: "",
    categoria: "",
    marca: "",
    modelo: "",
    motor: "",
    sistema_injecao: "",
    tipo: "",
    tipo_diagrama: ""
  }

  step = {
    first: true,
    second: false,
    third: false,
    category: false
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '12rem',
    minHeight: '5rem',
    placeholder: 'Digite sua dúvida...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    defaultFontSize: '4',
    toolbarHiddenButtons: [
      [,
        'subscript',
        'superscript',
        'heading',
      ],
      [
        'backgroundColor',
        'customClasses',
      ]
    ],
  };

  @Output("type") type: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public signal,
    private dialog: MatDialog,
    private forumService: ForumService,
    private _router: Router,
    private _signalService: SignalService,
    private _diagramaService: DiagramaService,
    private appService: AppService,
    private newFiltroService: NewFiltroService,
    private newFiltroDiagramaService: NewFiltroDiagramaService,
  ) {
    this.signal_diagrama = signal;
  }

  ngOnInit() {
    this.getDropBoxFilterDiagrama();
    this.getDropBoxFilterSinal();
    this.user = this.appService.getUser().value;

    if (this.signal_diagrama.module !== undefined) {
      this.newForum = true;
      this.tipo = 'sinal';
      this.step = {
        first: false,
        second: true,
        third: false,
        category: false
      }
    } else if (this.signal_diagrama == 'new') {
      this.newForum = true;
      this.tipo = 'novo';
      this.step = {
        first: true,
        second: false,
        third: false,
        category: false
      }
    } else if (this.signal_diagrama.modulo !== undefined) {
      this.tipo = 'diagrama';
      this.newForum = true;
      this.step = {
        first: false,
        second: true,
        third: false,
        category: false
      }
    } else if (this.signal_diagrama.reply !== undefined) {
      this.newForum = false;
      this.editPost = true;
      this.step = {
        first: false,
        second: true,
        third: false,
        category: false
      }
      this.preencherDados();
    }

  }

  ngOnDestroy() {
    this.sub.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  close() {
    this.dialog.closeAll();
  }

  removerTags(html) {
    const data = new DOMParser().parseFromString(html, 'text/html');
    return data.body.textContent || "";
  }

  async savePost() {
    this.loading = true;
    if (this.newForumFormGroup.get('body').hasError('required') && this.newForumFormGroup.get('body').touched
      || !this.newForumFormGroup.get('body').value) {
      return
    }
    if (this.newForumFormGroup.get('title').hasError('required') && this.newForumFormGroup.get('title').touched
      || !this.newForumFormGroup.get('title').value) {
      return
    }

    if (this.newForum == true) {
      let forum;
      let videoAdd = '';
      if (this.idYoutube) {
        videoAdd = `<br> <iframe width="492" height="277" src="https://www.youtube.com/embed/${this.idYoutube}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>`
      }
      if (this.tipo == 'sinal') {
        this.sinalId.push(this.signal_diagrama._id);
        forum = {
          diagramaId: null,
          sinalId: this.sinalId,
          videoId: this.idYoutube,
          categoria: this._categoria,
          filtro: {
            modulo: this.signal_diagrama.module,
            marca: this.signal_diagrama.brand,
            modelo: this.signal_diagrama.model,
            ano: this.signal_diagrama.year,
            motor: this.signal_diagrama.motor,
            sistema_injecao: this.signal_diagrama.injSystem,
            categoria: this.signal_diagrama.sett,
            tipo: this.signal_diagrama.signalType,
            tipo_diagrama: null,
            tags: this.tags
          },
          titulo: this.newForumFormGroup.get('title').value,
          body: this.newForumFormGroup.get('body').value + videoAdd,
          texto: this.removerTags(this.newForumFormGroup.get('body').value)
        }
      } else if (this.tipo == 'diagrama') {
        forum = {
          diagramaId: [this.signal_diagrama._id],
          sinalId: this.sinalId,
          videoId: this.idYoutube,
          categoria: this._categoria,
          filtro: {
            modulo: this.signal_diagrama.modulo,
            marca: this.signal_diagrama.marca,
            modelo: this.signal_diagrama.modelo,
            ano: this.signal_diagrama.ano,
            motor: this.signal_diagrama.motor,
            sistema_injecao: this.signal_diagrama.sistema_injecao,
            categoria: this.signal_diagrama.categoria,
            tipo: this.signal_diagrama.tipo,
            tipo_diagrama: this.signal_diagrama.tipo_diagrama,
            tags: this.tags
          },
          titulo: this.newForumFormGroup.get('title').value,
          body: this.newForumFormGroup.get('body').value + videoAdd,
          texto: this.removerTags(this.newForumFormGroup.get('body').value)
        }
      } else if (this.tipo == 'novo') {

        if (this.category == 'Oscilograma') {
          const sinalId = await this.getSignal(this.oscilogramaFormGroupValue);
          if (sinalId) {
            this.sinalId.push(sinalId[0]);
          }
          forum = {
            diagramaId: null,
            sinalId: this.sinalId,
            videoId: this.idYoutube,
            categoria: this._categoria,
            filtro: {
              modulo: this.module,
              marca: '',
              modelo: '',
              ano: '',
              motor: null,
              sistema_injecao: '',
              categoria: '',
              tipo: '',
              tipo_diagrama: null,
              tags: this.tags
            },
            titulo: this.newForumFormGroup.get('title').value,
            body: this.newForumFormGroup.get('body').value + videoAdd,
            texto: this.removerTags(this.newForumFormGroup.get('body').value)
          }
        } else if (this.category == 'Diagramas') {
          const diagramaId = await this.getDiagram(this.diagramaFormGroupValue);
          forum = {
            diagramaId: diagramaId,
            sinalId: this.sinalId,
            videoId: this.idYoutube,
            categoria: this._categoria,
            filtro: {
              modulo: this.module,
              marca: this.diagramaFormGroupValue.marca,
              modelo: this.diagramaFormGroupValue.modelo,
              ano: this.diagramaFormGroupValue.ano,
              motor: this.diagramaFormGroupValue.motor,
              sistema_injecao: this.diagramaFormGroupValue.sistema_injecao,
              categoria: this.diagramaFormGroupValue.categoria,
              tipo: this.diagramaFormGroupValue.tipo,
              tipo_diagrama: this.diagramaFormGroupValue.tipo_diagrama,
              tags: this.tags
            },
            titulo: this.newForumFormGroup.get('title').value,
            body: this.newForumFormGroup.get('body').value + videoAdd,
            texto: this.removerTags(this.newForumFormGroup.get('body').value)
          }
        } else if (this.outrosCat == 'video') {
          const sinalId = await this.getSignal(this.oscilogramaFormGroupValue);
          if (sinalId) {
            this.sinalId.push(sinalId[0]);
          }
          forum = {
            diagramaId: null,
            sinalId: this.sinalId,
            videoId: this.idYoutube,
            categoria: this._categoria,
            filtro: {
              modulo: this.module,
              marca: '',
              modelo: '',
              ano: '',
              motor: null,
              sistema_injecao: '',
              categoria: '',
              tipo: '',
              tipo_diagrama: null,
              tags: this.tags
            },
            titulo: this.newForumFormGroup.get('title').value,
            body: this.newForumFormGroup.get('body').value + videoAdd,
            texto: this.removerTags(this.newForumFormGroup.get('body').value)
          }
        } else {
          forum = {
            diagramaId: null,
            sinalId: this.sinalId,
            videoId: this.idYoutube,
            categoria: this._categoria,
            filtro: {
              modulo: this.module,
              marca: null,
              modelo: null,
              ano: null,
              motor: null,
              sistema_injecao: null,
              categoria: null,
              tipo: null,
              tipo_diagrama: null,
              tags: this.tags
            },
            titulo: this.newForumFormGroup.get('title').value,
            body: this.newForumFormGroup.get('body').value + videoAdd,
            texto: this.removerTags(this.newForumFormGroup.get('body').value)
          }
        }
      }
      await this.forumService.createForum(forum).subscribe((res: any) => {
        if (res.code == 200) {
          this.loading = false;
          this.close();
          this.alertForum(true, 'criado', 'Forum');
          const titulo = res.forum.titulo.split(' ').join('-');
          let url = `forum/${res.forum._id}/${titulo}`.replace('?', '');
          this._router.navigate([`${environment.url_version}/${url}`]);
        } else {
          this.loading = false;
          this.close();
          this.alertForum(false, 'criar', 'forum');
        }
      },
        (error) => {
          this.loading = false;
          this.close();
          this.alertForum(false, 'criar', 'forum');
        });
    } if (this.newForum == false) {
      //EDITAR UM FORUM

      let forum;
      let videoAdd = '';
      if (this.idYoutube) {
        videoAdd = `<br> <iframe width="492" height="277" src="https://www.youtube.com/embed/${this.idYoutube}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>`
      }

      if (this.outrosCat == 'oscilograma') {
        const sinalId = await this.getSignal(this.oscilogramaFormGroupValue);
        if (sinalId) {
          this.sinalId.push(sinalId[0]);
        }
        forum = {
          forumId: this.signal_diagrama._id,
          diagramaId: null,
          sinalId: this.sinalId,
          videoId: this.idYoutube,
          categoria: this._categoria,
          filtro: {
            modulo: this.module,
            marca: '',
            modelo: '',
            ano: '',
            motor: null,
            sistema_injecao: '',
            categoria: '',
            tipo: '',
            tipo_diagrama: null,
            tags: this.tags
          },
          titulo: this.newForumFormGroup.get('title').value,
          body: this.newForumFormGroup.get('body').value + videoAdd,
          texto: this.removerTags(this.newForumFormGroup.get('body').value)
        }
      } else if (this.outrosCat == 'diagrama') {
        const diagramaId = await this.getDiagram(this.diagramaFormGroupValue);
        forum = {
          forumId: this.signal_diagrama._id,
          diagramaId: diagramaId,
          sinalId: this.sinalId,
          videoId: this.idYoutube,
          categoria: this._categoria,
          filtro: {
            modulo: this.module,
            marca: this.diagramaFormGroupValue.marca,
            modelo: this.diagramaFormGroupValue.modelo,
            ano: this.diagramaFormGroupValue.ano,
            motor: this.diagramaFormGroupValue.motor,
            sistema_injecao: this.diagramaFormGroupValue.sistema_injecao,
            categoria: this.diagramaFormGroupValue.categoria,
            tipo: this.diagramaFormGroupValue.tipo,
            tipo_diagrama: this.diagramaFormGroupValue.tipo_diagrama,
            tags: this.tags
          },
          titulo: this.newForumFormGroup.get('title').value,
          body: this.newForumFormGroup.get('body').value + videoAdd,
          texto: this.removerTags(this.newForumFormGroup.get('body').value)
        }
      } else if (this.outrosCat == 'video') {
        const sinalId = await this.getSignal(this.oscilogramaFormGroupValue);
        if (sinalId) {
          this.sinalId.push(sinalId[0]);
        }
        forum = {
          forumId: this.signal_diagrama._id,
          diagramaId: null,
          sinalId: this.sinalId,
          videoId: this.idYoutube,
          categoria: this._categoria,
          filtro: {
            modulo: this.module,
            marca: '',
            modelo: '',
            ano: '',
            motor: null,
            sistema_injecao: '',
            categoria: '',
            tipo: '',
            tipo_diagrama: null,
            tags: this.tags
          },
          titulo: this.newForumFormGroup.get('title').value,
          body: this.newForumFormGroup.get('body').value + videoAdd,
          texto: this.removerTags(this.newForumFormGroup.get('body').value)
        }
      } else {
        this.sinalId.push(this.signal_diagrama._id);
        forum = {
          forumId: this.signal_diagrama._id,
          diagramaId: null,
          sinalId: this.sinalId,
          videoId: this.idYoutube,
          categoria: this._categoria,
          filtro: {
            modulo: this.module,
            marca: null,
            modelo: null,
            ano: null,
            motor: null,
            sistema_injecao: null,
            categoria: null,
            tipo: null,
            tipo_diagrama: null,
            tags: this.tags
          },
          titulo: this.newForumFormGroup.get('title').value,
          body: this.newForumFormGroup.get('body').value + videoAdd,
          texto: this.removerTags(this.newForumFormGroup.get('body').value)
        }
      }
      this.forumService.editForum(forum).subscribe((res: any) => {
        if (res.code == 200) {
          this.loading = false;
          this.close();
          this.alertForum(true, 'editado', 'Forum');
          const titulo = res.forum.titulo.split(' ').join('-');
          let url = `forum/${res.forum._id}/${titulo}`.replace('?', '');
          this._router.navigate([`${environment.url_version}/${url}`]);
          this.forumService.emitirNovaBusca();
        } else {
          this.loading = false;
          this.close();
          this.alertForum(false, 'editar', 'forum');
        }
      },
        (error) => {
          this.loading = false;
          this.close();
          this.alertForum(false, 'editar', 'forum');
        });
    }
  }

  async getSignal(sinal) {
    try {
      const object = {
        tags: {
          module: [this.module],
          brand: [sinal.brand],
          model: [sinal.model],
          year: [sinal.year],
          sett: [sinal.sett],
          signalType: [sinal.signalType],
          injSystem: [sinal.injSystem]
        },
        type: 'aggregate',
        page: 1,
        sort: -1,
        sortType: 'date',
        validado: false,
        limit: 10
      }
  
      let sinalId = [];
      let res: any = await this.newFiltroService.getFilterSignalsByFilter(object).pipe(take(1)).toPromise();
      if (res.size == 1) {
        res.signals.forEach(signal => {
          sinalId.push(signal._id);
        });
        return await sinalId;
      }
    } catch (error) {
      
    }
  }

  async getDiagram(diagrama) {
    const object = {
      ano: diagrama.ano,
      marca: diagrama.marca,
      modelo: diagrama.modelo,
      modulo: this.module,
      motor: diagrama.motor,
      page: 1,
      sistema_injecao: diagrama.sistema_injecao,
      tipo_diagrama: diagrama.tipo_diagrama
    }


    let diagramaId = [];
    let res: any = await this.newFiltroDiagramaService.getFilterDiagramsByFilter(object).pipe(take(1)).toPromise();
    if (res.size == 1) {
      res.diagramas.forEach(diagrama => {
        diagramaId.push(diagrama._id)
      });
      return await diagramaId;
    }
  }

  alertForum(alert, tipo, forum) {
    if (alert) {
      this.dialog.open(SucessCreateAccountComponent, {
        data: {
          title: 'Sucesso',
          icon_title: '&#xE876;',
          message: forum + ' ' + tipo + ' com sucesso!',
          buttonConfirm: 'Tudo bem',
          buttonDecline: '',
          icon_button: '&#xE5C8;',
          background: '#47c9a2',
          timer: 6
        },
        disableClose: true
      });
    } else {
      this.dialog.open(SucessCreateAccountComponent, {
        data: {
          title: 'Erro',
          icon_title: '&#xE888;',
          message: 'Falha ao ' + tipo + ' o ' + forum + ', tente novamente mais tarde!',
          buttonConfirm: '',
          buttonDecline: 'Tudo bem',
          icon_button: '&#xE5C8;',
          background: '#cc3300',
          timer: 6
        },
        disableClose: true
      });
    }
  }

  defineCategory(e) {
    this.category = e;
    this.backUpCategory = e;
    this.module = undefined;
    this.nextStep = false;
    this.clearFilter();
  }

  firstStep() {
    this.step = {
      first: true,
      second: false,
      third: false,
      category: false
    }
  }

  secondStep() {
    this.getInfoFilters();
    this.step = {
      first: false,
      second: true,
      third: false,
      category: false
    }
  }

  clearFilter() {
    this.oscilogramaFormGroup.patchValue({
      brand: null,
      injSystem: null,
      model: null,
      sett: null,
      signalType: null,
      validado: null,
      year: null
    });

    this.diagramaFormGroup.patchValue({
      ano: null,
      categoria: null,
      marca: null,
      modelo: null,
      motor: null,
      sistema_injecao: null,
      tipo: null,
      tipo_diagrama: null
    });
  }

  clearFieldOscilograma(control) {
    this.oscilogramaFormGroup.patchValue({ [`${control}`]: null });
    this.changeFilter();
  }

  clearFieldDiagrama(control) {
    this.diagramaFormGroup.patchValue({ [`${control}`]: null });
    this.changeFilter();
  }

  changeFilter(e?) {
    if (e == 'sinal') this.category = 'Oscilograma';
    if (e == 'diagrama') this.category = 'Diagramas';
    if (this.category == 'Oscilograma' || this.category == 'Outros') {
      const filter = {
        brand: this.oscilogramaFormGroup.get('brand').value,
        injSystem: this.oscilogramaFormGroup.get('injSystem').value,
        model: this.oscilogramaFormGroup.get('model').value,
        module: this.module,
        sett: this.oscilogramaFormGroup.get('sett').value,
        signalType: this.oscilogramaFormGroup.get('signalType').value,
        validado: false,
        year: this.oscilogramaFormGroup.get('year').value,
      }
      this._signalService.filtroDropbox(filter, false)
        .subscribe((res: any) => {

          if (res.code == 200) {
            if (res.size == 1) {
              this.addSignalFlag = true;
            }
            if (res.dropbox) {
              this.oscilogramaList = res.dropbox;

              this.brandSinal = res.dropbox.brand;
              this.injSystemSinal = res.dropbox.injSystem;
              this.modelSinal = res.dropbox.model;
              this.settSinal = res.dropbox.sett;
              this.signalTypeSinal = res.dropbox.signalType;
              this.yearSinal = res.dropbox.year;
            }
          } if (res.code == 401) {
          }
        });
      this.validNextStep();
    } if (this.category == 'Diagramas') {
      const filter = {
        ano: this.diagramaFormGroup.get('ano').value,
        categoria: this.diagramaFormGroup.get('categoria').value,
        marca: this.diagramaFormGroup.get('marca').value,
        modelo: this.diagramaFormGroup.get('modelo').value,
        modulo: this.module,
        motor: this.diagramaFormGroup.get('motor').value,
        page: 1,
        sistema_injecao: this.diagramaFormGroup.get('sistema_injecao').value,
        tipo: this.diagramaFormGroup.get('tipo').value,
        tipo_diagrama: this.diagramaFormGroup.get('tipo_diagrama').value,
      }
      this._diagramaService.filterDiagrams(filter)
        .subscribe((res: any) => {
          if (res.code == 200) {
            if (res.size == 1) {
              this.addDiagramaFlag = true;
            }
            if (res.dropbox) {
              this.diagramaList = res.dropbox;

              this.marcaDiagrama = res.dropbox.marca;
              this.modeloDiagrama = res.dropbox.modelo;
              this.anoDiagrama = res.dropbox.ano;
              this.motorDiagrama = res.dropbox.motor;
              this.tipo_diagramaDiagrama = res.dropbox.tipo_diagrama;
              this.sistema_injecaoDiagrama = res.dropbox.sistema_injecao;
              this.categoriaDiagrama = res.dropbox.categoria;
              this.tipoDiagrama = res.dropbox.tipo;
            }
          } if (res.code == 401) {
          }
        });
      this.validNextStep();
    }
  }

  changeModulo(modulo, e?) {
    this.clearFilter();
    switch (modulo) {
      case 'Automotivo':
        this.module = 'Automotivo-carros'
        break;

      case 'Caminhões':
        this.module = 'Automotivo-caminhões'
        break;

      case 'Moto':
        this.module = 'Automotivo-motos'
        break;
    }
    this.changeFilter(e);
  }

  validNextStep() {
    if (this.category == 'Oscilograma') {
      const filter = {
        brand: this.oscilogramaFormGroup.get('brand').value,
        injSystem: this.oscilogramaFormGroup.get('injSystem').value,
        model: this.oscilogramaFormGroup.get('model').value,
        module: this.module,
        sett: this.oscilogramaFormGroup.get('sett').value,
        signalType: this.oscilogramaFormGroup.get('signalType').value,
        validado: false,
        year: this.oscilogramaFormGroup.get('year').value,
      }
      var nextStep = true;
      for (var key in filter) {
        if (filter[key] == null)
          nextStep = false;
      }
      this.nextStep = nextStep;

    } else if (this.category == 'Diagramas') {
      const filter = {
        ano: this.diagramaFormGroup.get('ano').value,
        categoria: this.diagramaFormGroup.get('categoria').value,
        marca: this.diagramaFormGroup.get('marca').value,
        modelo: this.diagramaFormGroup.get('modelo').value,
        modulo: this.module,
        motor: this.diagramaFormGroup.get('motor').value,
        page: 1,
        sistema_injecao: this.diagramaFormGroup.get('sistema_injecao').value,
        tipo_diagrama: this.diagramaFormGroup.get('tipo_diagrama').value,
      }
      var nextStep = true;
      for (var key in filter) {
        if (filter[key] == null)
          nextStep = false;
      }
      this.nextStep = nextStep;
    }
  }

  changeCat(categoria) {
    this.outrosCat = categoria;
    this._categoria.push(categoria);
    if (categoria == 'oscilograma') {
      this.category = 'Oscilograma';
      this.backUpCategory = 'Oscilograma';
    } else if (categoria == 'diagrama') {
      this.category = 'Diagramas';
      this.backUpCategory = 'Diagramas';
    } else {
      this.category = 'Outros'
      this.backUpCategory = 'Outros'
    }
  }

  addTag(tag) {
    if (tag == '') return;
    if (tag == ' ') return;
    for (let _tag of this.tags) {
      if (_tag == tag) return;
    }
    this.tags.push(tag)
    this.newForumFormGroup.patchValue({
      tags: null,
    });
  }

  removeTag(i) {
    this.tags.splice(i, 1);
  }

  preencherDados() {
    if (this.signal_diagrama.categoria[0] == 'oscilograma') {
      this.nextStep = true;
      this.backStep = true;
      this.defineCategory('Outros')
      this.outrosCat = 'oscilograma';
      this.module = this.signal_diagrama.filtro.modulo
      this.oscilogramaFormGroup.patchValue({
        brand: this.signal_diagrama.filtro.marca,
        injSystem: this.signal_diagrama.filtro.sistema_injecao,
        model: this.signal_diagrama.filtro.modelo,
        sett: this.signal_diagrama.filtro.categoria,
        signalType: this.signal_diagrama.filtro.tipo,
        year: this.signal_diagrama.filtro.ano[0]
      });
      this.validNextStep();
      if (this.signal_diagrama.edit == null || this.signal_diagrama.edit == undefined) {
        this.newForumFormGroup.patchValue({
          title: this.signal_diagrama.titulo,
          body: this.signal_diagrama.body
        });
      } else {
        this.newForumFormGroup.patchValue({
          title: this.signal_diagrama.titulo,
          body: this.signal_diagrama.edit
        });
      }
      this.tags = this.signal_diagrama.filtro.tags
      this.nextStep = true;
    } else if (this.signal_diagrama.categoria[0] == 'diagrama') {
      this.nextStep = true;
      this.backStep = true;
      this.defineCategory('Outros')
      this.outrosCat = 'diagrama';
      this.module = this.signal_diagrama.filtro.modulo
      this.diagramaFormGroup.patchValue({
        ano: this.signal_diagrama.filtro.ano,
        categoria: this.signal_diagrama.filtro.categoria,
        marca: this.signal_diagrama.filtro.marca,
        modelo: this.signal_diagrama.filtro.modelo,
        motor: this.signal_diagrama.filtro.motor,
        sistema_injecao: this.signal_diagrama.filtro.sistema_injecao,
        tipo: this.signal_diagrama.filtro.tipo,
        tipo_diagrama: this.signal_diagrama.filtro.tipo_diagrama
      });
      this.validNextStep();
      if (this.signal_diagrama.edit == null || this.signal_diagrama.edit == undefined) {
        this.newForumFormGroup.patchValue({
          title: this.signal_diagrama.titulo,
          body: this.signal_diagrama.body
        });
      } else {
        this.newForumFormGroup.patchValue({
          title: this.signal_diagrama.titulo,
          body: this.signal_diagrama.edit
        });
      }
      this.tags = this.signal_diagrama.filtro.tags
      this.nextStep = true;
    } else if (this.signal_diagrama.categoria[0] == 'video') {
      if (this.signal_diagrama.videoId) {
        this.youtubeValue = 'https://youtu.be/' + this.signal_diagrama.videoId[0];
        this.idYoutube = this.signal_diagrama.videoId[0];
      }
      this.nextStep = true;
      this.backStep = true;
      this.defineCategory('Outros')
      this.outrosCat = 'video'
      this.outrosFormGroup.patchValue({
        categoria: this.categorias[3].nome
      });
      this.module = this.signal_diagrama.filtro.modulo
      if (this.signal_diagrama.filtro.marca !== null) {
        this.oscilogramaFormGroup.patchValue({
          brand: this.signal_diagrama.filtro.marca,
          injSystem: this.signal_diagrama.filtro.sistema_injecao,
          model: this.signal_diagrama.filtro.modelo,
          sett: this.signal_diagrama.filtro.categoria,
          signalType: this.signal_diagrama.filtro.tipo,
          year: this.signal_diagrama.filtro.ano[0]
        });
      }
      this.newForumFormGroup.patchValue({
        title: this.signal_diagrama.titulo,
        body: this.signal_diagrama.body
      });
      this.tags = this.signal_diagrama.filtro.tags
      this.nextStep = true;
    } else {
      this.defineCategory('Outros')
    }
  }

  onKeyIdYoutube(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      $this.youTubeGetID(event);
    }, 50);
  }

  youTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    this.idYoutube = (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }


  getDropBoxFilterDiagrama() {

    this.sub.push(this.diagramaFormGroup.get('marca').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.marcaDiagrama) this.diagramaList.marca = this.marcaDiagrama.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))

    this.sub.push(this.diagramaFormGroup.get('modelo').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.modeloDiagrama) this.diagramaList.modelo = this.modeloDiagrama.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        });
      }
      ))

    this.sub.push(this.diagramaFormGroup.get('ano').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.anoDiagrama) this.diagramaList.ano = this.anoDiagrama.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))

    this.sub.push(this.diagramaFormGroup.get('motor').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.motorDiagrama) this.diagramaList.motor = this.motorDiagrama.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))

    this.sub.push(this.diagramaFormGroup.get('tipo_diagrama').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.tipo_diagramaDiagrama) this.diagramaList.tipo_diagrama = this.tipo_diagramaDiagrama.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))

    this.sub.push(this.diagramaFormGroup.get('sistema_injecao').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.sistema_injecaoDiagrama) this.diagramaList.sistema_injecao = this.sistema_injecaoDiagrama.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))

    this.sub.push(this.diagramaFormGroup.get('categoria').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.categoriaDiagrama) this.diagramaList.categoria = this.categoriaDiagrama.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))

    this.sub.push(this.diagramaFormGroup.get('tipo').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.tipoDiagrama) this.diagramaList.tipo = this.tipoDiagrama.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))
  }

  getDropBoxFilterSinal() {

    this.sub.push(this.oscilogramaFormGroup.get('brand').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.brandSinal) this.oscilogramaList.brand = this.brandSinal.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))

    this.sub.push(this.oscilogramaFormGroup.get('model').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.modelSinal) this.oscilogramaList.model = this.modelSinal.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        });
      }
      ))

    this.sub.push(this.oscilogramaFormGroup.get('year').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.yearSinal) this.oscilogramaList.year = this.yearSinal.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))

    this.sub.push(this.oscilogramaFormGroup.get('injSystem').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.injSystemSinal) this.oscilogramaList.injSystem = this.injSystemSinal.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))

    this.sub.push(this.oscilogramaFormGroup.get('sett').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.settSinal) this.oscilogramaList.sett = this.settSinal.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))

    this.sub.push(this.oscilogramaFormGroup.get('signalType').valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(async value => {
        if (value == null) {
          return
        }
        if (this.signalTypeSinal) this.oscilogramaList.signalType = this.signalTypeSinal.filter(item => {
          return item.toLowerCase().includes(value.toLowerCase())
        })
      }
      ))
  }

  abrirCategorias() {
    this.clearFilter();
    this.step.category = true;
    this.step.second = false;
    for (let index = 0; index < this.categorias.length; index++) {
      if (this.outrosCat == this.categorias[index].cat) {
        this.categorias.splice(index, 1);
      }
    }
  }

  saveCategorias() {
    this.step.category = false;
    this.step.second = true;
    this.category = this.backUpCategory;
  }

  addCat(cat) {
    this._categoria.push(cat);
    for (let index = 0; index < this.categorias.length; index++) {
      this._categoria.forEach(cat => {
        if (cat == 'video') {
          this.addVideo = true;
        }
        if (cat == 'oscilograma') {
          this.addSinal = true;
          this.category = 'Oscilograma';
        }
        if (cat == 'diagrama') {
          this.addDiagrama = true;
          this.category = 'Diagramas';
        }
        if (cat == this.categorias[index].cat) {
          this.categorias.splice(index, 1);
        }
      });
    }
    this.addCategoriaFormGroup.patchValue({
      categoria: null,
    });
  }

  removeCat(cat, i) {
    if (i !== 0) {
      if (cat == 'video') {
        this.addVideo = false;
      }
      if (cat == 'oscilograma') {
        this.addSinal = false;
        this.category = this.backUpCategory;
      }
      if (cat == 'diagrama') {
        this.addDiagrama = false;
        this.category = this.backUpCategory;
      }
      for (let index = 0; index < this.backupCategorias.length; index++) {
        if (cat == this.backupCategorias[index].cat) {
          this.categorias.push(this.backupCategorias[index]);
        }
      }
      for (let index = 0; index < this._categoria.length; index++) {
        if (this._categoria[index] == cat) {
          this._categoria.splice(index, 1);
        }
      }
    }
  }

  async addDiagramas() {
    const diagramaId = await this.getDiagramaAddCat(this.diagramaFormGroup.value);
    this.diagramaId.push(diagramaId[0]._id);
    this.diagramaIdInfo.push(diagramaId[0]);
    this.changeModulo('Automotivo');
    this.addDiagramaFlag = false;
  }

  removeDiagrama(id) {
    for (let index = 0; index < this.diagramaIdInfo.length; index++) {
      if (this.diagramaIdInfo[index]._id == id) {
        this.diagramaIdInfo.splice(index, 1);
      }
    }
    for (let index = 0; index < this.diagramaId.length; index++) {
      if (this.diagramaId[index] == id) {
        this.diagramaId.splice(index, 1);
      }
    }
  }

  async addSignal() {
    const sinalId = await this.getSignalAddCat(this.oscilogramaFormGroup.value);
    this.sinalId.push(sinalId[0]._id);
    this.sinalIdInfo.push(sinalId[0]);
    this.changeModulo('Automotivo');
    this.addSignalFlag = false;
  }

  removeSignal(id) {
    for (let index = 0; index < this.sinalIdInfo.length; index++) {
      if (this.sinalIdInfo[index]._id == id) {
        this.sinalIdInfo.splice(index, 1);
      }
    }
    for (let index = 0; index < this.sinalId.length; index++) {
      if (this.sinalId[index] == id) {
        this.sinalId.splice(index, 1);
      }
    }
  }

  async getDiagramaAddCat(diagrama) {
    const object = {
      ano: diagrama.ano,
      marca: diagrama.marca,
      modelo: diagrama.modelo,
      modulo: this.module,
      motor: diagrama.motor,
      page: 1,
      sistema_injecao: diagrama.sistema_injecao,
      tipo_diagrama: diagrama.tipo_diagrama
    }


    let diagramaId = [];
    let res: any = await this.newFiltroDiagramaService.getFilterDiagramsByFilter(object).pipe(take(1)).toPromise();
    res.diagramas.forEach(diagrama => {
      diagramaId.push(diagrama)
    });
    return await diagramaId;
  }

  async getSignalAddCat(sinal) {
    try {
      const object = {
        tags: {
          module: [this.module],
          brand: [sinal.brand],
          model: [sinal.model],
          year: [sinal.year],
          sett: [sinal.sett],
          signalType: [sinal.signalType],
          injSystem: [sinal.injSystem]
        },
        type: 'aggregate',
        page: 1,
        sort: -1,
        sortType: 'date',
        validado: false,
        limit: 10
      }
  
      let getSinal = [];
      let res: any = await this.newFiltroService.getFilterSignalsByFilter(object).pipe(take(1)).toPromise();
      res.signals.forEach(signal => {
        getSinal.push(signal);
      });
      return await getSinal;
    } catch (error) {
      
    }
  }

  getInfoFilters() {
    this.diagramaFormGroupValue = this.diagramaFormGroup.value;
    this.oscilogramaFormGroupValue = this.oscilogramaFormGroup.value;
  }


}



