import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { VehicleInfoBasicComponent } from './vehicle-info-basic/vehicle-info-basic.component';
import { NoticeComponent } from './notice/notice.component';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../shared.service';

@Injectable()
export class AlertService {

  constructor(
    private _dialog: MatDialog,
    private http: HttpClient
  ) { }

  show(type: string = 'simple', args: {}): MatDialogRef<any> {
    switch (type) {
      case 'vehicle-basic':
        return this._dialog.open(VehicleInfoBasicComponent, {
          data: { ...args },
          hasBackdrop: true, minWidth: 360, id: 'mat-dialog-vehicle-basic'
        })

      case 'confirm':
        return this._dialog.open(NoticeComponent, {
          data: { ...args },
          hasBackdrop: true, minWidth: 360, id: 'mat-dialog-confirm'
        })
      default:
        break;
    }
  }

  downloadPDF(id) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/pfd/download`, { pdfId: id }, { headers: SharedService.header, responseType: 'arraybuffer' });
  }

  getImg(id) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/foto/download`, { fotoId: id }, { headers: SharedService.header, responseType: 'arraybuffer' });
  }

  getDiagrama(diagramaId, sinalId) {
    return this.http.post(`${environment.api_server}/api/diagrama/get/componentes/diagramaId/sinalId`, { diagramaId: diagramaId, sinalId: sinalId }, { headers: SharedService.header });
  }

  downloadCertificado(cursoId: any) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/cursos/aluno/curso/download`, { cursoId: cursoId }, { headers: SharedService.header });
  }

  getFilterDiagramsByFilter(tags) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/diagrama/admin/filtro`, tags, { headers: SharedService.header });
  }

  diagramaFavorito(tags) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/diagrama/admin/filtro/favorito`, tags, { headers: SharedService.header });
  }

  renovarPlanoCupom(cupom) {
    return this.http.post(`${environment.api_server}/api/assinantes/recorrencia/cupom/estender`, { nomeCupom: cupom }, { headers: SharedService.header });
  }

  validaCupomByNome(nome, planoCupomId?) {
    return this.http.post(`${environment.api_server}/api/assinantes/cupom/valida/nome`, { nome: nome, planoCupomId: planoCupomId }, { headers: SharedService.header });
  }

  renovarPlanoCartao(params) {
    return this.http.post(`${environment.api_server}/api/assinantes/recorrencia/renovar/pagamento`, params, { headers: SharedService.header });
  }

  getTutorialId(id) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/get/pdf`, { pdfId: id }, { headers: SharedService.header });
  }

  downloadFoto(id) {
    return this.http.post(`${environment.api_server}/api/newtecnoscopio/foto/download`, { fotoId: id }, { headers: SharedService.header, responseType: 'arraybuffer' });
  }

  getInfoUser() {
    return this.http.get(`${environment.api_server}/api/module/user/get/infos/request`, { headers: SharedService.header });
  }

  sendInfoAccept(accept) {
    return this.http.post(`${environment.api_server}/api/assinantes/contrato/create`, accept, { headers: SharedService.header });
  }
}