import { AppService } from './../../../app.service';
import { environment } from './../../../../environments/environment';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-de-testes-view',
  templateUrl: './site-de-testes-view.component.html',
  styleUrls: ['./site-de-testes-view.component.scss']
})
export class SiteDeTestesViewComponent implements OnInit {

  constructor(
    private router: Router,
    private appService: AppService,
  ) { }

  ngOnInit() {
  }

  passBlockPag(e) {
    if (e == '1') {
      // localStorage.setItem('passTeste', e);
      // this.router.navigate([`${environment.url_version}/user/login`]);
      this.appService.emitPassSiteTeste(true);
    }
  }

}
