import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../shared.service';

@Injectable()
export class VideoService {
  public videoList: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  private key = environment.api_key_youtube;

  constructor(private _http: HttpClient) { }

  update(form) {
    return this._http.put(`${environment.api_server}/api/module/admin/tecnoscopio/videos/${form._id}`, form, { headers: SharedService.header })
  }
  delete(id) {
    return this._http.delete(`${environment.api_server}/api/module/admin/tecnoscopio/videos/${id}`, { headers: SharedService.header })
  }

  create(form) {
    return this._http.post(`${environment.api_server}/api/module/admin/tecnoscopio/videos`, form, { headers: SharedService.header })
  }
  getVideos(text) {
    return this._http.post(`${environment.api_server}/api/module/public/tecnoscopio/videos`, { title: text }, { headers: SharedService.header });
  }
  videoExist(videoId) {
    const url = 'https://www.googleapis.com/youtube/v3/videos?id=';
    return this._http.get(`${url}${videoId}&key=${this.key}&part=snippet`);
  }
  getPlaylistFromYoutube(playlistId: string, pageToken = '', maxResult: number = 10) {
    const baseurl = 'https://www.googleapis.com/youtube/v3/playlistItems?';

    const full_url = `${baseurl}playlistId=${playlistId}&maxResults=${maxResult}${pageToken ? '&pageToken=' + pageToken : ''}&part=snippet&key=${environment.api_key_youtube}`;
    return this._http.get(full_url);
  }


}
