import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SiteDeTestesRoutingModule } from './site-de-testes-routing.module';
import { SiteDeTestesViewComponent } from './site-de-testes-view/site-de-testes-view.component';


@NgModule({
  declarations: [SiteDeTestesViewComponent],
  imports: [
    CommonModule,
    SiteDeTestesRoutingModule
  ],
  exports: [
    SiteDeTestesViewComponent
  ]
})
export class SiteDeTestesModule { }
