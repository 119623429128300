import { DiagramaService } from './../../../_nt-f4bric/service/diagrama.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { PagerService } from '../../services/Pager/pager';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-filter-diagram',
  templateUrl: './filter-diagram.component.html',
  styleUrls: ['./filter-diagram.component.scss']
})
export class FilterDiagramComponent implements OnInit {

  loading: boolean = false;
  dropbox: any = {};
  diagramas = [];
  pageTotal: number = 1;
  pager: any = {};
  permissao = [];
  page: number = 1;
  service = "Desktop";

  tags = {
    modulo: null,
    marca: null,
    modelo: null,
    ano: null,
    motor: null,
    tipo_diagrama: null,
    sistema_injecao: null
  }


  ordem: string = 'decrescente';
  ordemText: string = 'Decrescente';

  ordenarText: string = 'Mais recentes';
  ordenar: string = 'criadoEm';
  favorito: boolean = false;
  concluido: boolean = null;
  apagado: boolean = false;

  constructor(
    private dialog: MatDialogRef<FilterDiagramComponent>,
    private alertService: AlertService,
    private pagerService: PagerService,
    private diagramaService: DiagramaService
    ) { }

  ngOnInit() {
    this.changeFilter();
    this.getAllPermission();
  }

  cancel() {
    this.dialog.close({flag: false});
  }
  confirm(action, id) {
    this.dialog.close({flag: true, action: action, id: id});
  }

  onModuloChange(event, tag) {
    this.page = 1;
    if (event.target.value == '') event.target.value = null;
    this.tags[`${tag}`] = event.target.value;
    Object.keys(this.tags).forEach((propriedade) => {
      if (this.tags[propriedade] === '') {
        this.tags[propriedade] = null;
      }
    });
    this.changeFilter();
  }

  setPage(page) {
    this.page = page;
    this.changeFilter();
  }

  changeFilter() {
    this.loading = true;
    this.dropbox = [];
    let newFavorito = this.favorito
    if (this.favorito == false) newFavorito = null;
    try {
      this.alertService.getFilterDiagramsByFilter({ ...this.tags, ...{ordem: this.ordem, ordenar: this.ordenar, favorito: newFavorito, concluido: this.concluido, apagado: this.apagado, page: this.page} }).subscribe((res: any) => {
        if (res.code == 200) {
          if (res.dropbox !== undefined && res.dropbox !== null) {
            this.diagramas = res.diagramas;
            this.dropbox = res.dropbox;
            this.pageTotal = Math.ceil(res.size / 10);
            this.pager = this.pagerService.getPager(this.pageTotal, this.page, 10, this.service);
          }
          this.loading = false;
          return
        } else {
          //Falha
          this.loading = false;
          return
        }
      },
        (error) => {
          //Falha
          this.loading = false;
          return
        });
    } catch (error) {
      //Falha
      this.loading = false;
      return
    }
  }

  setConcluido(flag){
    this.concluido = flag;
    this.changeFilter(); 
  }

  setApagados(){
    this.apagado = !this.apagado;
    if (this.apagado) this.favorito = false;
    this.changeFilter(); 
  }

  setFavoritos(){
    this.favorito = !this.favorito;
    if (this.favorito) this.apagado = false;
    this.changeFilter(); 
  }

  clearFilter() {
    this.page = 1;
    this.tags = {
      modulo: null,
      marca: null,
      modelo: null,
      ano: null,
      motor: null,
      tipo_diagrama: null,
      sistema_injecao: null
    }
    this.changeFilter(); 
  }

  defineFavorito(flag, diagrama){
    try {
      this.alertService.diagramaFavorito({ favorito: flag, diagramaId: diagrama._id } ).subscribe((res: any) => {
        if (res.code == 200) {
          this.changeFilter(); 
          return
        } else {
          //Falha
          return
        }
      },
        (error) => {
          //Falha
          return
        });
    } catch (error) {
      //Falha
      return
    }
  }

  changeOrder(tag, text) {
    this.ordenar = tag;
    this.ordenarText = text;
    this.changeFilter();
  }

  changeOrderCres(tag, text){
    this.ordem = tag;
    this.ordemText = text;
    this.changeFilter();
  }

  getAllPermission() {
    try {
      this.diagramaService.getPermissoes().subscribe(
        (res: any) => {
          if (res.code == 200) {
            this.permissao = res.retorno;
            return;
          } else {
            return;
          }
        },
        (error) => {
          return;
        }
      );
    } catch (error) {
      return;
    }
  }

  getPermissaoNome(permissaoId: string): string {
    const permissao = this.permissao.find(p => p._id === permissaoId);
    return permissao ? permissao.nome : 'Permissão não encontrada';
  }

}
