import { Directive, Input, ElementRef, Renderer, HostListener } from '@angular/core';
import { DirectiveService } from './directive.service';
import { AppService } from '../../app.service';
import { tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appEvaluation]'
})
export class EvaluationDirective {
  @Input() average: number;
  @Input() star: number;
  @Input() voted: boolean = false;
  @Input() disabled: boolean = false;
  @Input() defaultSize = '15';
  limit: boolean = false;

  destroy$ = new Subject();

  constructor(private _element: ElementRef, private _rederer: Renderer, private _service: DirectiveService, private _appService: AppService) {
    this._service.limit.subscribe(res => {
      this.limit = res;

    })
  }

  ngOnInit() {    
    this._appService.mediaQuery.pipe(takeUntil(this.destroy$)).subscribe((res => {
      if (res.matches) {   
        this.defaultSize = '20';
      } else {
        this.defaultSize = '20';
      }
      if (this.average != null) {
        this._rederer.setElementStyle(this._element.nativeElement, 'font-size', this.defaultSize + 'px');
        this.star == 1 ? this.limit = false : null;
        this.paint();
      }       
    }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    
  }

  @HostListener('mouseenter') onMouseOver() {
    if (!this.disabled) {
      this._rederer.setElementStyle(this._element.nativeElement, 'font-size', 19 + "px");
    }
  }
  @HostListener('mouseleave') onmouseleave() {
    if (!this.disabled) {
      if (this.voted == true) {
        this._rederer.setElementStyle(this._element.nativeElement, 'font-size', 19 + "px");
      } else {
        this._rederer.setElementStyle(this._element.nativeElement, 'font-size', this.defaultSize + "px");
      }
    } 
  }
  @HostListener('change') ngOnChanges() {    
    if (!this.disabled) {
      this.star == 1 ? this.limit = false : null;
      this.paint();
    } else {
      this._rederer.setElementStyle(this._element.nativeElement, 'font-size', this.defaultSize + "px");
    }
  }
  paint() {    
    if (this.voted == true) {
      this._rederer.setElementStyle(this._element.nativeElement, 'font-size', 19 + "px");
    } else {
      this._rederer.setElementStyle(this._element.nativeElement, 'font-size', this.defaultSize + "px");
    }

    if (this.limit) {
      this._rederer.setElementStyle(this._element.nativeElement, 'background', 'initial')
      this._rederer.setElementStyle(this._element.nativeElement, '-webkit-text-fill-color', '#777777')
      this._rederer.setElementStyle(this._element.nativeElement, '-webkit-background-clip', 'initial');
      return;
    }
    if (this.average >= this.star) {
      this._service.limit.next(false);
      this._rederer.setElementStyle(this._element.nativeElement, 'background', `linear-gradient(to right, #d60000 0%, #d60000 100%, ##777777 100%, ##777777 100%`);
      this._rederer.setElementStyle(this._element.nativeElement, '-webkit-text-fill-color', 'transparent')
      this._rederer.setElementStyle(this._element.nativeElement, '-webkit-background-clip', 'text')
      this._rederer.setElementStyle(this._element.nativeElement, 'transition', 'all .3s ease-out')
      return;
    }
    let max = Math.floor((this.average - Math.floor(this.average)) * 100);
    this._service.limit.next(true);
    this._rederer.setElementStyle(this._element.nativeElement, 'background', `linear-gradient(to right, #d60000 0%, #d60000 ${max}%, #777777 ${max}%, #777777 100%`);
    this._rederer.setElementStyle(this._element.nativeElement, '-webkit-text-fill-color', 'transparent')
    this._rederer.setElementStyle(this._element.nativeElement, '-webkit-background-clip', 'text')
    this._rederer.setElementStyle(this._element.nativeElement, 'transition', 'all .3s ease-out')
  }
}

