import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared/shared.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class NewFiltroService {

  constructor(private http: HttpClient) { }

  static novaBusca: EventEmitter<any> = new EventEmitter();

  getFilterSignalsByFilter(tags) {
    let api_request = `${environment.api_server}/api/module/user/signal/filter/list`;
    return this.http.post(api_request, tags, { headers: SharedService.header });
  }

  cripytoRequestNow() {
    return Date.now();
  }

  cripytoData(data, now) {
    let LOGS = false;
    now = parseInt(now);
    var hoje = new Date(now); if (LOGS) console.log('hoje: ', hoje)
    let dia = hoje.getDate();
    let mes = hoje.getMonth() + 1;
    let ano = hoje.getFullYear();
    let secret = `secret: ${now + 3 * dia + 5 * mes * 7 * ano}`; if (LOGS) console.log(secret), console.log(dia, mes, ano);
    let encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), secret.trim()).toString();
    return { encrypt: encrypt };
  }

  getAllFaultCode(body) {
    return this.http.post(`${environment.api_server}/api/codigofalha/findall`, body, { headers: SharedService.header });
  }

}
